export const CommonUtil = {
  // distance: (x1, y1, x2, y2, unit) => {
  //   let theta = x1 - y2;
  //   let dist = Math.sin(deg2rad(x1)) * Math.sin(deg2rad(x2)) + Math.cos(deg2rad(x1)) * Math.cos(deg2rad(x2)) * Math.cos(deg2rad(theta));
  //
  //   dist = Math.acos(dist);
  //   dist = rad2deg(dist);
  //   dist = dist * 60 * 1.1515;
  //
  //   if (unit === DISTANCE_UNIT.KILOMETER) {
  //     dist = dist * 1.609344;
  //   } else if (unit === DISTANCE_UNIT.METER) {
  //     dist = dist * 1609.344;
  //   }
  //
  //   return (dist);
  // },
  distance: (lat1, lng1, lat2, lng2) => {
    let R = 6371; // Radius of the earth in km
    let dLat = deg2rad(lat2-lat1);  // deg2rad below
    let dLon = deg2rad(lng2-lng1);
    let a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c; // Distance in km
    return d;
  },

  Unit: {
    format(unit) {
      let n = String(unit).replaceAll(',', '');
      const formatter = new Intl.NumberFormat('ko-KR');

      return formatter.format(n ? Number(n) : 0);
    },
    removeComma(unit) {
      let n = String(unit).replaceAll(',', '');
      return n ? Number(n) : 0;
    },
  },
  randomUUID: () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
};


// This function converts decimal degrees to radians
const deg2rad = (deg) => {
  return (deg * Math.PI / 180.0);
};
// This function converts radians to decimal degrees
const rad2deg = (rad) => {
  return (rad * 180 / Math.PI);
};
