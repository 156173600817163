import axios from 'axios';
import {asyncRefreshToken} from "../components/providers/AuthProvier";

const EXCLUDE_URL = [
  '/apis/token/issue',
  '/apis/admin/member/join'
]

axios.defaults.baseURL = process.env.API_BASE_URL;
// axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('access-token');

    config.headers['Content-Type'] = "application/json; charset=utf-8";
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  }, (error) => {
    console.log('request errors catch from axios interceptor:', error.response);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data['accessToken']) sessionStorage.setItem('access-token', response.data['accessToken']);
    if (response.data && response.data['refreshToken']) sessionStorage.setItem('refresh-token', response.data['refreshToken']);

    return response;
  }, async (error) => {
    const {config, response: { status }} = error;
    const originalRequest = config;

    if (status === 401 && EXCLUDE_URL.includes(originalRequest.url)) return Promise.reject(error);

    if (originalRequest.url !== '/apis/token/refresh') {
      if (status === 401) {
        const tokenRefreshResponse = await asyncRefreshToken();
        const {accessToken, refreshToken} = tokenRefreshResponse;

        sessionStorage.setItem('access-token', accessToken);
        sessionStorage.setItem('refresh-token', refreshToken);

        return new Promise((resolve) => {
          resolve(axios(originalRequest));
        });
      } else {
        console.log('response errors catch from axios interceptor:', error.response);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

