import React, {useEffect, useState} from 'react';
import {Image, Segment} from 'semantic-ui-react';
import _ from 'lodash';
import axios from 'axios';

const CarmonEmployeeImageViewer = ({url}) => {
  const [image, setImage] = useState(null);

  useEffect(async () => {
    if (!_.isEmpty(url)) {
      const imgResponse = await axios.get(url, {responseType: 'blob'});

      if (imgResponse.status === 200) {
        const imageFile = new File([imgResponse.data], 'imageFile');
        const reader = new FileReader();
        reader.onload = e => {
          const image = String(e.target?.result);
          setImage(image);
        }

        reader.readAsDataURL(imageFile);
      }
    }
  }, [url]);

  return (
    <Segment compact style={{padding: 0}}>
      <Image src={image}/>
    </Segment>
  );
}

export default CarmonEmployeeImageViewer;

