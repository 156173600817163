import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Divider } from 'semantic-ui-react'

const ErrorPage404 = () => {
    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' color='pink' textAlign='center'>
                    [404] Page Not Found.
                </Header>
                <Divider />
                <Link to='/'><Header as='h3' color='teal'>메인으로 돌아가기</Header></Link>
            </Grid.Column>
        </Grid>
    );
}

export default ErrorPage404;