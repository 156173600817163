import React, {useEffect, useState} from 'react';
import {useHistory, withRouter} from "react-router-dom"
import {Grid, Header, Form, Container, Segment, Button, Icon, Modal, Loader, Dimmer, Input} from 'semantic-ui-react';
import _ from "lodash";
import axios from "axios";
import {SemanticToastContainer, toast} from 'react-semantic-toasts';

const CarmonAdminLoginFromLayout = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("사용자 정보가 올바르지 않습니다.");
  const [active, setActive] = useState(true);
  const [registrationModalOption, setRegistrationModalOption] = useState({
    open: false,
    data: {
      loginId: '',
      password: '',
      passwordCheck: '',
      name: ''
    },
    error: {
      passwordCheck: null,
    }
  });

  useEffect(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    //console.log(registrationModalOption);
  }, [registrationModalOption]);

  const onSubmit = async () => {
    let postData = {
      loginId: loginId,
      password: password
    };

    try {
      const response = await axios.post('/apis/token/issue', postData);

      console.log(response);

      if (_.isEmpty(response.data['accessToken'])) {
        setOpen(true);
        return false;
      } else {
        history.push('/');
      }
    } catch (ex) {
      console.log(ex);
      setModalMessage(ex.response.data.message);
      setOpen(true);
    }
  }

  const changeRegistrationData = (field, value) => {
    setRegistrationModalOption(prevState => {
      let currentState = {
        ...prevState,
        data: {
          ...prevState['data'],
          [field]: value,
        },
        error: {
          passwordCheck: null
        }
      }

      if (currentState.data.password !== currentState.data.passwordCheck) {
        currentState.error.passwordCheck = {
          pointing: 'above',
          content: '패스워드가 일치하지 않습니다.'
        }
      }

      return currentState;
    });
  }

  const registration = () => {
    setLoading(true);

    axios.post('/apis/admin/member/join', registrationModalOption.data).then(() => {
      toast({
        type: "success",
        title: "회원가입 완료",
        time: 5000,
        description: '카몬 관리자의 승인 후 이용가능합니다!'
      });

      setRegistrationModalOption(prevState => {
        return {
          ...prevState,
          open: false
        }
      });
    }).catch(error => {
      if (error.response.status === 409) {
        setRegistrationModalOption(prevState => {
          return {
            ...prevState,
            error: {
              ...prevState['error'],
              loginId: {
                pointing: 'above',
                content: '이미 존재하는 아이디입니다.'
              }
            }
          }
        });
      } else {
        toast({
          type: "error",
          title: "ERROR",
          time: 5000,
          description: error.response && error.response.data && error.response.data.message || error
        });
      }
    }).finally(() => {
      setLoading(false);
    });


  }

  const validateFrom = (event) => {
    event.preventDefault();
    if (registrationModalOption.error.passwordCheck) return;
    registration();
    return false;
  }

  return (
    <Container>
      <Dimmer active={active} inverted style={{background: `rgb(255, 255, 255, 1)`}}>
        <Loader size='large'>Loading</Loader>
      </Dimmer>
      <Grid style={{height: '100vh'}} centered={true} verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column style={{maxWidth: 450}}>
            <Header color='orange'>
              CARMON Management System
            </Header>
            <Segment>
              <Form onSubmit={onSubmit}>
                <Form.Input required={true}
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='id'
                            onChange={(e, {value}) => setLoginId(value)}/>

                <Form.Input required={true}
                            type='password'
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='password'
                            onChange={(e, {value}) => setPassword(value)}/>

                <Button type='submit' color='orange' fluid><Icon name='key'></Icon>Login</Button>
                <br/>
                <div style={{overflow:'hidden'}}>
                <Button basic
                        icon={'add user'}
                        size={'mini'}
                        color={'blue'}
                        content={'파트너사 회원가입'}
                        floated={'right'}
                        onClick={(event) => {
                          event.preventDefault();
                          setRegistrationModalOption(prevState => {
                            return {
                              ...prevState,
                              open: true
                            }
                          });
                        }}
                />
                </div>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='small'>
        <Container textAlign='center'>
          <Icon.Group size='huge' style={{marginBottom: `1em`}}>
            <Icon name='user'/>
            <Icon size='big' color='red' name='dont'/>
          </Icon.Group>

          <p>{modalMessage}</p>
        </Container>
        <Modal.Actions style={{marginTop: `2em`, textAlign: `center`}}>
          <Button basic size='large' color='red' onClick={() => setOpen(false)}>
            <Icon name='remove'/> 닫기
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setRegistrationModalOption(prevState => {
          return {
            ...prevState,
            open: false
          }
        })}
        open={registrationModalOption.open}
        size='tiny'>
        <Modal.Header>
          <Header>파트너사 회원가입</Header>
        </Modal.Header>
          <Modal.Content>
            <Form id={'registrationForm'} onSubmit={validateFrom} loading={loading}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field control={Input}
                                label={'아이디'}
                                pattern={'^[a-z0-9]{4,15}'}
                                title={'영문으로 시작하는 4~15자리의 문자'}
                                value={registrationModalOption.data.loginId}
                                error={registrationModalOption.error.loginId}
                                required
                                onChange={(event, {value}) => {
                                  changeRegistrationData('loginId', value);
                                }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Field control={Input}
                                type={'password'}
                                label={'패스워드'}
                                pattern={'.{8,20}'}
                                title={'최소 8자리 이상'}
                                required
                                value={registrationModalOption.data.password}
                                error={registrationModalOption.error.passwordCheck !== null}
                                onChange={(event, {value}) => {
                                  changeRegistrationData('password', value);
                                }}/>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field control={Input}
                                type={'password'}
                                label={'패스워드 확인'}
                                pattern={'.{8,20}'}
                                title={'최소 8자리 이상'}
                                required
                                value={registrationModalOption.data.passwordCheck}
                                error={registrationModalOption.error.passwordCheck}
                                onChange={(event, {value}) => {
                                  changeRegistrationData('passwordCheck', value);
                                }}/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field control={Input}
                                label={'파트너사명'}
                                required
                                value={registrationModalOption.data.name}
                                onChange={(event, {value}) => {
                                  changeRegistrationData('name', value);
                                }}/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{marginTop: `2em`, textAlign: `center`, overflow:'hidden'}}>
            <Button type={'submit'}
                    form={'registrationForm'}
                    floated={'right'}
                    loading={loading}
                    disabled={loading}
                    size='small'
                    color='blue'>
              <Icon name='add user'/> 회원가입
            </Button>
            <Button floated={'left'} size='small' color='red' onClick={() => setRegistrationModalOption(prevState => {
              return {
                ...prevState,
                open: false
              }
            })}>
              <Icon name='remove'/> 닫기
            </Button>
          </Modal.Actions>
      </Modal>
      <SemanticToastContainer position="top-right" />
    </Container>
  );
}

export default withRouter(CarmonAdminLoginFromLayout)

