import React, { useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Grid, Segment} from 'semantic-ui-react';
import CarmonAdminNaviBar from './Navibar';
import CarmonSideMenu from './Menu';
import CarmonAdminFooter from './Footer';
import CarmonAdministrator from '../components/admin/Administrator';
import ErrorPage404 from '../errors/Error404';
import CarmonReservationList from '../components/admin/ReservationList';
import CarmonReservationInformation from '../components/admin/ReservationInformation';
import CarmonDashBoard from '../components/admin/Dashboard';
import CarmonCenterList from '../components/admin/CenterList';
import {SemanticToastContainer} from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import CarmonDealerList from '../components/admin/DealerList';
import CarmonAuction from '../components/admin/AuctionList';
import CarmonMessageSendHistory from '../components/admin/MessageSendHistory';

import CCJRequestList from '../components/admin/chachatja/CCJRequestList';
import CarmonDelivery from '../components/admin/CarmonDelivery';
import RepairReportRequestList from '../components/admin/chachatja/RepairReportRequestList';
import AccidentHistory from '../components/admin/partners/AccidentHistory';

const CarmonAdminMain = () => {
  const [sideMenuHide, setSideMenuHide] = useState(false);

  return (
    <>
      <CarmonAdminNaviBar sideMenuHide={sideMenuHide} setSideMenuHide={setSideMenuHide} />
      <CarmonSideMenu sideMenuHide={sideMenuHide} />

      <Segment basic style={{marginLeft: sideMenuHide ? '0' : `15rem`, marginTop: `0`}}>
        <Grid style={{height: `100%`}}>
          <Grid.Column>
            <Switch>
              <Route exact path="/administrator">
                <CarmonAdministrator />
              </Route>

              <Route exact path="/reservation-information">
                <CarmonReservationInformation />
              </Route>

              <Route exact path="/carmon-delivery">
                <CarmonDelivery />
              </Route>

              <Route exact path="/auction">
                <CarmonAuction />
              </Route>

              <Route exact path="/dealer">
                <CarmonDealerList />
              </Route>

              <Route exact path="/reservation">
                <CarmonReservationList />
              </Route>

              <Route exact path="/center">
                <CarmonCenterList />
              </Route>

              <Route exact path="/at-history">
                <CarmonMessageSendHistory />
              </Route>

              <Route exact path="/ccj-requests">
                <CCJRequestList />
              </Route>

              <Route exact path="/repair-report-requests">
                <RepairReportRequestList />
              </Route>

              <Route exact path="/accident_history">
                <AccidentHistory />
              </Route>

              <Route path="/">
                <CarmonDashBoard />
              </Route>

              <Route component={ErrorPage404} />
            </Switch>
          </Grid.Column>
        </Grid>
      </Segment>
      <CarmonAdminFooter />
      <SemanticToastContainer position="top-right" />
    </>
  );
};


export default CarmonAdminMain;
