import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Header, Modal, Segment} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {BANK_TYPE} from '../../consts/Consts';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import moment from 'moment';

const CarmonSellerDeposit = ({open, close, paymentId, callback}) => {
  const [payment, setPayment] = useState({});
  const bankData = useRef([]);

  useEffect(() => {
    Object.entries(BANK_TYPE).map(([value]) => {
      retrieveBankCode(value);
    });
  }, []);

  useEffect(() => {
    if (paymentId && paymentId > 0) retrievePaymentInfo();
  }, [paymentId]);

  const retrieveBankCode = async (codeGroup) => {
    try {
      const response = await axios.get(`/apis/common/code_list/${codeGroup}`);
      if (response.status === 200) {
        bankData.current = [...bankData.current, ...response.data];
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const retrievePaymentInfo = async () => {
    try {
      const response = await axios.get(`/apis/admin/payment/${paymentId}`);

      if(response.status === 200) {
        if(response.data['sellerBankCode']) {
          response.data['sellerBankName'] = bankData.current.filter(v => v.code === response.data['sellerBankCode'])[0].name;
        }

        if (response.data['sellerDepositYn'] === "Y") {
          response.data.sellerDepositTime = moment(response.data.sellerDepositTime).format('YYYY-MM-DDTHH:mm');
        }

        setPayment(response.data);
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const svaePayment = async () => {
    try {
      payment['sellerDepositTime'] = new Date(payment['sellerDepositTime']).toISOString();

      const response = await axios.post(`/apis/admin/payment`, payment)

      if(response.status === 200) {
        callback && callback();
        close && close();
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const eventHandler = {
    onClickSave: () => {
      svaePayment();
    }
  }

  return (
    <Modal size='small'
           centered={false}
            open={open}
            closeIcon
            onClose={() => {close && close()}}>
      <Header as='h2'>판매자 차량 대금입금 정보입력</Header>
      <Segment basic>
        <Form>
          <Form.Input fluid
                     label='입금은행'
                     placeholder='은행명'
                     name='bankName'
                     readOnly
                     value={payment.sellerBankName || ''} />
          <Form.Input fluid
                      label='계좌번호'
                      placeholder='계좌번호'
                      name='bankName'
                      readOnly
                      value={payment.sellerBankAccount || ''} />

          <Form.Field control={NumberFormat}
                      thousandSeparator
                      label='입금예정금액'
                      placeholder='입금예정금액'
                      name='bidAmount'
                      readOnly
                      value={payment.bidAmount || ''} />

          <Form.Field required
                      control={NumberFormat}
                      thousandSeparator
                      label='입금금액'
                      placeholder='입금금액'
                      name='sellerDepositAmount'
                      onValueChange={
                        (values) => {
                          setPayment(prevState => {
                            return {
                              ...prevState,
                              sellerDepositAmount: _.toNumber(values['value'])
                            }
                          });
                        }
                      }
                      value={payment.sellerDepositAmount || 0} />
          <Form.Input fluid
                      required
                      type='datetime-local'
                      label='입금시간'
                      placeholder='입금시간'
                      name='sellerDepositTime'
                      onChange={(el, {name, value}) => {
                        setPayment(prevState => {
                          return {
                            ...prevState,
                            [name]: value
                          }
                        });
                      }}
                      value={payment.sellerDepositTime || ''} />
        </Form>
      </Segment>
      <Segment>
        <Button icon='save' content='저장' color='teal' onClick={eventHandler.onClickSave}/>
        <Button floated='right' icon='times' color='red' content='닫기' onClick={() => close && close()}/>
      </Segment>
    </Modal>
  );
}

export default CarmonSellerDeposit;