import React, {useEffect, useState} from 'react';
import {Button, FormInput, Modal, Select, Table} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import _ from 'lodash';
import {CommonUtil} from '../../utils/CommonUtil';

const CONTACT_TYPE = [
  {key: 'CEL', value: 'CEL', text: '휴대폰'},
  {key: 'TEL', value: 'TEL', text: '일반전화'},
  {key: 'FAX', value: 'FAX', text: 'FAX'},
  {key: 'MAIL', value: 'MAIL', text: '이메일'}
];

const DEFAULT_CONTACT_VALUE = {contactType: '', contactName: '', contactNumber: ''};

const CenterContact = ({open, close, center}) => {
  useEffect(async () => {
    setModalOption(prevState => ({...prevState, open: open}));

    if(open) await retrieveCenterContactList();
  }, [open]);

  const [modalOption, setModalOption] = useState({open: open});
  const [newContact, setNewContact] = useState(DEFAULT_CONTACT_VALUE);

  const [contactList, setContactList] = useState([]);
  const closeModal = () => {
    setModalOption(prevState => ({...prevState, open: false}));
    close && close();
  }

  const retrieveCenterContactList = async () => {
    try {
      const response = await axios.get(`/apis/admin/center/contact/${center.centerId}`);

      _.forEach(response.data, o => {
        o.uuid = CommonUtil.randomUUID();
      });

      setContactList(response.data);
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 10000,
        description: JSON.stringify(error.response.data)
      });
    }
  }

  const saveCenterContact = async () => {
    const response = await axios.post(`/apis/admin/center/contact`, contactList);

    if (response.status === 200) {
      toast({
        type: "success",
        title: "성공",
        time: 2000,
        description: "정비소 연락처 정보가 저장되었습니다."
      });
      retrieveCenterContactList();
    }
  }

  const onChangeField = (uuid, name, value) => {
    setContactList(prevState => {
      const list = _.cloneDeep(prevState);
      const index = _.findIndex(list, {uuid: uuid});
      _.set(list[index], name, value);

      return [...list];
    });
  }

  const onChangeNewField = (e, {name, value}) => {
    setNewContact(prevState => {
      return {...prevState, [name]: value}
    });
  }

  const addContact = () => {
    if (_.isEmpty(newContact.contactType) || _.isEmpty(newContact.contactName) || _.isEmpty(newContact.contactNumber)) return;

    let uuid = CommonUtil.randomUUID();

    setContactList(prevState => {
      return [
        ...prevState,
        {uuid: uuid, centerId: center.centerId, ...newContact}
      ]
    })

    setNewContact({contactType: '', contactName: '', contactNumber: ''});
  }

  const removeContact = (uuid) => {
    setContactList(prevState => {
      const list = _.cloneDeep(prevState);
      _.pullAllBy(list, [{uuid: uuid}], 'uuid');

      return [...list];
    })
  }

  return (
    <Modal open={modalOption.open}
           onClose={closeModal}
           size={'small'}
           centered={false}>
      <Modal.Header>
        [{center && center.name}] 연락처 관리
      </Modal.Header>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>타입</Table.HeaderCell>
              <Table.HeaderCell width={5}>관리명</Table.HeaderCell>
              <Table.HeaderCell width={5}>번호</Table.HeaderCell>
              <Table.HeaderCell collapsing></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              _.map(contactList, o => {


                return (
                  <Table.Row key={o.uuid}>
                    <Table.Cell>
                      <Select fluid
                        name='contactType'
                        onChange={(e, {name, value}) => onChangeField(o.uuid, name, value)}
                        options={CONTACT_TYPE}
                        defaultValue={o.contactType}
                        placeholder='연락처타입' />
                    </Table.Cell>
                    <Table.Cell>
                      <FormInput fluid defaultValue={o.contactName} name='contactName' onChange={(e, {name, value}) => onChangeField(o.uuid, name, value)}></FormInput>
                    </Table.Cell>
                    <Table.Cell>
                      <FormInput fluid defaultValue={o.contactNumber} name='contactNumber' onChange={(e, {name, value}) => onChangeField(o.uuid, name, value)}></FormInput>
                    </Table.Cell>
                    <Table.Cell >
                      {
                        <Button circular size={'mini'} color={'red'} icon={'minus'} onClick={() => removeContact(o.uuid)}/>
                      }
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
            <Table.Row>
              <Table.Cell>
                <Select fluid
                        name='contactType'
                        onChange={onChangeNewField}
                        options={CONTACT_TYPE}
                        value={newContact.contactType}
                        placeholder='연락처타입' />
              </Table.Cell>
              <Table.Cell>
                <FormInput fluid name={'contactName'} value={newContact.contactName} onChange={onChangeNewField}></FormInput>
              </Table.Cell>
              <Table.Cell>
                <FormInput fluid name={'contactNumber'} value={newContact.contactNumber} onChange={onChangeNewField}></FormInput>
              </Table.Cell>
              <Table.Cell >
                {
                  <Button circular size={'mini'} color={'blue'} icon={'plus'} onClick={addContact} />
                }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

      </Modal.Content>
      <Modal.Actions>
        <Button icon={'save'} color={'teal'} content={'저장'} onClick={saveCenterContact} />
        <Button icon={'times'} color={'red'} content={'닫기'} onClick={closeModal} />
      </Modal.Actions>
    </Modal>
  );
}

export default CenterContact;
