import React, {useEffect, useReducer, useRef, useState} from 'react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Input,
  Menu, Popup,
  Segment,
  Select,
  Table,
} from 'semantic-ui-react';
import {KAKAO_AT_RESULT} from '../../consts/Consts';

import moment from 'moment';
import CarmonConfirm from '../common/Confirm';
import _ from 'lodash';
import JsonContentModal from '../popup/JsonContentModal';

const CarmonMessageSendHistory = () => {
  const LIST_COUNT_PER_PAGE = 10;
  const MAX_PAGE_COUNT = 10;

  const maxPage = useRef(1);
  const [page, setPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);

  const [messageType, setMessageType] = useState([]);
  const [resultType, setResultType] = useState([]);
  const [searchParams, setSearchParams] = useState({
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    toNumber: '',
    messageType: null,
    resultCode: null
  });

  const [resendConfirm, setResendConfrim] = useState({
    title: '재전송',
    content: '',
    open: false,
    close: () => {setResendConfrim(prevState => ({...prevState, open: false}))},
    callback: null
  });
  const [contentModal, setContentModal] = useState({
    open: false,
    close: () => {setContentModal(prevState => ({...prevState, open: false}))},
    content: ''});

  const historyDispatch = (state, action) => {
    switch (action.type) {
      case 'data':
        return {
          ...state,
          data: action.data.sort((o1, o2) => {
            if (o1.regTime < o2.regTime) return 1;
            if (o1.regTime > o2.regTime) return -1;
            return 0;
          })
        }
    }
  }

  const [history, dispatch] = useReducer(historyDispatch, {
    data: [],
    column: null,
    direction: 'ascending',
  });

  useEffect(() => {
    init();
    retrieveHistory();
  }, []);

  const init = () => {
    initResultType();
    retrieveMessageType();
  };

  const initResultType = () => {
    const resultOptions = [{key: null, value: null, text: '전체'}];

    KAKAO_AT_RESULT.map(v => {
      resultOptions.push({key: v.code, value: v.code, text: `[${v.code}] ${v.message}`});
    });

    setResultType(resultOptions);
  };

  const getResultMessage = (code) => {
    return code ? KAKAO_AT_RESULT.filter(v => v.code === _.toNumber(code))[0]['message'] : '';
  };

  const retrieveMessageType = async () => {
    try {
      let res = await axios.get('/apis/common/at-message/type');

      if (res.status === 200) {
        const messageTypeOptions = [{key: null, value: null, text: '전체'}];

        res.data.map(v => {
          messageTypeOptions.push({key: v.id, value: v.code, text: `${v.name}`});
        });

        setMessageType(messageTypeOptions);
      }
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const retrieveHistory = async () => {
    try {
      const res = await axios.post('/apis/admin/message/history', searchParams);
      if (res.status === 200) {
        setPage(1);
        dispatch({type: 'data', data: res.data});
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const eventHandler = {
    onChangeSearchForm: (e, {name, value}) => {
      setSearchParams(prevState => {
        return {
          ...prevState,
          [name]: value
        };
      });
    },
    onClickReSend: (row) => {
      setResendConfrim(prevState => {
        return {
          ...prevState,
          open: true,
          content: <span>[{row.toNumber}] 번호로 메세지를 재전송하시겠습니까? <br />메세지는 비동기로 전송됩니다. 전송 결과는 잠시 후 다시 조회해주세요.</span>,
          callback: () => {
            axios.post('/apis/admin/message/send', row).then(() => {
            }).catch((error) => {
              toast({
                type: "error",
                title: "ERROR",
                time: 5000,
                description: error.response && error.response.data && error.response.data.message || error
              });
            });
          }};
      });
    }
  }

  const renderMessageList = () => {
    const renderer = [];
    const pageStartIdx = (page - 1) * LIST_COUNT_PER_PAGE;
    const pageFinishIdx = (page * LIST_COUNT_PER_PAGE) - 1;

    if (history.data.length === 0) {
      renderer.push(
        <Table.Row key={'empty'}>
          <Table.Cell colSpan={12} textAlign={'center'}>
            <Icon name={'meh outline'} size={'huge'} />
            <Header as={'h4'}>알림톡 발송 내역이 없습니다.</Header>
          </Table.Cell>
        </Table.Row>
      );
    }

    history.data.map((v, idx) => {
      if (idx < pageStartIdx) return;
      if (idx > pageFinishIdx) return false;

      renderer.push(
        <Table.Row
          key={v.messageSendHistoryId}
          negative={v.resultCode !== 7000}
        >
          <Table.Cell>{v.messageSendHistoryId}</Table.Cell>
          <Table.Cell>{v.refKey}</Table.Cell>
          <Table.Cell>{v.msgKey}</Table.Cell>
          <Table.Cell>
            {v.msgType}
            <Header as={'h5'}>
              {
                messageType && messageType.find(o => o.value === v.msgType) && messageType.find(o => o.value === v.msgType)['text']
              }
            </Header>
          </Table.Cell>
          <Table.Cell>
            <Button color='green' content={'내용보기'} onClick={() => {
              setContentModal(prevState => ({...prevState, open: true, content: JSON.parse(v.msgContent)}));
            }} /></Table.Cell>
          <Table.Cell>{v.toNumber}</Table.Cell>
          <Table.Cell>{v.regTime}</Table.Cell>
          <Table.Cell>{v.resCode}</Table.Cell>
          <Table.Cell>{v.resMsg}</Table.Cell>
          <Table.Cell>
            <Popup
              trigger={<span>{v.resultCode}</span>}
              content={getResultMessage(v.resultCode)}
              position='bottom center'
            />
          </Table.Cell>
          <Table.Cell textAlign={'right'}>
            {v.resendHistoryId}
          </Table.Cell>
          <Table.Cell>
            <Button color={'blue'}
                    icon={'paper plane'}
                    onClick={() => eventHandler.onClickReSend(v)} />
                    {/*disabled={v.resultCode === 7000 || v.resendHistoryId > 0}*/}
          </Table.Cell>
        </Table.Row>
      );
    });

    return renderer;
  }

  const renderPagination = () => {
    const renderer = [];
    let total = 0;

    if (history.data.length > LIST_COUNT_PER_PAGE) {
      renderer.push(
        <Menu.Item key='prevPageGroup' as='a' icon='chevron left' onClick={() => {
          if (pageGroup > 1) setPageGroup(pageGroup - 1);
        }}/>
      );

      for (let i = ((pageGroup - 1) * MAX_PAGE_COUNT + 1); i <= Math.ceil(history.data.length / LIST_COUNT_PER_PAGE); i++) {
        if (i > pageGroup * MAX_PAGE_COUNT) break;
        renderer.push(<Menu.Item key={i} as='a' onClick={() => setPage(i)}
                               style={{background: page === i ? `rgb(0, 0, 0, .1)` : `0 0`}}>{i}</Menu.Item>);
        total = i;
      }

      renderer.push(
        <Menu.Item key='nextPageGroup' as='a' icon='chevron right' onClick={() => {
          if (Math.ceil(history.data.length / LIST_COUNT_PER_PAGE) > (pageGroup * MAX_PAGE_COUNT)) {
            setPageGroup(pageGroup + 1);
          }
        }}/>
      );
    }

    maxPage.current = total + 1;

    return renderer;
  }

  return (
    <div>
      <Container fluid>
        <Segment attached='top'>
          <Form>
            <Form.Group widths='3'>
              <Form.Field>
                <label>발송일자</label>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Input size='small'
                           type='date'
                           name='fromDate'
                           defaultValue={moment().format("YYYY-MM-DD")}
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                  ~
                  <Form.Field>
                    <Input size='small'
                           type='date'
                           name='toDate'
                           defaultValue={moment().format("YYYY-MM-DD")}
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                </Form.Group>
              </Form.Field>

              <Form.Field
                name='toNumber'
                control={Input}
                label='수신번호'
                placeholder='수신번호'
                onChange={eventHandler.onChangeSearchForm}
              />

              <Form.Field
                name='messageType'
                control={Select}
                options={messageType}
                label='메세지 타입'
                placeholder='메세지 타입'
                onChange={eventHandler.onChangeSearchForm}
              />

              <Form.Field
                name='resultCode'
                control={Select}
                options={resultType}
                label='발송결과'
                placeholder='발송결과'
                onChange={eventHandler.onChangeSearchForm}
              />
            </Form.Group>
          </Form>
        </Segment>
        <Segment attached='bottom' style={{background: `#f9fafb`}}>
          <Container fluid textAlign='right'>
            <Button.Group>
              <Button color='teal' onClick={retrieveHistory}><Icon name='search'/>Search</Button>
            </Button.Group>
          </Container>
        </Segment>
      </Container>

      <Segment basic style={{overflowX: 'auto', padding: 0}}>
        <Table sortable celled selectable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                ID
              </Table.HeaderCell>

              <Table.HeaderCell>
                Ref Key
              </Table.HeaderCell>

              <Table.HeaderCell>
                Message Key
              </Table.HeaderCell>

              <Table.HeaderCell>
                메세지 타입
              </Table.HeaderCell>

              <Table.HeaderCell>
                메세지 내용
              </Table.HeaderCell>

              <Table.HeaderCell>
                발송 번호
              </Table.HeaderCell>

              <Table.HeaderCell>
                전송시간
              </Table.HeaderCell>

              <Table.HeaderCell>
                응답코드
              </Table.HeaderCell>

              <Table.HeaderCell>
                응답메세지
              </Table.HeaderCell>

              <Table.HeaderCell>
                결과코드<br />(결과메세지)
              </Table.HeaderCell>

              <Table.HeaderCell>
                재발송 ID
              </Table.HeaderCell>

              <Table.HeaderCell>
                재전송
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {renderMessageList()}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='17'>
                <Header floated='left'>Total {history.data.length}건</Header>
                <Menu floated='right' pagination>
                  {renderPagination()}
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>

      <JsonContentModal {...contentModal} />

      <CarmonConfirm {...resendConfirm} />
    </div>
  );
};

export default CarmonMessageSendHistory;
