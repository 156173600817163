import React from 'react'
import CenterData from './CenterData'

const CenterList = ({ pageData, ...props }) => {

  return (
    pageData.map((centerData, idx) => {
      return <CenterData centerData={centerData} key={idx} {...props} />
    })
  )
}

export default CenterList