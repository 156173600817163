import {CARNAWA_BASE_URL} from '../../Consts';

export const MOBILE_REPORT_TEMPLATE = `
<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html lang="ko">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  
  <meta name="author" content="카몬 CARMON">
  <link rel="canonical" href="https://ext.carmon.in"/>
  <meta name="robots" content="index, follow">
  <meta name="title" content="카몬 CARMON &mdash; 카몬스터 | Car Monster">
  <meta name="description" content="중고차 시장에 신뢰를 더하고자 합니다. 판매자도, 구매자도 믿을 수 있는 중고차 거래 플랫폼 카몬입니다.">
  <meta name="keywords" content="차,자동차,중고차,판매,팔기,구매,사기,카몬,딜러,내차팔기,헤이딜러,첫차">
  <meta name="language" content="Korean">
  <meta name="contact" content="carmonster000@gmail.com">
  <meta property="og:type" content="website"/>
  <meta property="og:title" content="카몬 CARMON &mdash; 카몬스터 | Car Monster"/>
  <meta property="og:description" content="중고차 시장에 신뢰를 더하고자 합니다. 판매자도, 구매자도 믿을 수 있는 중고차 거래 플랫폼 카몬입니다."/>
  <meta property="og:site_name" content="카몬 CARMON">
  <meta name="twitter:card" content="summary"/>
  <meta name="twitter:title" content="카몬 CARMON &mdash; 카몬스터 | Car Monster"/>
  <meta name="twitter:description" content="중고차 시장에 신뢰를 더하고자 합니다. 판매자도, 구매자도 믿을 수 있는 중고차 거래 플랫폼 카몬입니다."/>

  <title>카나와 가격비교 리포트 by 카몬</title>

  <style type="text/css">
    
    /* Outlines the grids, remove when sending */
    table td { padding-top: 6px; padding-bottom: 6px; }

    /* CLIENT-SPECIFIC STYLES */
    body, table, td, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
    table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
    img { -ms-interpolation-mode: bicubic; }

    /* RESET STYLES */
    img { border: 0; outline: none; text-decoration: none; }
    table { border-collapse: collapse !important; }
    body { margin: 0 !important; padding: 0 !important; width: 100% !important; }

    /* iOS BLUE LINKS */
    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    /* ANDROID CENTER FIX */
    div[style*="margin: 16px 0;"] { margin: 0 !important; }
    
    #tbl-car-information td { border: 1px solid #2224261a; padding: 6px!important; }
    #tbl-car-information td:nth-child(2n-1) { background-color: #00b5ad66; }
    #tbl-price-list td { border: 1px solid #2224261a; padding: 6px!important; }

    /* MEDIA QUERIES */
    @media all and (max-width:639px){ 
      *[class*="wrapper"]{ width:320px!important; padding: 0 !important; }
      .container{ width:300px!important;  padding: 0 !important; }
      .mobile{ width:300px!important; display:block!important; padding: 0 !important; }
      .img{ width:100% !important; height:auto !important; }
      *[class*="mobileOff"] { width: 0px !important; display: none !important; }
      *[class*="mobileOn"] { display: block !important; max-height:none !important; }
    }
    
    @media all and (min-width:640px) {
      *[class*="wrapper"]{ width:640px!important; padding: 0 !important; }
      .container{ width:600px!important;  padding: 0 !important; }
      .img{ width:100% !important; height:auto !important; }
      *[class*="mobileOff"] { display: block !important; max-height:none !important; }
      *[class*="mobileOn"] { width: 0px !important; display: none !important; }
    }

  </style>    
</head>
<body style="margin:0; padding:0; background-color:#F2F2F2;">
  
  <span style="display: block; width: 640px !important; max-width: 640px; height: 1px" class="mobileOff"></span>
  
  <center>
    <table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#F2F2F2">
      <tr>
        <td align="center" valign="top">
            
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#00b5ad" style="color: #ffffff;">
            <tr>
              <td align="center" valign="top" style="padding: 12px 0;">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td align="center" valign="top" style="border-bottom: 1px solid #ffffff">
                      <h1 style="margin: 0">카나와 가격비교 리포트</h1>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" valign="top">
                      [[##_차량번호_##]] 차량에 대한 가격 비교 리포트입니다.
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td align="right" valign="top" style="font-size: 14px">
                      - 요청일시 : [##_요청일시_##]
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td align="left" valign="top">
                      <h3>1. 차량정보</h3>
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper mobileOff" bgcolor="#FFFFFF" style="font-size: 14px;">
            <tr>
              <td width="640" align="center" valign="top">

                <table id="tbl-car-information" width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>차량번호</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_차량번호_##]
                    </td>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>모델</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_모델_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>연식</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_연식_##]
                    </td>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>최초등록일</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_최초등록일_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>차종</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_차종_##]
                    </td>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>용도</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_용도_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>연료</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_연료_##]
                    </td>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>배기량</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_배기량_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>색상</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_색상_##]
                    </td>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>정원</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_정원_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>변속기</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_변속기_##]
                    </td>
                    <td width="80" class="mobile" align="left" valign="top">
                      <b>주행거리</b>
                    </td>
                    <td width="220" class="mobile" align="left" valign="top">
                      [##_주행거리_##]
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
          
          <table width="320" cellpadding="0" cellspacing="0" border="0" class="wrapper mobileOn" bgcolor="#FFFFFF" style="font-size: 14px;">
            <tr>
              <td width="320" align="center" valign="top">

                <table id="tbl-car-information" width="300" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>차량번호</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_차량번호_##]
                    </td>
                  </tr>
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>모델</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_모델_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>연식</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_연식_##]
                    </td>
                  </tr>
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>최초등록일</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_최초등록일_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>차종</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_차종_##]
                    </td>
                  </tr>
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>용도</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_용도_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>연료</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_연료_##]
                    </td>
                  </tr>
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>배기량</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_배기량_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>색상</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_색상_##]
                    </td>
                  </tr>
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>정원</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_정원_##]
                    </td>
                  </tr>
                  
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>변속기</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_변속기_##]
                    </td>
                  </tr>
                  <tr>
                    <td width="100" align="left" valign="top">
                      <b>주행거리</b>
                    </td>
                    <td width="200" align="left" valign="top">
                      [##_주행거리_##]
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
            <tr>
              <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
            </tr>
          </table>
          
          [##_상세옵션_##]
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td align="left" valign="top">
                      <h3>2. 가격리스트</h3>
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>

          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper mobileOff" bgcolor="#FFFFFF" style="font-size: 14px">
            <tr>
              <td width="640" align="center" valign="top">

                <table id="tbl-price-list" width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr style="background-color: #00b5ad66;">
                      <td width="40" class="mobile" align="center" valign="top">
                        <b>순위</b>
                      </td>
                      <td width="215" class="mobile" align="center" valign="top">
                        <b>매매사이트</b>
                      </td> 
                      <td width="90" class="mobile" align="center" valign="top">
                        <b>차량가격</b>
                      </td>
                      <td width="105" class="mobile" align="center" valign="top">
                        <b>중개수수료</b>
                      </td>
                      <td width="155" class="mobile" align="center" valign="top">
                        <b>구매후보증</b>
                      </td>
                  </tr>
                  
                  [##_가격리스트_640_##]
                </table>

              </td>
            </tr>
          </table>
          
          <table width="320" cellpadding="0" cellspacing="0" border="0" class="wrapper mobileOn" bgcolor="#FFFFFF" style="font-size: 14px">
            <tr>
              <td width="320" align="center" valign="top">

                <table id="tbl-price-list" width="300" cellpadding="0" cellspacing="0" border="0" class="container">
                  [##_가격리스트_320_##]
                </table>

              </td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td align="left" class="mobile" valign="top" style="font-size: 14px">
                      ※ 중고차 실구매 비용<br/>
                      <span style="font-size: 12px">차량가격 + 취등록세 + 인지대, 중지대 + 중고차 매도비 + 알선 수수료 + 성능 보험료 + 등록신청대행수수료</span>
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
            <tr>
              <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
            </tr>            
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" onclick="javascript:window.open('${CARNAWA_BASE_URL}/send-report?req=[##_요청아이디_##]', '_blank')" style="cursor: pointer">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" bgcolor="#f3f4f5" style="border: 1px solid #d4d4d5">
                  <tr>
                    <td width="60" align="center" valign="middle" style="font-size: 14px; padding: 6px;">
                      <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/no-data.png" width="36px" height="auto" style="margin:0; padding:0; border:none; display:block;" border="0" alt="" />
                    </td>
                    <td width="540" align="left" valign="middle" style="font-size: 14px; padding: 12px; word-break: keep-all">
                      <h4 style="display: block; margin-top: 0; margin-bottom: 8px">
                        가격비교 결과가 나오지 않았나요?
                        <img src="${'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'}" alt="" style="width: 16px; height: auto; vertical-align: top;" />
                      </h4>
                      차량을 발견한 중고차 업체를 카몬에 알려주세요!
                      보다 정확하고 상세한 가격비교 서비스를 제공하는데 도움이 됩니다.
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
            <tr>
              <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" onclick="javascript:window.open('${CARNAWA_BASE_URL}/send-request?req=[##_요청아이디_##]', '_blank')" style="cursor: pointer;">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" bgcolor="#f3f4f5" style="border: 1px solid #d4d4d5">
                  <tr>
                    <td width="60" align="center" valign="middle" style="font-size: 14px; padding: 6px;">
                      <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/call-center.png" width="36px" height="auto" style="margin:0; padding:0; border:none; display:block;" border="0" alt="" />
                    </td>
                    <td width="540" align="left" valign="middle" style="font-size: 14px; padding: 12px; word-break: keep-all">
                      <h4 style="display: block; margin-top: 0; margin-bottom: 8px">
                        원하는 차량이 있으신가요?
                        <img src="${'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'}" alt="" style="width: 16px; height: auto; vertical-align: top;" />
                      </h4>
                      카몬에 문의하세요! 원하시는 차량을 카몬에서 직접 찾아드립니다.
                      거기에 중개수수료 없이! 기본 보증은 물론, 추가 보증 6개월까지!
                      지금 바로 문의하세요!
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
            <tr>
              <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#1b1c1d" style="color: #ffffff">
            <tr>
              <td align="center" valign="top" style="padding: 12px 0;">
          
                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td width="250" class="mobile" align="left" valign="middle">
                      <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/logo/carmon.png" width="180" height="auto" style="margin:0; padding:0; border:none; display:block;" border="0" alt="" />
                    </td>
                    <td width="350" class="mobile" align="left" valign="middle">
                      <h4 style="margin: 10px 0">카몬 | 주식회사 카몬스터</h4>
                      <span style="font-size: 14px; display: block">[제휴문의] <a href="mailto:carmonster000@gmail.com" style="color:#ffffff; text-decoration:underline;">carmonster000@gmail.com</a></span>
                      <span style="font-size: 14px; display: block">[고객센터] <a href="tel:070-7576-4981" style="color:#ffffff; text-decoration:underline;">070-7576-4981</a></span>
                    </td>
                  </tr>
                </table>
          
              </td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#1b1c1d" style="color: #ffffff">
            <tr>
              <td align="center" valign="top">
          
                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td width="250" class="mobile" align="left" valign="middle">
                      <h4 style="margin: 0">카몬 서비스들</h4>
                    </td>
                    <td width="100" class="mobile" align="left" valign="middle">
                      <a href="https://carmon.in" target="_blank" title="carmon.in" style="font-size: 14px; color:#ffffff; text-decoration:underline;">
                        내차팔기
                        <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/open-link.png" width="16" height="auto" style="margin:0; padding:0; border:none; display:inline-block; vertical-align: middle" border="0" alt="" />
                      </a>
                    </td>                    
                    <td width="100" class="mobile" align="left" valign="middle">
                      <a href="https://carnawa.co.kr/carnawa" target="_blank" title="carnawa.co.kr" style="font-size: 14px; color:#ffffff; text-decoration:underline;">
                        가격비교
                        <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/open-link.png" width="16" height="auto" style="margin:0; padding:0; border:none; display:inline-block; vertical-align: middle" border="0" alt="" />
                      </a>
                    </td>
                    <td width="150" class="mobile" align="left" valign="middle">
                      <a href="https://carnawa.co.kr/repair" target="_blank" title="carnawa.co.kr" style="font-size: 14px; color:#ffffff; text-decoration:underline;">
                        숨은 보상금 찾기
                        <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/open-link.png" width="16" height="auto" style="margin:0; padding:0; border:none; display:inline-block; vertical-align: middle" border="0" alt="" />
                      </a>
                    </td>
                  </tr>
                </table>
                
              </td>
            </tr>
          </table>
          
          <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#1b1c1d" style="color: #ffffff;">
            <tr>
              <td align="center" valign="top">

                <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
                  <tr>
                    <td width="600" style="max-width: 600px; word-break: keep-all" align="center" valign="top">
                      (주)카몬스터는 판매자와 구매자가 서로 믿고 거래할 수 있는 데이터 기반 중고차 거래 문화를 만들어 갑니다.
                    </td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
          
        </td>
      </tr>
    </table>
  </center>
</body>
</html>
`;