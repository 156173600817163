import React from 'react'
import {Container, Button, Segment, Menu, Icon, Header} from 'semantic-ui-react'
import {useHistory} from "react-router-dom"
import axios from "axios";


const CarmonAdminNaviBar = ({sideMenuHide = false, setSideMenuHide}) => {
  let history = useHistory();

  const onClickLogout = async () => {
    try {
      const response = await axios.post('/apis/token/remove');
      if (response.status === 200) {
        sessionStorage.removeItem("access-token");
        sessionStorage.removeItem("refresh-token");
        history.push('/login');
      }
    } catch (ex) {
      console.log(ex.response);
    }
  }

  const onClickMenuControl = () => {
    setSideMenuHide(!sideMenuHide);
  }

  return (
    <Container fluid className='carmon-top-navi'>
      <Segment basic
               color='orange'
               inverted
               style={{
                 width: '100%',
                 minHeight: '56px',
                 padding: '0'
               }}
               as={Menu}
               fixed="top">
        <Menu.Item style={{width: '15rem'}}>
          <Header inverted as='h3' onClick={() => location.href = "/"}>CARMON ADMIN</Header>
        </Menu.Item>
        <Menu.Item>
          <a onClick={onClickMenuControl}><Icon name='bars' size='large'/></a>
        </Menu.Item>

        <Menu.Item position="right">
          <Button color='yellow' onClick={onClickLogout}><Icon name='log out'/>Log out</Button>
        </Menu.Item>
      </Segment>
    </Container>
  );
}

export default CarmonAdminNaviBar
