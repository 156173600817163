import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {Container} from 'semantic-ui-react';

const WindowPopup = ({ url, children, close, title, width, height}) => {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);

  useEffect(() => {
    setContainer(document.createElement("div"));
  }, []);

  useEffect(() => {
    if (container) {
      newWindow.current = window.open(
        url || '',
        '_blank',
        `
          width=${width},
          height=${height},
          left=${window.screen.availWidth / 2 - (width / 2)}, 
          top=${window.screen.availHeight / 2 - (height / 2)}
        `
      );

      newWindow.current.document.body.appendChild(container);

      if (title) newWindow.current.document.title = title;

      newWindow.current.onbeforeunload = () => {
        close();
      };

      copyStyles(window.document, newWindow.current.document);

      const curWindow = newWindow.current;

      return () => curWindow.close();
    }
  }, [container]);

  return container && createPortal(<Container style={{padding:'25px 0'}}>{children}</Container>, container);
};

const copyStyles = (src, dest) => {
  Array.from(src.styleSheets).forEach(styleSheet => {
    dest.head.appendChild(styleSheet.ownerNode.cloneNode(true))
  })
  Array.from(src.fonts).forEach(font => dest.fonts.add(font))
}

export default WindowPopup;
