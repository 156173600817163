import React, {useEffect, useState} from 'react'
import {Grid, Header, Image, Modal, Segment} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-semantic-toasts";
import Slider from "react-slick";

const PARTS = [
  {
    position: 'outFront',
    position_name: '정면',
  },
  {
    position: 'outLeft',
    position_name: '측면 (왼쪽)',
  },
  {
    position: 'outRight',
    position_name: '측면 (오른쪽)',
  },
  {
    position: 'outRear',
    position_name: '후방',
  },
  {
    position: 'outTop',
    position_name: '상판',
  },
  {
    position: 'bottom',
    position_name: '하부',
  },
  {
    position: 'inPanel',
    position_name: '계기판',
  },
  {
    position: 'inSeat',
    position_name: '시트',
  },
  {
    position: 'inDriverDoor',
    position_name: '운전석 도어',
  },
  {
    position: 'inNavigation',
    position_name: '네비게이션',
  },
  {
    position: 'inCenterFascia',
    position_name: '센터페시아',
  },
  {
    position: 'etc1',
    position_name: '기타1',
  },
  {
    position: 'etc2',
    position_name: '기타2',
  },
  {
    position: 'etc3',
    position_name: '기타3',
  },
  {
    position: 'etc4',
    position_name: '기타4',
  },
  {
    position: 'etc5',
    position_name: '기타5',
  }
];

const CarmonImageViewer = ({open, close, reservationNumber}) => {
  const [images, setImages] = useState([]);
  const [slider, setSlider] = useState(null);

  useEffect(() => {
    if (reservationNumber) getImageList();
  }, [reservationNumber]);

  const getImageList = async () => {
    try {
      const response = await axios.get(`/apis/admin/image/${reservationNumber}`);
      if (response.status === 200) {
        setImages(response.data);
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const renderImages = () => {
    const render = [];

    images.map(o => {
      render.push(
        <Image src={o.url} key={o.position} size='large' rounded/>
      );
    });

    return render;
  }

  const SliderSettings = {
    lazyLoad: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <>
      <Modal
        onClose={() => close()}
        open={open}
        size='fullscreen'
        centered={true}
        style={{background: 'transparent', marginLeft: '3em'}}>
        <Modal.Content style={{padding: '0', background: 'transparent'}}>
          <Segment compact>
            <Grid columns='equal' centered celled='internally'>
              <Grid.Row>
                {
                  images.map(o => {
                    return <Grid.Column key={`position_title_${o.position}`}><Header
                      as='h5'>{PARTS.find(p => p.position === o.position).position_name}</Header></Grid.Column>
                  })
                }
              </Grid.Row>
              <Grid.Row>
                {
                  images.map((o, index) => {
                    return <Grid.Column key={`position_image_${o.position}`}><Image src={o.url} key={o.position}
                                                                                    size='tiny'
                                                                                    onClick={() => slider.slickGoTo(index)}/></Grid.Column>
                  })
                }
              </Grid.Row>
            </Grid>

          </Segment>
          <Slider ref={slider => setSlider(slider)} {...SliderSettings}>
            {renderImages()}
          </Slider>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default CarmonImageViewer
