import React, {useEffect, useRef, useState} from 'react';
import {Form, Button, Icon, Modal} from "semantic-ui-react";
import axios from "axios";
import {toast} from 'react-semantic-toasts';
import {BANK_TYPE} from '../../consts/Consts';

const CarmonRegistryChannel = ({modalOpen = false, visibleControl, isUpdate = false, currentChannel, callback}) => {
  const [formData, setFormData] = useState({});
  const bankData = useRef([]);
  const [bankOptions, setBankOptions] = useState([]);

  const defaultFormData = {
    centerChannelId: '',
    channelName: '',
    contactNumber: '',
    businessNumber: '',
    bank: '',
    bankAccount: '',
    bankAccountName: '',
  }

  const onChangeField = (e, {name, value}) => {
    formData[name] = value;
  };

  useEffect(() => {
    getBankData();
  }, []);

  useEffect(() => {
    setBankOptions(bankData.current.sort((o1, o2) => {
      return (o1.order >= o2.order && o1.key > o2.key) ? 1 : -1;
    }));
  },[bankData.current])

  useEffect(() => {
    if (isUpdate && currentChannel) {
      setFormData(prevState => {
        return {
          ...prevState,
          ...currentChannel.data,
          channelName: currentChannel.data.name
        }
      });
    } else {
      setFormData(defaultFormData);
    }
  }, [currentChannel]);

  const getBankData = () => {
    Object.entries(BANK_TYPE).map(([value]) => {
      retrieveCommonCode(value, value === BANK_TYPE.BANK ? 1 : 2);
    });
  }

  const retrieveCommonCode = async (codeGroup, order) => {
    try {
      const response = await axios.get(`/apis/common/code_list/${codeGroup}`);
      if (response.status === 200) {
        let optionData = [];

        response.data.map(row => {
          optionData.push({key: row.code, text: row.name, value: row.code, order: order});
        })

        bankData.current = [...bankData.current, ...optionData];
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const onClickSave = () => {
    axios.post('/apis/admin/channel/save', formData).then(() => {
      callback && callback();
      visibleControl(false);
    });
  }

  return (
    <Modal
      onOpen={() => visibleControl(true)}
      onClose={() => visibleControl(false)}
      open={modalOpen}
      size='tiny'
      centered={false}>

      <Modal.Header>{isUpdate ? "회원사 정보 수정" : "신규 회원사 등록"}</Modal.Header>
      <Modal.Content>
        <Form key={formData.centerChannelId}>
          <Form.Field name='centerChannelId' value={formData.centerChannelId}></Form.Field>
          <Form.Input fluid
                      label='회원사명'
                      name='channelName'
                      placeholder='회원사명'
                      onChange={onChangeField}
                      defaultValue={formData.channelName || ''}/>

          <Form.Input fluid
                      label='연락처'
                      placeholder='연락처'
                      name='contactNumber'
                      onChange={onChangeField}
                      defaultValue={formData.contactNumber || ''}/>

          <Form.Input fluid
                      label='사업자번호'
                      placeholder='사업자번호'
                      name='businessNumber'
                      onChange={onChangeField}
                      defaultValue={formData.businessNumber || ''}/>

          {/*<Form.Input fluid*/}
          {/*            label='은행명'*/}
          {/*            placeholder='은행명'*/}
          {/*            name='bankName'*/}
          {/*            onChange={onChangeField}*/}
          {/*            value={formData.bank}/>*/}
          <Form.Select fluid
                       search
                       options={bankOptions}
                       name='bankName'
                       label='은행명'
                       placeholder='은행명'
                       onChange={onChangeField}
                       defaultValue={formData.bank || ''} />

          <Form.Input fluid
                      label='계좌번호'
                      placeholder='계좌번호'
                      name='bankAccount'
                      onChange={onChangeField}
                      defaultValue={formData.bankAccount || ''} />

          <Form.Input fluid
                      label='예금주'
                      placeholder='예금주'
                      name='bankAccountName'
                      onChange={onChangeField}
                      defaultValue={formData.bankAccountName || ''}/>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button color='teal' onClick={onClickSave}>
          <Icon name='save'/> 저장
        </Button>
        <Button color='red' onClick={() => visibleControl(false)}>
          <Icon name='remove'/> 닫기
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CarmonRegistryChannel;