import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Container, Icon, Modal, Table} from "semantic-ui-react";
import moment from 'moment';

const CarmonAdministrator = () => {
  const [administratorList, setAdministratorList] = useState([]);
  const [open, setOpen] = useState(false);

  const retrieveAdminUSer = async () => {
    const response = await axios.get('/apis/admin/list');
    setAdministratorList(response.data);
  }

  useEffect(() => {
    retrieveAdminUSer();
  }, []);

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>로그인 아이디</Table.HeaderCell>
            <Table.HeaderCell>관리자명</Table.HeaderCell>
            <Table.HeaderCell>최초등록일자</Table.HeaderCell>
            <Table.HeaderCell>최종수정일자</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {administratorList.map((o, idx) => {
            return (
              <Table.Row key={`adm${idx}`}>
                <Table.Cell>{o.administratorId}</Table.Cell>
                <Table.Cell>{o.loginId}</Table.Cell>
                <Table.Cell>{o.name}</Table.Cell>
                <Table.Cell>{moment(o.regTime)}</Table.Cell>
                <Table.Cell>{moment(o.modTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
              <Button color='teal' onClick={() => setOpen(true)}>
                <Icon name='plus'/>
                신규등록
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='large'>
        <Container textAlign='center'>

        </Container>
        <Modal.Actions style={{marginTop: `2em`, textAlign: `center`}}>
          <Button basic size='large' color='red' onClick={() => setOpen(false)}>
            <Icon name='remove'/> 닫기
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default CarmonAdministrator;