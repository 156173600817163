import {CARNAWA_BASE_URL} from '../../Consts';

export const REPAIR_HISTORY_MOBILE_REPORT_TEMPLATE = `
<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html lang="ko">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  
  <meta name="author" content="카몬 CARMON">
  <link rel="canonical" href="https://ext.carmon.in">
  <meta name="robots" content="index, follow">
  <meta name="title" content="카몬 CARMON &mdash; 카몬스터 | Car Monster">
  <meta name="description" content="중고차 시장에 신뢰를 더하고자 합니다. 판매자도, 구매자도 믿을 수 있는 중고차 거래 플랫폼 카몬입니다.">
  <meta name="keywords" content="차,자동차,중고차,판매,팔기,구매,사기,카몬,딜러,내차팔기,헤이딜러,첫차">
  <meta name="language" content="Korean">
  <meta name="contact" content="carmonster000@gmail.com">
  <meta property="og:type" content="website">
  <meta property="og:title" content="카몬 CARMON &mdash; 카몬스터 | Car Monster">
  <meta property="og:description" content="중고차 시장에 신뢰를 더하고자 합니다. 판매자도, 구매자도 믿을 수 있는 중고차 거래 플랫폼 카몬입니다.">
  <meta property="og:site_name" content="카몬 CARMON">
  <meta name="twitter:card" content="summary">
  <meta name="twitter:title" content="카몬 CARMON &mdash; 카몬스터 | Car Monster">
  <meta name="twitter:description" content="중고차 시장에 신뢰를 더하고자 합니다. 판매자도, 구매자도 믿을 수 있는 중고차 거래 플랫폼 카몬입니다.">

  <title>카나와 사고정비 리포트</title>

      
</head>

<body style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; background-color: #F2F2F2; margin: 0 auto; padding: 0; width: 100%;"> 
  <table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#F2F2F2" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
    <tr>
      <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
          
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#02A7F0" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #ffffff; border-collapse: collapse;">
          <tr >
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; padding: 0px;">

              <table width="640" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border-bottom: 1px solid #ffffff;">
                    <div style="position:relative;">
                      <img src="${CARNAWA_BASE_URL}/images/repair-history/repair-history-title.jpeg" />
                      <div style="position:absolute;top:115px;left:120px; width:450px; height:50px;color:black;background-image: url(${CARNAWA_BASE_URL}/images/repair-history/repair-history-search.jpeg);background-repeat: no-repeat;background-position: center; padding:20px;">
                        <span style="line-height:50px;margin-left:-50px;"><strong>[[##_차량번호_##]] 차량 사고 이력 상세 정비 내용 리포트</strong></span>
                      </div>                      
                    </div>              
                  </td>
                </tr>                
              </table>
            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="right" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 14px;">
                    - 요청일시 : [##_요청일시_##]
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h3>1. 조회 차량정보</h3>
                  </td>
                </tr>              
              </table>

            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-size: 14px; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
              
              <table id="tbl-car-information" width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td colspan="2"><h3>[##_모델_##]</h3></td>
                </tr>
                
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>연식</b>
                  </td>                  
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_연식_##]
                  </td>
                </tr>
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>최초등록일</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_최초등록일_##]
                  </td>
                </tr>
                
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>차종</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_차종_##]
                  </td>
                </tr>
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>용도</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_용도_##]
                  </td>
                </tr>
                
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>연료</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_연료_##]
                  </td>
                </tr>
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>배기량</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_배기량_##]
                  </td>
                </tr>
                
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>색상</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_색상_##]
                  </td>
                </tr>
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>정원</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_정원_##]
                  </td>
                </tr>
                
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>변속기</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_변속기_##]
                  </td>
                </tr>
                <tr>
                  <td width="160" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; background-color: #02A7F0; padding: 6px; font-size: 1.2em; color: #ffffff;" bgcolor="#02A7F0">
                    <b>연비</b>
                  </td>
                  <td width="300" align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;" bgcolor="#FAFAFA">
                    [##_연비_##]
                  </td>
                  
                </tr>                
              </table>

            </td>
          </tr>
        </table>
        
        [##_상세옵션_##]
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h3>2. 사고 정비 부위</h3>
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>

        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-size: 14px; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table id="tbl-parts-images" width="640" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <div style="position:relative; display:flex; flex-flow:row nowrap; align-items:stretch; justify-content:space-around;">
                  <div style="flex-shrink:0;flex-grow:0;min-width:240px;width:240px;min-height:400px;height:400px; background-position:center center; background-repeat:no-repeat; background-size:contain;background-image: [##_차량부위좌측_##];"></div>
                  <div style="flex-shrink:0;flex-grow:0;min-width:240px;width:240px;min-height:400px;height:400px; background-position:center center; background-repeat:no-repeat; background-size:contain;background-image: [##_차량부위우측_##];"></div>
                  
                </div>
                <div style="position:relative; display:flex; flex-flow:row nowrap; align-items:stretch; justify-content:space-around;">
                  <div style="flex-shrink:0;flex-grow:0;min-width:240px;width:240px;min-height:400px;height:400px; background-position:center center; background-repeat:no-repeat; background-size:contain;background-image: [##_차량부위상단_##];"></div>
                  <div style="flex-shrink:0;flex-grow:0;min-width:240px;width:240px;min-height:400px;height:400px; background-position:center center; background-repeat:no-repeat; background-size:contain;background-image: [##_차량부위하단_##];"></div>                  
                </div>
              </table>

            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h3>3. 사고 정비 이력</h3>
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
              [##_유사고차량_##]
              
              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="text-align: center; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <thead>
                  <tr style="height:30px;">
                    <th style="padding: 6px;border: 1px solid #2224261a;background-color: #02A7F0; font-size: 1.2em; color: #ffffff;">정비일자</th>
                    <th style="padding: 6px;border: 1px solid #2224261a;background-color: #02A7F0; font-size: 1.2em; color: #ffffff;">정비완료일자</th>
                    <th style="padding: 6px;border: 1px solid #2224261a;background-color: #02A7F0; font-size: 1.2em; color: #ffffff;">정비구분</th>
                    <th style="padding: 6px;border: 1px solid #2224261a;background-color: #02A7F0; font-size: 1.2em; color: #ffffff;">정비부위</th>                    
                  </tr>
                </thead>
                [##_사고정비이력_##]
              </table>

            </td>
          </tr>
          <tr>
            <td height="10" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 10px; line-height: 10px;">&nbsp;</td>
          </tr>            
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h3>
                    4. 비고사항
                    </h3>
                  </td>
                </tr>
              </table>
              
            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="text-align: left; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td>
                    [##_비고사항_##]
                  </td>
                </tr>
              </table>

            </td>
          </tr>
          <tr>
            <td height="10" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 10px; line-height: 10px;">&nbsp;</td>
          </tr>            
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="left" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h3>
                    5. 배상 또는 수리비 보상 예상금액                    
                    </h3>
                  </td>
                </tr>
              </table>
              
            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="text-align: center; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td align="right" width="35%" style="font-size:20px">보험사</td>
                  <td align="left" width="65%" style="padding-left:30px">[##_예상보상가액_##]</td>
                </tr>
                <tr>
                  <td align="right" width="35%" style="font-size:20px">딜러</td>
                  <td align="left" width="65%" style="padding-left:30px">
                    <div style="font-size: 2.5em;font-weight: 800; color: #1b1c1d;">200 <span style="font-size:20px">만원</span></div>
                  </td>
                </tr>
              </table>

            </td>
          </tr>
          <tr>
            <td height="10" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 10px; line-height: 10px;">&nbsp;</td>
          </tr>            
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border: 0px solid #d4d4d5; border-collapse: collapse;">
                <tr>                  
                  <td width="540" align="center" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 14px; padding: 12px; word-break: keep-all;">
                    <div style="font-size:0.8rem;color:#666">보험사 보상금액은 차량 구매가 대비 5% ~ 7% 내에서 보상 가능합니다.</div>                    
                  </td>
                </tr>
              </table>
              
              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border: 0px solid #d4d4d5; border-collapse: collapse;">
                <tr>                  
                  <td width="540" align="center" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 14px; padding: 12px; word-break: keep-all;">
                    <h4 style="display: block; margin-top: 0; margin-bottom: 8px; color:#db2828!important;">
                      성능점검기록부에 고지되지 않은 정비이력이 있는 경우 보상받으실 수 있습니다. <br/>
                      보상을 원하시는 경우 카몬 고객센터로 연락주시면 절차를 안내드리겠습니다.                      
                    </h4>                    
                  </td>
                </tr>
              </table>

            </td>
          </tr>
          <tr>
            <td height="10" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; font-size: 10px; line-height: 10px;">&nbsp;</td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#1b1c1d" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #ffffff; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; padding: 12px 0;">
        
              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td width="250" class="mobile" align="left" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/logo/carmon.png" width="140" height="auto" style="-ms-interpolation-mode: bicubic; outline: none; text-decoration: none; margin: 0; padding: 0; border: none; display: block;" border="0" alt="">
                  </td>
                  <td width="350" class="mobile" align="left" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h4 style="margin: 10px 0">카몬 | 주식회사 카몬스터</h4>
                    <span style="font-size: 14px; display: block">[제휴문의] <a href="mailto:carmonster000@gmail.com" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; color: #ffffff; text-decoration: underline;">carmonster000@gmail.com</a></span>
                    <span style="font-size: 14px; display: block">[고객센터] <a href="tel:070-7576-4981" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; color: #ffffff; text-decoration: underline;">070-7576-4981</a></span>
                  </td>
                </tr>
              </table>
        
            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#1b1c1d" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #ffffff; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
        
              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td width="250" class="mobile" align="left" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <h4 style="margin: 0">카몬 서비스</h4>
                  </td>
                  <td width="100" class="mobile" align="left" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <a href="https://carmon.in" target="_blank" title="carmon.in" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-size: 14px; color: #ffffff; text-decoration: underline;">
                      내차팔기
                      <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/open-link.png" width="16" height="auto" style="-ms-interpolation-mode: bicubic; outline: none; text-decoration: none; margin: 0; padding: 0; border: none; display: inline-block; vertical-align: middle;" border="0" alt="">
                    </a>
                  </td>
                  
                  <td width="100" class="mobile" align="left" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <a href="https://carnawa.co.kr/carnawa" target="_blank" title="carnawa.co.kr" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-size: 14px; color: #ffffff; text-decoration: underline;">
                      가격비교
                      <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/open-link.png" width="16" height="auto" style="-ms-interpolation-mode: bicubic; outline: none; text-decoration: none; margin: 0; padding: 0; border: none; display: inline-block; vertical-align: middle;" border="0" alt="">
                    </a>
                  </td>
                  
                  <td width="150" class="mobile" align="left" valign="middle" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">
                    <a href="https://carnawa.co.kr/repair" target="_blank" title="carnawa.co.kr" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-size: 14px; color: #ffffff; text-decoration: underline;">
                      숨은 보상금 찾기
                      <img src="https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/open-link.png" width="16" height="auto" style="-ms-interpolation-mode: bicubic; outline: none; text-decoration: none; margin: 0; padding: 0; border: none; display: inline-block; vertical-align: middle;" border="0" alt="">
                    </a>
                  </td>
                </tr>
              </table>
              
            </td>
          </tr>
        </table>
        
        <table width="640" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#1b1c1d" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #ffffff; border-collapse: collapse;">
          <tr>
            <td align="center" valign="top" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px;">

              <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;">
                <tr>
                  <td width="600" style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; max-width: 440px; word-break: keep-all;" align="center" valign="top">
                    <p>(주)카몬스터는 판매자와 구매자가 서로 믿고 거래할 수 있는 데이터 기반 중고차 거래 문화를 만들어 갑니다.</p>
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>
        
      </td>
    </tr>
  </table>
</body>
</html>
`;