import React, {useEffect, useReducer, useRef, useState} from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Icon, Image,
  Input,
  Menu, Modal, Pagination, Popup,
  Segment,
  Select, Statistic,
  Table,
} from 'semantic-ui-react';
import _ from "lodash";
import axios from "axios";
import {toast} from "react-semantic-toasts";
import moment from 'moment';
import {AuctionState, TransportState, UseYn, YesOrNo} from '../../consts/Consts';
import CarmonAssignTransportManager from "../popup/AssignTransportManager";
import CarmonImageViewer from "../popup/ImageViewer";
import CarmonSellerDeposit from '../popup/SellerDeposit';

const COUNT_PER_PAGE = 10;

const CarmonAuction = () => {
  const [sellerDepositProps, setSellerDepositProps] = useState({
    open: false,
    close: () => {
      setSellerDepositProps(prevState => {
        return {
          ...prevState,
          open: false,
          paymentId: null,
        }
      });
    },
    paymentId: null,
    callback: async () => {
      await retrieveAuction();
    }
  });

  const [pageable, setPageable] = useState({page: 0, size: COUNT_PER_PAGE});
  const [now, setNow] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [depositButtons, setDepositButtons] = useState({});
  const [searchForm, setSearchForm] = useState({});
  const [assignTransportManagerModalOption, setAssignTransportManagerModalOption] = useState({
    open: false,
    close: () => {
      setAssignTransportManagerModalOption(prevState => {
        return {
          ...prevState,
          open: false,
        };
      })
    },
    callback: async () => {
      await retrieveAuction();
    }
  });

  const [imageModalProps, setImageModalProps] = useState({
    open: false,
    close: imageModalClose,
    reservation: null,
    callback: null
  });

  const [contractImagePopup, setContractImagePopup] = useState({
    open: false,
    close: () => setContractImagePopup((prevState => {
      return {...prevState, open: false}
    })),
    url: null
  });

  const [auctionStateOptions] = useState([
    {key: '', value: '', text: "전체"},
    {key: 'WA', value: 'WA', text: "[WA] 대기"},
    {key: 'AA', value: 'AA', text: "[AA] 진행"},
    {key: 'SB', value: 'SB', text: "[SB] 낙찰"},
    {key: 'FB', value: 'FB', text: "[FB] 유찰"},
    {key: 'SY', value: 'SY', text: "[SY] 판매승인"},
    {key: 'SN', value: 'SN', text: "[SN] 판매거부"},
    {key: 'RA', value: 'RA', text: "[RA] 경매 재출품"},
  ]);

  useEffect(() => {
    const timeInterval = setInterval(() => setNow(moment()), 1000);
    return () => clearInterval(timeInterval);
  }, [now]);

  useEffect(() => {
    const callRetrieveAuction = async () => {
      await retrieveAuction();
    }

    callRetrieveAuction();
  }, [pageable.page, pageable.size]);


  const imageModalClose = () => {
    setImageModalProps(prevState => {
      return {
        ...prevState,
        open: false,
      }
    });
  }

  const retrieveAuction = async () => {
    try {
      setLoading(true);
      const params = Object.assign({}, searchForm, pageable);
      const response = await axios.get('/apis/admin/auction/list', {params: params});
      if (response.status === 200) {
        dispatch({type: 'CHANGE_DATA', data: response.data});
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    } finally {
      setLoading(false);
    }
  }

  const dataSortableReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        }
      case 'CHANGE_DATA':
        return {
          column: null,
          data: action.data,
          direction: null
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(dataSortableReducer, {
    column: null,
    data: [],
    direction: null,
  })

  const {column, data, direction} = state;

  const eventHandler = {
    onChangeSearchForm: (event, {name, value}) => {
      setSearchForm(prevState => {
        return {
          ...prevState,
          [name]: value,
        }
      });
    },
    onClickAssignTransportManager: (auction) => {
      setAssignTransportManagerModalOption(prevState => {
        return {
          ...prevState,
          open: true,
          auction: auction,
          callback: async () => {
            await retrieveAuction();
          }
        };
      })
    },
    onClickDeposit: async (auction) => {
      depositConfirm(auction);
    },
    onClickImageViewer: (reservationNumber) => {
      setImageModalProps(prevState => {
        return {
          ...prevState,
          open: true,
          close: imageModalClose,
          reservationNumber: reservationNumber
        }
      })
    },
    onClickContractImage: (url) => {
      setContractImagePopup(prevState => {
        return {
          ...prevState,
          open: true,
          url: url
        };
      });
    },
    onClickSellerDeposit: (auction) => {
      setSellerDepositProps(prevState => {
        return {
          ...prevState,
          open: true,
          paymentId: auction.paymentId,
          callback: async () => {
            await retrieveAuction();
          }
        }
      });
    }
  }

  const depositButtonEnable = (auctionId, enable) => {
    setDepositButtons(prevState => {
      return {
        ...prevState,
        [auctionId]: !enable
      }
    });
  };

  const depositConfirm = async (auction) => {
    if (depositButtons[auction.auctionId]) return false;
    depositButtonEnable(auction.auctionId, false);

    try {
      let response = await axios(`/apis/admin/payment/auction/${auction.auctionId}`, {method: auction.dealerPaidYn === UseYn.YES ? 'delete' : 'put'});

      if (response && response.status === 200) {
        depositButtonEnable(auction.auctionId, true);
        await retrieveAuction();
      }
    } catch (error) {
      depositButtonEnable(auction.auctionId, true);
      console.log(error);
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const getTransportStateLabel = (transportState) => {
    let label = '';

    switch (transportState) {
      case TransportState.ASSIGN_MANAGER:
        label = '배정완료'
        break;
      case TransportState.START_TRANSPORT:
        label = '탁송시작'
        break;
      case TransportState.COMPLETE_TRANSPORT:
        label = '탁송완료'
        break;
      case TransportState.FAILURE_TRANSPORT:
        label = '탁송실패'
        break;
      default:
        label = '기사배정'
    }

    return label;
  }

  const getProcessTime = (transport) => {
    let time;

    switch(transport.transportState) {
      case TransportState.WAIT:
        time = transport.sellerContactTime;
        break;
      case TransportState.ASSIGN_MANAGER:
        time = transport.sellerContactTime;
        break;
      case TransportState.START_TRANSPORT:
        time = transport.contractRegTime;
        break;
      case TransportState.COMPLETE_TRANSPORT:
        time = transport.transportCompleteTime;
        break;
      default:
        time = null;
    }

    return time === null ? null : moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  const getNowProcessing = (row) => {
    let stateText = "";

    if (row.transportState === TransportState.COMPLETE_TRANSPORT) {
      stateText = "거래종료";
    } else if (row.transportState === TransportState.START_TRANSPORT) {
      if (row.sellerDepositYn === "Y")
        stateText = "[판매자]대금지급완료";
      else
        stateText = "[탁송]탁송시작";
    } else if (row.transportState === TransportState.ASSIGN_MANAGER) {
      stateText = "[탁송]기사배정";
    } else if (row.dealerPaidYn === "Y") {
      stateText = "[딜러]입금완료";
    } else if (row.buyerInformationId > 0) {
      if (row.buyerInformationCheckTime === null) {
        stateText = "[딜러]매수자정보등록";
      } else {
        stateText = "[판매자]입금정보등록";
      }
    }

    return stateText;
  }

  const renderAuctionList = () => {
    //const render = [];

    const render = data.content?.map((o) => {
      return (
        <Table.Row key={o.auctionId}>
          <Table.Cell>{o.auctionId}</Table.Cell>
          <Table.Cell>{o.reservationNumber}</Table.Cell>
          <Table.Cell>
            <Header as='h5' style={{margin: '0'}} color='grey'>
              {o.licensePlateNumber}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              {o.modelName} ({o.year})
            </Header>
            <Header as='h5' style={{margin: '0'}} color='grey'>
              {o.makerName}
            </Header>
          </Table.Cell>
          <Table.Cell>
            <Header as='h5' style={{margin: '0'}}>{o.name}</Header>
            <Header as='h5' style={{margin: '0'}} color='grey'>{o.sellerContactNumber}</Header>
          </Table.Cell>
          <Table.Cell>
            <Header as='h5' style={{margin: '0'}}>{auctionStateOptions.filter(v => v.value === o.auctionState)[0].text}</Header>
            {
              o.auctionState === AuctionState.SALES_YES ?
                <Header as='h5' style={{margin: '0'}} color='blue'>{getNowProcessing(o)}</Header>
              :
              o.auctionState === AuctionState.SALES_NO ?
                <Popup
                  trigger={<Header as='h5' style={{margin: '0'}} color='red'>[거부사유]</Header>}
                  content={o.note}
                  position='bottom left'
                />
              :
                ''
            }
          </Table.Cell>
          <Table.Cell>{moment(o.finishDate).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
          <Table.Cell>{getTimeLimit(o)}</Table.Cell>
          <Table.Cell textAlign='right'>{o.bidCount}</Table.Cell>
          <Table.Cell textAlign='right'>
            {
              o.maxBidAmount ?
                <Statistic size='mini'>
                  <Statistic.Value
                    style={{textAlign: 'right', color: '#d01919'}}>{o.maxBidAmount?.toLocaleString()}</Statistic.Value>
                  <Statistic.Label style={{textAlign: 'right', color: '#2185d0'}}>
                    ({o.minBidAmount?.toLocaleString()})
                  </Statistic.Label>
                </Statistic> : ''
            }
          </Table.Cell>
          <Table.Cell>
            {
              o.dealerId ?
                <>
                  <Header as='h5' style={{margin: '0'}}>{o.dealerName} ({o.companyName})</Header>
                  <Header as='h5' style={{margin: '0'}}>{o.contactNumber}</Header>
                  <Header as='h5' style={{margin: '0'}}>{moment(o.bidTime).format('YYYY-MM-DD HH:mm:ss')}</Header>
                </> : ''

            }
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Icon name={o.auctionState === AuctionState.SALES_NO ? 'times' : 'check'}
                  style={{
                    color: o.auctionState === AuctionState.SALES_YES ? '#21ba45' :
                      o.auctionState === AuctionState.SALES_NO ? '#db2828' : '#dddddd'
                  }}/>
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Button loading={depositButtons[o.auctionId]}
                    disabled={depositButtons[o.auctionId]
                    || o.transportManagerId > 0
                    || o.auctionState !== AuctionState.SALES_YES}
                    icon='won'
                    size='mini'
                    color={o.dealerPaidYn === UseYn.YES ? 'red' : 'blue'}
                    content={o.dealerPaidYn === UseYn.YES ? '확인취소' : '입금확인'}
                    onClick={() => eventHandler.onClickDeposit(o)}/>
          </Table.Cell>
          <Table.Cell textAlign={'center'}>
            <Popup trigger={<Button fluid
                                    color={
                                      o.transportState === TransportState.COMPLETE_TRANSPORT ?
                                      'green' : 'blue'
                                    }
                                    size='mini'
                                    icon={
                                      o.transportState === TransportState.COMPLETE_TRANSPORT ?
                                        'check' : 'truck'
                                    }
                                    basic={!!o.transportManagerId && o.transportManagerId > 0}
                                    content={
                                      <span>
                                        {getTransportStateLabel(o.transportState)} <br/>
                                        {getProcessTime(o)}
                                      </span>
                                    }
                                    disabled={o.auctionState !== AuctionState.SALES_YES || o.dealerPaidYn !== UseYn.YES}
                                    onClick={() => eventHandler.onClickAssignTransportManager(o)}/>}
                   inverted
                   disabled={!o.transportManagerId}
                   content={o.transportManagerName + " (" + o.transportManagerContactNumber + ")"}
            />
          </Table.Cell>

          <Table.Cell>
            <Button fluid
                    color='orange'
                    size='mini'
                    icon={o.sellerDepositYn === YesOrNo.YES ? 'check' : 'calculator'}
                    basic={o.sellerDepositYn === YesOrNo.YES}
                    content={
                      o.sellerDepositYn === YesOrNo.YES ?
                      _.toNumber(o.sellerDepositAmount).toLocaleString() :
                      '지급대기'
                    }
                    disabled={ !o.transportState || o.transportState === TransportState.WAIT}
                    onClick={() => {eventHandler.onClickSellerDeposit(o)}} />
          </Table.Cell>

          <Table.Cell textAlign='center'>
            <Icon name={o.latestImageGroupId ? 'images outline' : 'times'}
                  color={o.latestImageGroupId ? 'blue' : 'red'}
                  onClick={() => o.latestImageGroupId ? eventHandler.onClickImageViewer(o.reservationNumber) : ''}/>
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Icon name={o.latestPcrId ? 'file alternate outline' : 'times'}
                  color={o.latestPcrId ? 'green' : 'red'}/>
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Icon name={o.idCardFileName ? 'images outline' : 'times'}
                  color={o.idCardFileName ? 'blue' : 'red'}
                  onClick={() => o.idCardFileName ? eventHandler.onClickContractImage(o.idCardFileName) : ''}/>
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Icon name={o.signatureFileName ? 'images outline' : 'times'}
                  color={o.signatureFileName ? 'blue' : 'red'}
                  onClick={() => o.signatureFileName ? eventHandler.onClickContractImage(o.signatureFileName) : ''}/>
          </Table.Cell>
        </Table.Row>
      );
    });

    return render;
  }

  const getTimeLimit = (currentAuction) => {
    const finishDate = moment(currentAuction.finishDate);
    if (finishDate.unix() <= now.unix()) return (<Header as='h5' color='red'>경매종료</Header>);

    const duration = moment.duration(finishDate.diff(now));
    const h = duration.hours();
    const m = duration.minutes();
    const s = duration.seconds();

    return (<Header as='h5'>{h}시간 {m}분 {s}초</Header>)
  }

  return (
    <>
      <Container fluid>
        <Segment attached='top'>
          <Form>
            <Form.Group widths='3'>
              <Form.Field>
                <label>경매등록일자</label>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Input size='small'
                           type='date'
                           name='auctionStartDate'
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                  ~
                  <Form.Field>
                    <Input size='small'
                           type='date'
                           name='auctionFinishDate'
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                </Form.Group>
              </Form.Field>
              <Form.Field
                control={Input}
                name='reservationNumber'
                label='예약번호'
                placeholder='예약번호'
                onChange={eventHandler.onChangeSearchForm}
              />
              <Form.Field
                control={Input}
                name='licensePlateNumber'
                label='차량번호'
                placeholder='차량번호'
                onChange={eventHandler.onChangeSearchForm}
              />

              <Form.Field
                name='auctionState'
                control={Select}
                options={auctionStateOptions}
                label='진행상태'
                placeholder='진행상태'
                onChange={eventHandler.onChangeSearchForm}
              />
            </Form.Group>
          </Form>
        </Segment>
        <Segment attached='bottom' style={{background: `#f9fafb`}}>
          <Container fluid textAlign='right'>
            <Button.Group>
              <Button color='teal' onClick={retrieveAuction}><Icon name='search'/>Search</Button>
            </Button.Group>
          </Container>
        </Segment>
      </Container>

      <Segment basic style={{overflowX: 'auto', padding: 0}} loading={loading}>
        <Table sortable celled selectable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'auctionId' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'auctionId'})}
              >
                ID
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'reservationNumber' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'reservationNumber'})}
              >
                예약번호
              </Table.HeaderCell>
              <Table.HeaderCell>
                차량정보
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'licensePlateNumber' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'licensePlateNumber'})}
              >
                판매자정보
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'auctionState' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'auctionState'})}
              >
                경매상태<br />(현재상태)
              </Table.HeaderCell>

              <Table.HeaderCell>
                경매종료일시
              </Table.HeaderCell>
              <Table.HeaderCell>
                남은시간
              </Table.HeaderCell>

              <Table.HeaderCell>
                입찰<br />건수
              </Table.HeaderCell>
              <Table.HeaderCell>
                최고(최저)입찰가
              </Table.HeaderCell>
              <Table.HeaderCell>
                낙찰(예정)딜러
              </Table.HeaderCell>
              <Table.HeaderCell>
                판매<br />수락
              </Table.HeaderCell>
              <Table.HeaderCell>
                딜러 입금여부
              </Table.HeaderCell>
              <Table.HeaderCell>
                탁송정보<br />(희망/상태 일시)
              </Table.HeaderCell>
              <Table.HeaderCell>
                차량대금<br />지급여부
              </Table.HeaderCell>

              <Table.HeaderCell>
                사진
              </Table.HeaderCell>
              <Table.HeaderCell>
                점검
              </Table.HeaderCell>
              <Table.HeaderCell>
                신분증
              </Table.HeaderCell>
              <Table.HeaderCell>
                서명
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {renderAuctionList()}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='18'>
                <Header floated='left'>Total {data?.totalElements}건</Header>
                <Menu floated='right' pagination>
                  <Pagination defaultActivePage={1}
                              activePage={pageable.page + 1}
                              totalPages={data?.totalPages}
                              siblingRange={4}
                              boundaryRange={0}
                              ellipsisItem={null}
                              onPageChange={(event, {activePage}) => {
                                setPageable(prevState => {
                                  return {
                                    ...prevState,
                                    page: activePage - 1
                                  }
                                })
                              }
                              }/>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <CarmonAssignTransportManager {...assignTransportManagerModalOption} />
        <CarmonImageViewer {...imageModalProps} />
        <CarmonSellerDeposit {...sellerDepositProps} />
        <Modal
          onClose={() => contractImagePopup.close()}
          open={contractImagePopup.open}
          size='small'
          centered>
          <Modal.Content style={{padding: 0}}>
            <Segment compact style={{padding: 0}}>
              <Image src={contractImagePopup.url}/>
            </Segment>
          </Modal.Content>
        </Modal>

      </Segment>
    </>
  );
}

export default CarmonAuction;