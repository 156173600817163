import React, {useEffect, useState} from 'react'
import {Form, Button, Icon, Modal, Input, Transition, Checkbox} from 'semantic-ui-react';
import axios from "axios";
import _ from 'lodash';
import DaumPostCodeModal from "./DaumPostCode";
import CenterContact from './CenterContact';

const CarmonRegistryCenter = ({
                                modalOpen = false,
                                visibleControl,
                                isUpdate = false,
                                currentCenter = {},
                                callback,
                                centerChannel,
                                association,
                                locationState,
                                locationRegionAll,
                                bankOptions,
                              }) => {
  const [formData, setFormData] = useState({})
  const [locationRegion, setLocationRegion] = useState([]);
  const [isValidForm, setIsValidForm] = useState(true);
  const [centerContactModalOption, setCenterContactModalOption] = useState({
    open: false,
    close: () => {setCenterContactModalOption(prevState => ({...prevState, open: false}))},
    center: currentCenter
  });

  const [daumPostModalOptions, setDaumPostModalOptions] = useState({
    address: '',
    addressDetail: '',
    open: false,
    callback: (address) => {
      document.getElementsByName('address')[0].value = address;
      setFormData(prevState => {
        return {
          ...prevState,
          address: address
        }
      });
    }
  });

  useEffect(() => {
    if (isUpdate && currentCenter) {
      let filteredRegion = [];
      locationRegionAll.map((o) => {
        if (o.masterCode === currentCenter.data.locationState) {
          filteredRegion.push({key: o.commonCodeId, value: o.code, text: o.name})
        }
      });

      setLocationRegion(filteredRegion);

      setFormData(prevState => {
        return {
          ...prevState,
          ...currentCenter.data,
          centerName: currentCenter.data.name
        }
      });

      setCenterContactModalOption(prevState => {
        return {
          ...prevState,
          center: currentCenter.data,
        }
      })
    }
  }, [currentCenter]);

  const onChangeField = (e, {name, value}) => {
    console.log(e.target);
    formData[name] = value;

    if (name === 'locationState') {
      let filteredRegion = [];
      locationRegionAll.map((o) => {
        if (o.masterCode === value) {
          filteredRegion.push({key: o.commonCodeId, value: o.code, text: o.name})
        }
      });

      setLocationRegion(filteredRegion);
    }
  };

  const onChangeCheckField = (e, {name, checked}) => {
    formData[name] = checked ? 'Y' : 'N';
  };

  const onClickSave = () => {
    const validation = checkValidation();
    if (!validation) {
      setIsValidForm(validation);
      setTimeout(() => setIsValidForm(true), 2000);
      return;
    }

    axios.post('/apis/admin/center/save', formData).then(() => {
      callback && callback();
      visibleControl(false);
    });
  }

  const checkValidation = () => {
    if (formData && formData.centerChannelId && _.toNumber(formData.centerChannelId) === 0) return false;
    if (_.isEmpty(formData && formData.centerName && formData.centerName.trim())) return false;
    if (_.isEmpty(formData && formData.businessNumber && formData.businessNumber.trim())) return false;
    if (_.isEmpty(formData && formData.contactNumber && formData.contactNumber)) return false;
    if (_.isEmpty(formData && formData.locationState && formData.locationState.trim())) return false;
    if (_.isEmpty(formData && formData.locationRegion && formData.locationRegion.trim())) return false;
    if (_.isEmpty(formData && formData.address && formData.address.trim())) return false;

    return true;
  }

  return (
    <>
      <Modal
        onOpen={() => visibleControl(true)}
        onClose={() => visibleControl(false)}
        open={modalOpen}
        size='tiny'
        centered={false}>

        <Modal.Header>{isUpdate ? "정비소 정보 수정" : "신규 정비소 등록"}</Modal.Header>
        <Modal.Content>
          <Form key={formData.centerId} id='centerForm'>
            <Form.Field name='centerId' value={formData.centerId}/>
            <Form.Group widths='equal'>
              <Form.Select fluid
                           label={<label>회원사명<span style={{color:'#db2828'}}>*</span></label>}
                           name='centerChannelId'
                           onChange={onChangeField}
                           options={centerChannel}
                           defaultValue={formData.centerChannelId}
                           placeholder='회원사명'>
              </Form.Select>
              <Form.Input fluid
                          label={<label>정비소명<span style={{color:'#db2828'}}>*</span></label>}
                          name='centerName'
                          onChange={onChangeField}
                          defaultValue={formData.centerName}
                          placeholder='정비소명'/>
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Input fluid
                          label={<label>사업자번호<span style={{color:'#db2828'}}>*</span></label>}
                          placeholder='사업자번호'
                          name='businessNumber'
                          defaultValue={formData.businessNumber}
                          onChange={onChangeField}/>

              <Form.Input fluid
                          label={<label>연락처<span style={{color:'#db2828'}}>*</span></label>}
                          placeholder='연락처'
                          name='contactNumber'
                          defaultValue={formData.contactNumber}
                          onChange={onChangeField}
                          action={<Button disabled={!isUpdate}
                                          color={'blue'}
                                          size={'small'}
                                          icon={'plus'}
                                          content={'추가'}
                                          onClick={() => {
                                            console.log('clicked!');
                                            setCenterContactModalOption(prevState => {
                                              return {
                                                ...prevState,
                                                open: true,
                                              }
                                            })
                                          }}/>}
              />
            </Form.Group>

            <Form.Select fluid
                         label='성능점검협회'
                         placeholder='성능점검협회'
                         name='association'
                         options={association}
                         defaultValue={formData.association}
                         onChange={onChangeField}/>

            <Form.Group widths='equal'>
              <Form.Select fluid
                           label={<label>지역(광역시/도)<span style={{color:'#db2828'}}>*</span></label>}
                           name='locationState'
                           placeholder='광역시/도'
                           options={locationState}
                           defaultValue={formData.locationState}
                           onChange={onChangeField}/>

              <Form.Select fluid
                           label={<label>지역(시/구/군)<span style={{color:'#db2828'}}>*</span></label>}
                           placeholder='시/구/군'
                           name='locationRegion'
                           options={locationRegion}
                           defaultValue={formData.locationRegion}
                           onChange={onChangeField}/>
            </Form.Group>

            <Form.Field>
              <label>주소<span style={{color:'#db2828'}}>*</span></label>
              <Input placeholder='주소'
                     id='address'
                     name='address'
                     action={{
                       color: 'blue',
                       content: '검색',
                       basic: true,
                       onClick: () => {
                         setDaumPostModalOptions(prevState => {
                           return {
                             ...prevState,
                             open: true,
                           }
                         });
                       }
                     }}
                     defaultValue={formData.address}
                     readOnly
                     onChange={onChangeField}/>
              <Input placeholder='상세주소'
                     id='addressDetail'
                     name='addressDetail'
                     defaultValue={formData.addressDetail}
                     onChange={onChangeField}
                     style={{marginTop:'10px'}}/>

            </Form.Field>

            <Form.Field>

              <Checkbox label={'수입차량 정비 가능 여부'}
                        name={'canForeign'}
                        onChange={onChangeCheckField}
                        defaultChecked={formData.canForeign === 'Y'} />
            </Form.Field>

            <Form.Select fluid
                         label={'은행'}
                         name='bank'
                         placeholder='은행'
                         options={bankOptions}
                         defaultValue={formData.bank}
                         onChange={onChangeField}/>

            <Form.Group widths='equal'>
              <Form.Input fluid
                           label={'예금주'}
                           placeholder='예금주'
                           name='bankAccountName'
                           defaultValue={formData.bankAccountName}
                           onChange={onChangeField}/>

              <Form.Input fluid
                          label={'계좌번호'}
                          placeholder='계좌번호'
                          name='bankAccount'
                          defaultValue={formData.bankAccount}
                          onChange={onChangeField}/>
            </Form.Group>

            <Form.Input fluid
                        label='유튜브채널(URL)'
                        placeholder='유튜브채널(URL)'
                        name='youtubeChannel'
                        maxLength={50}
                        defaultValue={formData.youtubeChannel}
                        onChange={onChangeField}/>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Transition
            animation='pulse'
            duration={500}
            visible={!isValidForm}
          >
            <span style={{display: isValidForm ? 'none' : '', color:'#db2828', float:'left'}}><strong>*필수값을 모두 입력해주세요.</strong></span>
          </Transition>
          <Button color='teal' form='centerForm' type='submit' onClick={onClickSave}>
            <Icon name='save'/> 저장
          </Button>
          <Button color='red' onClick={() => visibleControl(false)}>
            <Icon name='remove'/> 닫기
          </Button>
        </Modal.Actions>
      </Modal>
      <DaumPostCodeModal modalOption={daumPostModalOptions}
                         setModalOption={setDaumPostModalOptions}/>

      <CenterContact {...centerContactModalOption} />
    </>
  )
}

export default CarmonRegistryCenter;
