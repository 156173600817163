import React, {useEffect, useState} from 'react';
import {Button, Dropdown, FormInput, Header, Modal, Table} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import _ from 'lodash';
import JsonContentModal from './JsonContentModal';
import CarmonConfirm from '../common/Confirm';

const AT_CENTER_MESSAGE = 'AT-MESSAGE-US-10';

const MessageSendHistoryPopup = ({open, close, keyword, centerId}) => {
  useEffect(async () => {
    setModalOption(prevState => ({...prevState, open: open}));

    if (open) {
      await retrieveMessageSendHistory(keyword);
      if (centerId) await retrieveCenterContactList(centerId);
    }
  }, [open]);

  const [modalOption, setModalOption] = useState({open: open});
  const [contactList, setContactList] = useState([{}]);

  const [data, setData] = useState([]);
  const [inputNumberModal, setInputNumberModal] = useState({
    open: false,
    close: () => setInputNumberModal(prevState => ({...prevState, open: false})),
    toNumber: '',
    content: '',
  });

  const [contentModal,setContentModal] = useState({
    open: false,
    close: () => {setContentModal(prevState => ({...prevState, open: false}))},
    content: ''});

  const [message, setMessage] = useState({toNumber: '', toNumberDisplay: '', msgContent: '',});

  const [resendConfirm, setResendConfrim] = useState({
    title: '재전송',
    content: '',
    open: false,
    close: () => {setResendConfrim(prevState => ({...prevState, open: false}))},
    callback: null
  });

  const closeModal = () => {
    setModalOption(prevState => ({...prevState, open: false}));
    close && close();
  }

  const retrieveMessageSendHistory = async (keyword) => {
    const response = await axios.get(`/apis/admin/message/history/search/keyword/${keyword}`);
    setData(response.data);
  }

  const retrieveCenterContactList = async (currentCenterId) => {
    try {
      const response = await axios.get(`/apis/admin/center/contact/${currentCenterId}`);

      const filteredContactList = _.map(response.data, o => {
        if (o.contactType === "CEL") return {key: o.centerContactId, value: o.contactNumber, text: `[${o.contactName}] ${o.contactNumber}`}
      });

      if (filteredContactList.length > 0) setContactList(filteredContactList);
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 10000,
        description: JSON.stringify(error.response.data)
      });
    }
  }

  const sendMessage = () => {
    setResendConfrim(prevState => {
      return {
        ...prevState,
        open: true,
        content: <span>[{message.toNumber}] 번호로 메세지를 재전송하시겠습니까? <br />메세지는 비동기로 전송됩니다. 전송 결과는 잠시 후 다시 조회해주세요.</span>,
        callback: () => {
          axios.post('/apis/admin/message/send', message).then(() => {
            toast({
              type: "success",
              title: "SUCCESS",
              time: 5000,
              description: "알림톡 재전송 요청 성공"
            });
            inputNumberModal.close();
          }).catch((error) => {
            toast({
              type: "error",
              title: "ERROR",
              time: 5000,
              description: error.response && error.response.data && error.response.data.message || error
            });
          });
        }};
    });
  }

  const makeContactOptions = (toNumber) => {
    if (_.findIndex(contactList, ['value', toNumber]) > -1) return contactList;

    if (toNumber) {
      setContactList(prevState => {
        return [
          {key: 'original', value: toNumber, text: `[기존번호] ${toNumber}`},
          ...prevState
        ]
      })
    }
  }

  return (
    <>
      <Modal open={modalOption.open}
             onClose={closeModal}
             closeIcon
             size={'large'}
             centered={false}>
        <Modal.Header>알림톡 발송내역</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>메세지타입</Table.HeaderCell>
                <Table.HeaderCell>수신번호</Table.HeaderCell>
                <Table.HeaderCell>발송일시</Table.HeaderCell>
                <Table.HeaderCell>성공여부</Table.HeaderCell>
                <Table.HeaderCell>내용보기</Table.HeaderCell>
                <Table.HeaderCell>재전송</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                data.length > 0 ?
                _.map(data, o => {
                  return (
                    <Table.Row>
                      <Table.Cell>{o.historyId}</Table.Cell>
                      <Table.Cell><strong>{o.msgTypeName}</strong><br/>{o.msgType}</Table.Cell>
                      <Table.Cell>{o.toNumber}</Table.Cell>
                      <Table.Cell>{o.regTime}</Table.Cell>
                      <Table.Cell>{o.resultMsg}<br/>({o.resultCode})</Table.Cell>
                      <Table.Cell>
                        <Button color='green' icon={'file alternate outline'} basic onClick={() => {
                          setContentModal(prevState => ({...prevState, open: true, content: JSON.parse(o.content)}));
                        }} />
                      </Table.Cell>
                      <Table.Cell>
                        <Button icon={'paper plane'}
                                color={'blue'}
                                onClick={() => {
                                  setMessage(prevState => {
                                    return {
                                      ...prevState,
                                      messageSendHistoryId: o.historyId,
                                      msgType: o.msgType,
                                      msgTypeName: o.msgTypeName,
                                      toNumber: o.toNumber,
                                      toNumberDisplay: `[기존번호] ${o.toNumber}`,
                                      msgContent: o.content,
                                    }
                                  });

                                  setInputNumberModal(prevState => {
                                    return {
                                      ...prevState,
                                      open: true,
                                    }
                                  });

                                  makeContactOptions(o.toNumber);
                                }
                        }/>
                      </Table.Cell>
                    </Table.Row>

                  )
                })
                :
                <Table.Row>
                  <Table.Cell colSpan={7} textAlign={'center'}><strong>데이터가 없습니다.</strong></Table.Cell>
                </Table.Row>
              }
            </Table.Body>
          </Table>
        </Modal.Content>

        <Modal.Actions>
          <Button color={'red'} icon={'times'} content={'닫기'} onClick={closeModal}/>
        </Modal.Actions>
      </Modal>


      <Modal open={inputNumberModal.open}
             centered={false}
             onClose={inputNumberModal.close}
             closeIcon
             size={'mini'}>
        <Modal.Header>
          수신번호 확인
        </Modal.Header>
        <Modal.Content>
          <Header as={'h4'}>{message.msgTypeName} [{message.msgType}]</Header>
          {
            message.msgType === AT_CENTER_MESSAGE ?
              <>
                <Dropdown
                  options={contactList}
                  placeholder={'수신 번호를 선택하세요.'}
                  selection
                  onChange={(e, {value}) => {
                    const content = JSON.parse(message.msgContent);
                    content['to'] = value;
                    setMessage(prevState => {
                      return {
                        ...prevState,
                        toNumber: value,
                        toNumberDisplay: e.target.outerText,
                        msgContent: JSON.stringify(content),
                      }
                    });
                  }}
                />
                <Button icon={'paper plane'} color={'blue'} onClick={sendMessage} content={`전송`} style={{marginLeft: 10}}/>
              </>
              :
              <FormInput
                fluid
                label={'수신번호'}
                name={'toNumber'}
                defaultValue={message.toNumber || ''}
                onChange={(e, {value}) => {
                  const content = JSON.parse(message.msgContent);
                  content['to'] = value;
                  setMessage(prevState => {
                    return {
                      ...prevState,
                      toNumber: value,
                      msgContent: JSON.stringify(content),
                    }
                  });
                }}
                action={<Button icon={'paper plane'} color={'blue'} onClick={sendMessage} />} />
          }
        </Modal.Content>
      </Modal>

      <JsonContentModal {...contentModal} />
      <CarmonConfirm {...resendConfirm} />
    </>
  );
}

export default MessageSendHistoryPopup;
