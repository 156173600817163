import React from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import 'semantic-ui-css/semantic.min.css'
import './App.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CarmonAdminMain from "./views/Main"
import CarmonAdminLoginFromLayout from "./views/Login";
import CarmonPrivateRoute from "./components/common/AuthRoute";
import './configs/axios_config'

const App = () => {
  const prevPathName = window.location.pathname;

  return (
    <Router>
      <Switch>
        <Route path="/login" component={CarmonAdminLoginFromLayout}/>
        <CarmonPrivateRoute component={CarmonAdminMain} prevPathName={prevPathName}/>
      </Switch>
    </Router>
  );
}

export default App