import React, {useState} from 'react';
import {Icon} from 'semantic-ui-react';

const CarmonReloadControl = ({callback}) => {
  const [enter, setEnter] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div onMouseEnter={() => setEnter(true)} onMouseLeave={() => loading ? '' : setEnter(false)}>
      <Icon name={enter ? 'redo' : 'times'}
            color={enter ? 'teal' : 'red'}
            loading={loading}
            onClick={() => {
              setLoading(true);
              enter ? callback && callback(() => {
                setEnter(false);
                setLoading(false);
              }) : ''
            }}/>
    </div>
  );
}

export default CarmonReloadControl;
