import React, { useState } from "react";
import {
  Button,
  Card,
  CardGroup,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import axios from "axios";
import { CommonUtil } from "../../../utils/CommonUtil";
import _ from "lodash";

const DEFAULT_MESSAGE = {
  carHistory: { type: "info", description: "데이터가 없습니다." },
  nice: { type: "info", description: "데이터가 없습니다." },
  molit: { type: "info", description: "데이터가 없습니다." },
};

export default function AccidentHistory({ plateNumber }) {
  const [message, setMessage] = useState(DEFAULT_MESSAGE);
  const [licensePlateNumber, setLicensePlateNumber] = useState(plateNumber);
  const [ownerNm, setOwnerNm] = useState("");
  const [carInformation, setCarInformation] = useState({
    latestMileage: 0,
    gearBoxName: "",
    firstRegDate: "",
    year: "",
    model: "",
    vehicleNumber: "",
  });
  const [accidentHistory, setAccidentHistory] = useState({});
  const [loading, setLoading] = useState({
    carHistory: false,
    nice: false,
    molit: false,
  });

  const retrieveAccidentHistory = async () => {
    if (!licensePlateNumber) {
      alert("차량번호를 입력해주세요.");
      return;
    }

    setMessage(DEFAULT_MESSAGE);
    // callMolit("withNiceDNR"); 나이스디앤알은 다른 버튼으로 호출
    callMolit();
    callCarHistory();
    //callNiceDNR();  차량 최초 등록일 param으로 전달하기 위해 molit api callback으로 호출
  };

  const callMolit = (type) => {
    setLoading((prevState) => {
      return { ...prevState, molit: true };
    });
    axios
      .post("/apis/admin/external/accident-history/molit", { licensePlateNumber: licensePlateNumber })
      .then(({ data }) => {
        if (data && data["carInformation"]) {
          setCarInformation(data["carInformation"]);
          if (type === "withNiceDNR") callNiceDNR(data["carInformation"]["firstRegDate"]);
        }

        if (data && data["repairHistory"]) {
          setAccidentHistory((prevState) => {
            return {
              ...prevState,
              molit: data.repairHistory,
            };
          });
        }
      })
      .catch((error) => {
        setMessage((prevState) => {
          return {
            ...prevState,
            molit: {
              type: "error",
              description: error.response && error.response.data && error.response.data.message,
            },
          };
        });

        // 정보초기화
        setCarInformation({
          latestMileage: 0,
          gearBoxName: "",
          firstRegDate: "",
          year: "",
          model: "",
          vehicleNumber: "",
        });
        setAccidentHistory({
          carHistory: "",
          nice: "",
          molit: "",
        });
      })
      .finally(() => {
        setLoading((prevState) => {
          return { ...prevState, molit: false };
        });
      });
  };

  const callCarHistory = () => {
    setLoading((prevState) => {
      return { ...prevState, carHistory: true };
    });
    axios
      .post("/apis/admin/external/accident-history/car-history", { licensePlateNumber: licensePlateNumber })
      .then(({ data }) => {
        setAccidentHistory((prevState) => {
          return {
            ...prevState,
            carHistory: data,
          };
        });
      })
      .catch((error) => {
        setMessage((prevState) => {
          return {
            ...prevState,
            carHistory: {
              type: "error",
              description: error.response && error.response.data && error.response.data.message,
            },
          };
        });
      })
      .finally(() => {
        setLoading((prevState) => {
          return { ...prevState, carHistory: false };
        });
      });
  };

  const callNiceDNR = (kindOf) => {
    if (kindOf === "personal" && (!licensePlateNumber || !ownerNm)) {
      alert("차량번호와 차주 이름을 입력해주세요.");
      return;
    }

    if (kindOf === "commercial" && (!carInformation?.vin || !carInformation?.firstRegDate)) {
      console.log(carInformation);
      alert("차량번호 검색 후 조회 가능합니다.");
      return;
    }

    setLoading((prevState) => {
      return { ...prevState, nice: true };
    });

    const requestData =
      kindOf === "personal"
        ? {
            kindOf: "personal",
            licensePlateNumber: licensePlateNumber,
            ownerNm: ownerNm,
          }
        : {
            kindOf: "commercial",
            licensePlateNumber: licensePlateNumber,
            vinNum: carInformation.vin,
            firstRegDate: carInformation.firstRegDate,
          };

    axios
      .post("/apis/admin/external/accident-history/nice-dnr", requestData)
      .then(({ data }) => {
        setAccidentHistory((prevState) => {
          return {
            ...prevState,
            nice: data,
          };
        });
      })
      .catch((error) => {
        setMessage((prevState) => {
          return {
            ...prevState,
            carHistory: {
              type: "error",
              description: error.response && error.response.data && error.response.data.message,
            },
          };
        });
      })
      .finally(() => {
        setLoading((prevState) => {
          return { ...prevState, nice: false };
        });
      });
  };

  return (
    <>
      <Header>차량 사고이력 조회</Header>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Input
                type={"text"}
                style={{ width: "200px" }}
                label={"차량번호"}
                value={licensePlateNumber}
                onChange={(event, { value }) => {
                  setLicensePlateNumber(value.trim());
                }}
                action={<Button icon={"search"} onClick={retrieveAccidentHistory} />}
              />
              <Header size="tiny">나이스디앤알 이력 조회</Header>
              <Form.Group>
                <div
                  style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", paddingLeft: "5px" }}
                >
                  <Button
                    icon={"search"}
                    content="매매상품용 차량조회"
                    onClick={() => callNiceDNR("commercial")}
                    color="orange"
                  />
                </div>
                <Form.Input
                  type={"text"}
                  // label={"차주 이름"}
                  placeholder={"차주 이름"}
                  value={ownerNm}
                  onChange={(event, { value }) => {
                    setOwnerNm(value.trim());
                  }}
                  action={
                    <Button
                      icon={"search"}
                      content="개인용 차량조회"
                      onClick={() => callNiceDNR("personal")}
                      color="orange"
                    />
                  }
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Container as={Segment} fluid>
              <Header>차량 기본 정보</Header>

              <Form size={"small"}>
                <Form.Group widths="equal">
                  <Form.Field control={Input} readOnly={true} label="차량번호" value={carInformation.vehicleNumber} />
                  <Form.Field control={Input} readOnly={true} label="모델" value={carInformation.model} />
                  <Form.Field control={Input} readOnly={true} label="연식" value={carInformation.year} />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field control={Input} readOnly={true} label="최초등록일" value={carInformation.firstRegDate} />
                  <Form.Field control={Input} readOnly={true} label="변속기" value={carInformation.gearBoxName} />

                  <Form.Field
                    control={Input}
                    readOnly={true}
                    label="최근주행거리"
                    value={CommonUtil.Unit.format(carInformation.latestMileage)}
                  />
                </Form.Group>
              </Form>
            </Container>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column>
            <Container as={Segment} fluid loading={loading.carHistory}>
              <div style={{ overflow: "hidden" }}>
                <Header floated={"left"}>카히스토리</Header>
                <Button
                  basic
                  size={"small"}
                  floated={"right"}
                  icon={"refresh"}
                  onClick={() => {
                    callCarHistory();
                  }}
                />
              </div>

              <Divider />

              {accidentHistory && accidentHistory.carHistory && !_.isEmpty(accidentHistory.carHistory) ? (
                <CardGroup>
                  {accidentHistory.carHistory.map((row, idx) => {
                    return (
                      <Card key={idx} fluid>
                        <Table celled striped>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>정비일자</b>
                              </Table.Cell>
                              <Table.Cell>
                                {row.inDay} <Icon name={"chevron right"} /> {row.outDay}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>정비내용</b>
                              </Table.Cell>
                              <Table.Cell>{row.workName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>보험금</b>
                              </Table.Cell>
                              <Table.Cell>{row.description}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Card>
                    );
                  })}
                </CardGroup>
              ) : (
                <Message info={message.carHistory.type === "info"} error={message.carHistory.type === "error"}>
                  <p>{message.carHistory.description}</p>
                </Message>
              )}
            </Container>
          </Grid.Column>

          <Grid.Column>
            <Container as={Segment} fluid loading={loading.nice}>
              <div style={{ overflow: "hidden" }}>
                <Header floated={"left"}>나이스디엔알</Header>
                <Button
                  basic
                  size={"small"}
                  floated={"right"}
                  icon={"refresh"}
                  onClick={() => {
                    callNiceDNR(carInformation.firstRegDate);
                  }}
                />
              </div>
              <Divider />

              {accidentHistory && accidentHistory.nice && !_.isEmpty(accidentHistory.nice.repairHistList) ? (
                <CardGroup>
                  {accidentHistory.nice.repairHistList.map((row, idx) => {
                    return (
                      <Card key={idx} fluid>
                        <Table celled striped>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>정비일자</b>
                              </Table.Cell>
                              <Table.Cell>
                                {row.inDay} <Icon name={"chevron right"} /> {row.outDay}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>정비내용</b>
                              </Table.Cell>
                              <Table.Cell>{row.workName}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Card>
                    );
                  })}
                </CardGroup>
              ) : (
                <Message info={message.nice.type === "info"} error={message.nice.type === "error"}>
                  <p>{message.nice.description}</p>
                </Message>
              )}
            </Container>
          </Grid.Column>

          <Grid.Column>
            <Container as={Segment} fluid loading={loading.molit}>
              <div style={{ overflow: "hidden" }}>
                <Header floated={"left"}>TS공단</Header>
                <Button basic size={"small"} floated={"right"} icon={"refresh"} onClick={callMolit} />
              </div>
              <Divider />

              {accidentHistory && accidentHistory.molit && !_.isEmpty(accidentHistory.molit) ? (
                <CardGroup>
                  {accidentHistory.molit.map((row, idx) => {
                    return (
                      <Card key={idx} fluid>
                        <Table celled striped>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>정비일자</b>
                              </Table.Cell>
                              <Table.Cell>
                                {row.inDay} <Icon name={"chevron right"} /> {row.outDay}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                <b>정비내용</b>
                              </Table.Cell>
                              <Table.Cell>{row.workName}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Card>
                    );
                  })}
                </CardGroup>
              ) : (
                <Message info={message.molit.type === "info"} error={message.molit.type === "error"}>
                  <p>{message.molit.description}</p>
                </Message>
              )}
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
