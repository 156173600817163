import axios from "axios";

export async function asyncRefreshToken() {
  try {
    const refreshToken = sessionStorage.getItem('refresh-token');

    if (!refreshToken) logout();

    const refreshTokenResponse = await axios.post(`/apis/token/refresh`, {}, {
      headers: {
        'Refresh-Token':  refreshToken
      }
    });

    if (refreshTokenResponse.status === 200) return refreshTokenResponse.data;
  } catch(error) {
    logout();
  }
}

const logout = () => {
  sessionStorage.removeItem("access-token");
  sessionStorage.removeItem("refresh-token");
  window.location = '/login';
}