import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Checkbox, Confirm,
  Divider, Dropdown,
  Grid,
  Header,
  Icon,
  Label, Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'lodash';
import {CommonUtil} from '../../../utils/CommonUtil';
import moment from 'moment';
import {EMAIL_REPORT_TEMPLATE} from '../../../consts/chachatja/reportTemplates/EmailTemplate';
import {MOBILE_REPORT_TEMPLATE} from '../../../consts/chachatja/reportTemplates/MobileTemplate';

const DEFAULT_STATUS = 'W';
const DEFAULT_ACTIVE_PAGE = 1;
const DEFAULT_PAGE_SIZE = 5;
const NO_PRICE = 99999999999;

export default function CCJRequestList() {
  const [comparisonTargets, setComparisonTargets] = useState([]);
  const [ccjRequests, setCcjRequests] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [comparisonResults, setComparisonResults] = useState([]);
  const [finalReport, setFinalReport] = useState('');

  const [showComparisonConfirm, setShowComparisonConfirm] = useState(false);
  const [showIssueConfirm, setShowIssueConfirm] = useState(false);

  const [loadings, setLoadings] = useState({});
  const [pageable, setPageable] = useState({
    status: DEFAULT_STATUS,
    activePage: DEFAULT_ACTIVE_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [isSending, setIsSending] = useState(false);

  const doComparison = () => {
    setComparisonResults([]);

    _.forEach(comparisonTargets, (target) => {
      const targetCompanyId = target.targetCompanyId;
      const targetCompanyName = target.targetCompanyName;
      const licensePlateNumber = selectedItem.searchText;
      //const randomTime = (_.random(5, (10 + idx), true) * 1000);
      //console.log(targetCompanyName, randomTime);

      setLoadings(prevState => ({...prevState, [targetCompanyId]: true}));
      //setTimeout(() => findCar(targetCompanyId, targetCompanyName, licensePlateNumber), randomTime);
      findCar(targetCompanyId, targetCompanyName, licensePlateNumber);
    });
  };

  const findCar = (targetCompanyId, targetCompanyName, licensePlateNumber) => {
    axios.post('/apis/admin/ccj/car-comparison/process', {
      requestId: 1,
      licensePlateNumber,
      targetCompanyId,
    })
      .then(({data}) => {
        if (data.price === null || data.price === '') data.price = NO_PRICE;

        const tg = _.find(comparisonTargets, ['targetCompanyId', data.targetCompanyId]);
        data.indexURL = tg?.indexURL || '';
        data.faviconURL = `https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/${tg.targetCompanyId}.ico`;
        setComparisonResults(prevState => {
          return [
            ...prevState,
            data
          ];
        });
      })
      .finally(() => setLoadings(prevState => ({...prevState, [targetCompanyId]: false})));
  };

  const getTargetCompanies = (countryFor) => {
    axios.get(`/apis/admin/ccj/car-comparison/target-companies?countryFor=${countryFor || 'L'}`)
      .then(({data}) => setComparisonTargets(data))
      .catch(
        ({
           response: {
             data: errorData,
           },
         }) => {
          console.error(errorData);
        });
  };

  const getRequests = () => {
    axios.get(`/apis/admin/ccj/requests?status=${pageable.status}&page=${pageable.activePage - 1}&size=${pageable.pageSize}&sort=regTime,desc`)
      .then(({data}) => {
        const refined = _.forEach(data.content, item => {
          item.parsedCarData = JSON.parse(item.carData);
          item.countryFor = 'L';
        });
        setCcjRequests({...data, content: refined});
      });
  };

  const issueReport = () => {
    console.log(selectedItem, comparisonResults);

    setIsSending(true);

    let emailTemplate = new XMLSerializer().serializeToString(document.querySelector('#emailTemplateArea > iframe').contentWindow.document);
    emailTemplate = emailTemplate.replaceAll('\n', '');

    axios.post(`/apis/admin/ccj/report-issue`, {
      requestId: selectedItem.requestId,
      rawData: JSON.stringify(comparisonResults),
      emailTemplate,
    })
      .then(() => {
        setSelectedItem(null);
        setComparisonResults([]);
        getRequests(pageable.activePage);
      })
      .finally(() => setIsSending(false));
  };

  useEffect(() => {
    getTargetCompanies();
    getRequests();
  }, []);

  useEffect(() => {
    getRequests();
  }, [pageable.status, pageable.activePage, pageable.pageSize]);

  useEffect(() => {
    if (selectedItem === null) return;

    let carData = selectedItem.parsedCarData;
    let report = finalReport.replaceAll('[##_차량번호_##]', selectedItem.searchText);
    report = report.replaceAll('[##_요청일시_##]', moment(selectedItem.regTime).format('YYYY년 MM월 DD일 HH:mm'));
    report = report.replaceAll('[##_모델_##]', carData.base.carDisplayName);
    report = report.replaceAll('[##_연식_##]', carData.base.carYear ? `${carData.base.carYear}년식` : (carData.detail.count === 1 ? `${carData.detail.data.prodYearName}년식` : '정보없음'));
    report = report.replaceAll('[##_최초등록일_##]', moment(carData.base.carFirstDate).format('YYYY년 MM월 DD일'));
    report = report.replaceAll('[##_차종_##]', carData.base.carModel);
    report = report.replaceAll('[##_용도_##]', carData.base.carUse);
    report = report.replaceAll('[##_연료_##]', carData.base.carFuel);
    report = report.replaceAll('[##_배기량_##]', `${CommonUtil.Unit.format(carData.base.carDisplacement)} cc`);
    report = report.replaceAll('[##_색상_##]', carData.base.carColor);
    report = report.replaceAll('[##_정원_##]', `${_.toNumber(carData.base.carPerson)}명`);
    report = report.replaceAll('[##_변속기_##]', carData.base.carTransmission);
    report = report.replaceAll('[##_주행거리_##]', `${CommonUtil.Unit.format(carData.base.carMileage)} km`);
    report = report.replaceAll('[##_요청아이디_##]', `${encodeURIComponent(selectedItem.requestId + '|' + selectedItem.searchText)}`);

    if (carData.detail.count === 1) {
      let detailTable = `<table width="${selectedItem.contactType === 'CEL' ? '640' : '480'}" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF">
          <tr>
            <td align="center" valign="top">

              <table width="${selectedItem.contactType === 'CEL' ? '600' : '440'}" cellpadding="0" cellspacing="0" border="0" class="container">
                <tr>
                  <td align="left" valign="top" style="line-height: 1.8">
      `;

      carData?.detail?.data?.carOpt?.split(/,/).map((keyword) => {
        detailTable += `<label style="font-size: 12px; background-color: #00b5ad!important; border-color: #00b5ad!important; color: #ffffff !important;display: inline-block; line-height: 1; vertical-align: baseline; margin: 0 0.14285714em; background-image: none; padding: 0.5833em 0.833em; color: rgba(0,0,0,.6); text-transform: none; font-weight: 700; border-radius: 0.28571429rem; -webkit-transition: background .1s ease; transition: background .1s ease;">${keyword}</label>`;
      });

      detailTable += `
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>`;

      report = report.replaceAll('[##_상세옵션_##]', detailTable);
    } else {
      report = report.replaceAll('[##_상세옵션_##]', '');
    }

    setFinalReport(report);
  }, [selectedItem]);

  useEffect(() => {
    if (_.countBy(loadings)?.true > 0) return;

    let priceListFor640 = '';
    let priceListFor320 = '';
    let priceList = '';

    _.map(_.sortBy(comparisonResults, ['price', 'targetCompanyId']), (result, idx) => {
      if (selectedItem.contactType === 'CEL') {
        /* 640 */
        priceListFor640 += `
        <tr ${idx === 0 ? 'style="background-color: #00b5ad22; font-weight: 700;"' : ''}>
            <td width="40" class="mobile" align="center" valign="middle">
              #${idx + 1}
            </td>
            <td width="215" class="mobile" align="left" valign="middle">
        `;

        if (result.price !== NO_PRICE) {
          priceListFor640 += `
            <a href="${result.link ? result.link : result.indexURL}" target="_blank" style="color:#000000; text-decoration:none;">
              <img src="${result.faviconURL || 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'}" alt="" 
                   onerror="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'"
                   style="width: 28px; height: auto; vertical-align: middle; margin-bottom: 3px;" />
                   ${_.trim(_.split(result.targetCompanyName, '-')[0])}
              <img src="${'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'}" alt="" 
                   onerror="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'"
                   style="width: 16px; height: auto; float: right; margin-top: 6px; vertical-align: middle;" />
            </a>
          `;
        } else {
          priceListFor640 += `
            <img src="${result.faviconURL || 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'}" alt="" 
                   onerror="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'"
                   style="width: 28px; height: auto; vertical-align: middle; margin-bottom: 3px;" />
                   ${_.trim(_.split(result.targetCompanyName, '-')[0])}
          `;
        }

        priceListFor640 += `
            </td>
        `;

        if (result.price !== NO_PRICE) {
          if (_.includes([1, 2], result.targetCompanyId)) {
            priceListFor640 += `
            <td width="90" class="mobile" align="center" valign="middle">
              ${CommonUtil.Unit.format(result.price)}만원
            </td>
            <td width="105" class="mobile" align="center" valign="middle">
              없음
            </td>
            <td width="155" class="mobile" align="center" valign="middle">
              기본보증(1개월 2천Km)<br/> + 추가보증(6개월)
            </td>
          `;
          } else {
            priceListFor640 += `
            <td width="90" class="mobile" align="center" valign="middle">
              ${CommonUtil.Unit.format(result.price)}만원
            </td>
            <td width="105" class="mobile" align="center" valign="middle">
              ${selectedItem?.countryFor === 'L' ? '30만원' : '40만원'}
            </td>
            <td width="155" class="mobile" align="center" valign="middle">
              기본보증(1개월 2천Km)
            </td>
          `;
          }
        } else {
          priceListFor640 += `
          <td width="70" class="mobile" align="center" valign="middle">차량없음</td>
          <td width="120" class="mobile" align="center" valign="middle">&nbsp;</td>
          <td width="155" class="mobile" align="center" valign="middle">&nbsp;</td>
        `;
        }

        priceListFor640 += `</tr>`;

        /* 320 */
        priceListFor320 += `
        <tr ${idx === 0 && 'style="background-color: #00b5ad22; font-weight: 700;"'}>
          <td>
        `;

        if (result.price !== NO_PRICE) {
          priceListFor320 += `
            <a href="${result.link ? result.link : result.indexURL}" target="_blank" style="color:#000000; text-decoration:none;">
              <img src="${result.faviconURL || 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'}" alt="" 
                   onerror="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'"
                   style="width: 28px; height: auto; vertical-align: middle; margin-bottom: 3px;" />
                   #${idx + 1} ${_.trim(_.split(result.targetCompanyName, '-')[0])}
              <img src="${'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'}" alt="" 
                   onerror="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'"
                   style="width: 16px; height: auto; float: right; margin-top: 6px; vertical-align: middle;" />
            </a>
          `;
        } else {
          priceListFor320 += `
            <img src="${result.faviconURL || 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'}" alt="" 
                   onerror="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'"
                   style="width: 28px; height: auto; vertical-align: middle; margin-bottom: 3px;" />
                   #${idx + 1} ${_.trim(_.split(result.targetCompanyName, '-')[0])}
          `;
        }

        if (result.price !== NO_PRICE) {
          if (_.includes([1, 2], result.targetCompanyId)) {
            priceListFor320 += `
            <p style="text-align: right;">
              ${CommonUtil.Unit.format(result.price)}만원<br/>
              중개수수료 없음<br/>
              기본보증(1개월 2천Km) + 추가보증(6개월)
            </p>
          `;
          } else {
            priceListFor320 += `
            <p style="text-align: right;">
              ${CommonUtil.Unit.format(result.price)}만원<br/>
              중개수수료 있음 (${selectedItem?.countryFor === 'L' ? '30' : '40'}만원)<br/>
              기본보증(1개월 2천Km)
            </p>
          `;
          }
        } else {
          priceListFor320 += `
          <p style="text-align: right;">
            차량없음
          </p>
        `;
        }

        priceListFor320 += `
          </td>        
        </tr>
        `;
      } else {
        priceList += `
        <tr ${idx === 0 ? 'style="background-color: #00b5ad22; font-weight: 700;"' : ''}>
          <td style="-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 6px; padding-bottom: 6px; border: 1px solid #2224261a; padding: 6px;">
        `;

        if (result.price !== NO_PRICE) {
          priceList += `<a href="${result.link ? result.link : result.indexURL}" target="_blank"
             style="color:#000000; text-decoration:none;">
            <img
              src="${result.faviconURL || 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'}"
              alt=""
              onError="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'"
              style="width: 28px; height: auto; vertical-align: middle; margin-bottom: 3px;" />
            #${idx + 1} ${_.trim(_.split(result.targetCompanyName, '-')[0])}
            <img src="${'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'}" alt=""
                 onError="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/openlink.png'"
                 style="width: 16px; height: auto; float: right; margin-top: 6px; vertical-align: middle;" />
          </a>`;
        } else {
          priceList += `<img
            src="${result.faviconURL || 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'}"
            alt=""
            onError="this.src = 'https://px-pjt-carmon.s3.ap-northeast-2.amazonaws.com/common/images/favicons/question.png'"
            style="width: 28px; height: auto; vertical-align: middle; margin-bottom: 3px;" />
          #${idx + 1} ${_.trim(_.split(result.targetCompanyName, '-')[0])}
          `;
        }

        if (result.price !== NO_PRICE) {
          if (_.includes([1, 2], result.targetCompanyId)) {
            priceList += `
            <p style="text-align: right;">
              ${CommonUtil.Unit.format(result.price)}만원<br/>
              중개수수료 없음<br/>
              기본보증(1개월 2천Km) + 추가보증(6개월)
            </p>
          `;
          } else {
            priceList += `
            <p style="text-align: right;">
              ${CommonUtil.Unit.format(result.price)}만원<br/>
              중개수수료 있음 (${selectedItem?.countryFor === 'L' ? '30' : '40'}만원)<br/>
              기본보증(1개월 2천Km)
            </p>
          `;
          }
        } else {
          priceList += `
          <p style="text-align: right;">
            차량없음
          </p>
        `;
        }

        priceList += `
          </td>        
        </tr>
        `;
      }
    });

    let report = finalReport.replaceAll('[##_가격리스트_640_##]', priceListFor640);
    report = report.replaceAll('[##_가격리스트_320_##]', priceListFor320);
    report = report.replaceAll('[##_가격리스트_##]', priceList);
    setFinalReport(report);

  }, [loadings]);

  return (
    <section>
      <Grid>
        <Grid.Row>
          <Grid.Column width="9">
            <Pagination
              defaultActivePage={DEFAULT_ACTIVE_PAGE}
              totalPages={ccjRequests?.totalPages || 1}
              onPageChange={(e, {activePage}) => setPageable(prevState => ({...prevState, activePage: activePage}))}
            />
          </Grid.Column>

          <Grid.Column width="3">
            <Dropdown
              fluid
              selection
              options={[
                {
                  key: 'ALL',
                  text: '전체',
                  value: 'A',
                },
                {
                  key: 'WAIT',
                  text: '처리대기',
                  value: 'W',
                },
                {
                  key: 'DONE',
                  text: '처리완료',
                  value: 'D',
                },
              ]}
              defaultValue={DEFAULT_STATUS}
              placeholder="표시건수"
              onChange={(e, {value}) => {
                setPageable(prevState => ({...prevState, status: value}));
              }}
            />
          </Grid.Column>

          <Grid.Column width="2">
            <Dropdown
              fluid
              selection
              options={[
                {
                  key: 'PGS_003',
                  text: '3건',
                  value: 3,
                },
                {
                  key: 'PGS_005',
                  text: '5건',
                  value: 5,
                },
                {
                  key: 'PGS_010',
                  text: '10건',
                  value: 10,
                },
                {
                  key: 'PGS_020',
                  text: '20건',
                  value: 20,
                },
              ]}
              defaultValue={DEFAULT_PAGE_SIZE}
              placeholder="조회건수"
              onChange={(e, {value}) => {
                setPageable(prevState => ({...prevState, pageSize: value}));
              }}
            />
          </Grid.Column>

          <Grid.Column width="2">
            <Button icon="search" content="요청조회" fluid primary onClick={() => getRequests()} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width="4">
            <Header dividing>
              가격조회요청
            </Header>
            {_.map(ccjRequests.content, (requestItem, idx) => (
              <Card fluid key={`requestItem_${idx}`} link
                    onClick={() => {
                      if (requestItem.ccjResponse !== null || (_.countBy(loadings)?.true > 0 || isSending)) return;
                      if (selectedItem === null) {
                        setSelectedItem(requestItem);
                        setFinalReport(requestItem.contactType === 'CEL' ? MOBILE_REPORT_TEMPLATE : EMAIL_REPORT_TEMPLATE);
                        getTargetCompanies();
                      } else {
                        if (selectedItem.requestId === requestItem.requestId) {
                          setSelectedItem(null);
                          setFinalReport('');
                          setComparisonResults([]);
                        } else {
                          setSelectedItem(requestItem);
                          setFinalReport(requestItem.contactType === 'CEL' ? MOBILE_REPORT_TEMPLATE : EMAIL_REPORT_TEMPLATE);
                          getTargetCompanies();
                        }
                      }
                    }}>
                {
                  requestItem.ccjResponse !== null
                    ? <Label icon="check" color="teal" corner="right" />
                    : <Label icon="wait" color="red" corner="right" />
                }
                <Card.Content>
                  <Card.Header>
                    <Checkbox disabled={requestItem.ccjResponse !== null}
                              checked={selectedItem?.requestId === requestItem.requestId}
                              label={requestItem.searchText} />
                  </Card.Header>
                  <Card.Meta>{requestItem.parsedCarData.base.carDisplayName}</Card.Meta>
                  <Card.Description>
                    <Table compact striped size="small">
                      <Table.Header />
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell><b>주행거리</b></Table.Cell>
                          <Table.Cell>{CommonUtil.Unit.format(requestItem.parsedCarData.base.carMileage)}km</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell><b>연료</b></Table.Cell>
                          <Table.Cell>{requestItem.parsedCarData.base.carFuel}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell><b>배기량</b></Table.Cell>
                          <Table.Cell>{CommonUtil.Unit.format(requestItem.parsedCarData.base.carDisplacement)}cc</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell><b>용도</b></Table.Cell>
                          <Table.Cell>{requestItem.parsedCarData.base.carUse}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell><b>색상</b></Table.Cell>
                          <Table.Cell>{requestItem.parsedCarData.base.carColor}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra textAlign="right">
                  {requestItem.contactType === 'EML' ? '이메일' : '휴대전화'} | {requestItem.contactText}<br />
                  구매희망지역 | {_.isEmpty(requestItem.location) ? '선택안함' : requestItem.location}
                  <br />
                  요청일시 {moment(requestItem.regTime).format('YYYY-MM-DD HH:mm')} {requestItem.ccjResponse !== null && (<>
                  <br />
                  응답일시 {moment(requestItem.ccjResponse?.regTime).format('YYYY-MM-DD HH:mm')}</>)}
                </Card.Content>
              </Card>
            ))}
          </Grid.Column>

          <Grid.Column width="3">
            <Header dividing>
              가격조회대상차량
            </Header>
            {
              selectedItem !== null
                ?
                (
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>
                        {selectedItem.searchText} <Button floated="right" size="mini" basic icon="remove"
                                                          onClick={() => setSelectedItem(null)} />
                      </Card.Header>
                      <Card.Meta>{selectedItem.parsedCarData.base.carDisplayName}</Card.Meta>
                      <Card.Description>
                        <Table compact striped size="small">
                          <Table.Header />
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell><b>주행거리</b></Table.Cell>
                              <Table.Cell>{CommonUtil.Unit.format(selectedItem.parsedCarData.base.carMileage)}km</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell><b>연료</b></Table.Cell>
                              <Table.Cell>{selectedItem.parsedCarData.base.carFuel}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell><b>배기량</b></Table.Cell>
                              <Table.Cell>{CommonUtil.Unit.format(selectedItem.parsedCarData.base.carDisplacement)}cc</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell><b>용도</b></Table.Cell>
                              <Table.Cell>{selectedItem.parsedCarData.base.carUse}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell><b>색상</b></Table.Cell>
                              <Table.Cell>{selectedItem.parsedCarData.base.carColor}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Button
                        fluid
                        toggle
                        active={selectedItem.countryFor === 'A'}
                        {...selectedItem.countryFor === 'A' && {icon: 'ship'}}
                        content={selectedItem.countryFor === 'L' ? '수입차량인 경우 클릭' : '수입차량'}
                        onClick={() => {
                          const newCountryFor = selectedItem.countryFor === 'L' ? 'A' : 'L';
                          setSelectedItem(prevState => ({
                            ...prevState,
                            countryFor: newCountryFor,
                          }));

                          setComparisonResults([]);
                          setFinalReport(selectedItem.contactType === 'CEL' ? MOBILE_REPORT_TEMPLATE : EMAIL_REPORT_TEMPLATE);
                          getTargetCompanies(newCountryFor);
                        }} />
                      <Divider />
                      <Button
                        fluid
                        primary
                        icon="search"
                        disabled={_.countBy(loadings)?.true > 0}
                        loading={_.countBy(loadings)?.true > 0}
                        content={`${selectedItem?.countryFor === 'L' ? '국산차량' : '수입차량'} 가격조회하기`}
                        onClick={() => setShowComparisonConfirm(true)} />
                    </Card.Content>
                  </Card>
                )
                :
                (
                  <Message error>
                    <Message.Content>
                      <Message.Header>
                        <Icon name="hand pointer outline" /> 처리할 요청을 먼저 선택하세요.
                      </Message.Header>
                    </Message.Content>
                  </Message>
                )}

            <Header dividing>
              가격조회대상 사이트
            </Header>
            <Table compact striped size="small">
              <Table.Header />
              <Table.Body>
                {_.map(comparisonTargets, (target, idx) => (
                  <Table.Row key={`target_${idx}`}>
                    <Table.Cell><b>{idx + 1}. {target.targetCompanyName} ({target.targetCompanyId})</b></Table.Cell>
                    <Table.Cell textAlign="center">
                      {loadings[target.targetCompanyId] &&
                      <Icon name="spinner" loading={loadings[target.targetCompanyId]} />}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column width="9">
            <Header dividing>
              조회결과 및 리포트발송
            </Header>
            <Button fluid icon="send"
                    content={`${selectedItem ? (selectedItem?.contactType === 'EML' ? '이메일' : '알림톡') : ''} 리포트 보내기`}
                    color="teal" size="large"
                    loading={isSending}
                    disabled={selectedItem === null || comparisonResults.length === 0 || (_.countBy(loadings)?.true > 0 || isSending)}
                    onClick={() => {
                      console.log(JSON.stringify(selectedItem), JSON.stringify(comparisonResults));
                      setShowIssueConfirm(true);
                    }} />
            <Segment loading={(_.countBy(loadings)?.true > 0 || isSending)} id="emailTemplateArea">
              {selectedItem !== null &&
              // <CCJReportTemplate selectedItem={selectedItem} comparisonResults={comparisonResults} />
              <iframe srcDoc={finalReport} style={{border: 'none', width: '100%', height: '100vh'}} />
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="3">
            <Pagination
              defaultActivePage={DEFAULT_ACTIVE_PAGE}
              totalPages={ccjRequests?.totalPages || 1}
              onPageChange={(e, {activePage}) => setPageable(prevState => ({...prevState, activePage: activePage}))}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Confirm
        header="확인!"
        content={<Header as="h4">{selectedItem?.countryFor === 'L' ? '국산차량' : '수입차량'} - [{selectedItem?.searchText}]의
          가격을 조회하시겠습니까?</Header>}
        size="mini"
        open={showComparisonConfirm}
        cancelButton="취소"
        confirmButton="조회"
        onCancel={() => setShowComparisonConfirm(false)}
        onConfirm={() => {
          setShowComparisonConfirm(false);
          doComparison();
        }} />

      <Confirm
        header="확인!"
        content={<Header as="h4">차량 [{selectedItem?.searchText}]의 리포트를 보내시겠습니까?</Header>}
        size="mini"
        open={showIssueConfirm}
        cancelButton="취소"
        confirmButton="보내기"
        onCancel={() => setShowIssueConfirm(false)}
        onConfirm={() => {
          setShowIssueConfirm(false);
          issueReport();
        }} />
    </section>
  );
}