import React, {useEffect, useReducer, useState} from 'react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Input,
  Menu, Modal,
  Segment,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import {UseYn} from '../../consts/Consts';
import CarmonEmployeeImageViewer from '../popup/EmployeeImageViewer';
import CarmonCompanyRegistrationForm from '../popup/CompanyRegistrationForm';

const IMAGE_TYPE = {
  EMPLOYEE_ID_CARD: 'employeeIdCard',
  BUSINESS_REGISTRATION: 'businessRegistration',
  BUYER_CERTIFICATE_INFO: 'buyerCertificateInfo',
};

const CarmonDealerList = () => {
  const listCountPerPage = 10;
  let maxPageCount = 10;

  const [page, setPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchForm, setSearchForm] = useState({
    //regStartDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    //regFinishDate: moment().format('YYYY-MM-DD'),
  });

  const [registrationImagePopup, setRegistrationImagePopup] = useState({
    open: false,
    close: () => {
      setRegistrationImagePopup((prevState => {
        return {...prevState, open: false};
      }));

      retrieveDealerList();
    },
    fileName: null,
    imageType: null,
    url: null,
  });

  const dataSortableReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          };
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        };
      case 'CHANGE_DATA':
        return {
          column: null,
          data: action.data,
          direction: null,
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(dataSortableReducer, {
    column: null,
    data: [],
    direction: null,
  });

  const {column, data, direction} = state;

  useEffect(async () => {
    await retrieveDealerList();
  }, []);

  const retrieveDealerList = async () => {
    try {
      const response = await axios.get('/apis/admin/dealer/list', {params: searchForm});
      if (response.status === 200) {
        dispatch({type: 'CHANGE_DATA', data: response.data});
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error,
      });
    }
  };

  const renderDealerList = () => {
    const render = [];

    const pageStartIdx = (page - 1) * listCountPerPage;
    const pageFinishIdx = (page * listCountPerPage) - 1;

    data.map((o, idx) => {
      if (idx < pageStartIdx) return;
      if (idx > pageFinishIdx) return false;

      render.push(
        <Table.Row key={o.dealerId}>
          <Table.Cell textAlign="right">{o.dealerId}</Table.Cell>
          <Table.Cell>{o.loginId}</Table.Cell>
          <Table.Cell>{o.name}</Table.Cell>
          <Table.Cell>{o.contactNumber}</Table.Cell>
          <Table.Cell>
            {o.employeeNumber}
            <Icon name={o.employeeIdCard ? 'address card outline' : 'times'}
                  color={o.employeeIdCard ? 'blue' : 'grey'}
                  size="large"
                  style={{float: 'right', cursor: 'pointer'}}
                  onClick={() => {
                    if (!o.employeeIdCard) return;

                    setRegistrationImagePopup(prevState => {
                      return {
                        ...prevState,
                        open: true,
                        dealerId: o.dealerId,
                        fileName: o.employeeIdCard,
                        imageType: IMAGE_TYPE.EMPLOYEE_ID_CARD,
                        url: process.env.API_BASE_URL.concat(`/apis/admin/image/dealer/${o.loginId}/${o.employeeIdCard}`),
                      };
                    });
                  }} />
          </Table.Cell>
          <Table.Cell>
            <strong><a href="#">{o.companyName}</a></strong>
            <Icon name={o.businessRegistration ? 'file alternate outline' : 'times'}
                  color={o.businessRegistration ? 'green' : 'grey'}
                  size="large"
                  style={{float: 'right', cursor: 'pointer'}}
                  onClick={() => {
                    if (!o.businessRegistration) return;

                    setRegistrationImagePopup(prevState => {
                      return {
                        ...prevState,
                        open: true,
                        dealerId: o.dealerId,
                        fileName: o.businessRegistration,
                        imageType: IMAGE_TYPE.BUSINESS_REGISTRATION,
                        url: process.env.API_BASE_URL.concat(`/apis/admin/image/dealer/${o.loginId}/${o.businessRegistration}`),
                        buyerCertificateInfoUrl: process.env.API_BASE_URL.concat(`/apis/admin/image/dealer/${o.loginId}/${o.buyerCertificateInfo}`),
                      };
                    });
                  }} />
          </Table.Cell>
          <Table.Cell>{o.companyContactNumber}</Table.Cell>
          <Table.Cell>
            <Icon name="check" style={{color: o.adminConfirmYn === UseYn.YES ? '#21ba45' : '#dddddd'}} />
            <Button basic={o.adminConfirmYn !== UseYn.NO}
                    size="mini"
                    floated="right"
                    color={o.adminConfirmYn === UseYn.NO ? 'blue' : 'red'}
                    icon={o.adminConfirmYn === UseYn.NO ? 'thumbs up' : 'thumbs down'}
                    content={o.adminConfirmYn === UseYn.NO ? '승인처리' : '승인취소'}
                    onClick={() => eventHandler.onClickAdminConfirm(o.dealerId, o.adminConfirmYn)} />
          </Table.Cell>
          {/*<Table.Cell>{o.grade}</Table.Cell>*/}
          <Table.Cell>{moment(o.regTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
        </Table.Row>,
      );
    });

    return render;
  };

  const renderPagination = () => {
    const render = [];

    if (data.length > listCountPerPage) {
      render.push(
        <Menu.Item key="prevPageGroup" as="a" icon="chevron left" onClick={() => {
          if (pageGroup > 1) setPageGroup(pageGroup - 1);
        }} />,
      );

      for (let i = ((pageGroup - 1) * maxPageCount + 1); i <= Math.ceil(data.length / listCountPerPage); i++) {
        if (i > pageGroup * maxPageCount) break;

        render.push(<Menu.Item key={i} as="a" onClick={() => setPage(i)}
                               style={{background: page === i ? `rgb(0, 0, 0, .1)` : `0 0`}}>{i}</Menu.Item>);
      }

      render.push(
        <Menu.Item key="nextPageGroup" as="a" icon="chevron right" onClick={() => {
          if (Math.ceil(data.length / listCountPerPage) > (pageGroup * maxPageCount)) {
            setPageGroup(pageGroup + 1);
          }
        }} />,
      );
    }

    return render;
  };

  const eventHandler = {
    onChangeSearchForm: (event, {name, value}) => {
      setSearchForm(prevState => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    },
    onClickAdminConfirm: async (dealderId) => {
      setLoading(true);
      try {
        const response = await axios.put('/apis/admin/dealer/confirm', {dealderId: dealderId});
        if (response.status === 200) {
          retrieveDealerList();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast({
          type: 'error',
          title: 'ERROR',
          time: 2000,
          description: error.response && error.response.data && error.response.data.message || error,
        });
      }
    },
  };

  return (
    <>
      <Container fluid>
        <Segment attached="top">
          <Form>
            <Form.Group widths="3">
              <Form.Field>
                <label>가입일자</label>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input size="small"
                           type="date"
                           name="regStartDate"
                           defaultValue={searchForm.regStartDate}
                           onChange={eventHandler.onChangeSearchForm} />
                  </Form.Field>
                  ~
                  <Form.Field>
                    <Input size="small"
                           type="date"
                           name="regFinishDate"
                           defaultValue={searchForm.regFinishDate}
                           onChange={eventHandler.onChangeSearchForm} />
                  </Form.Field>
                </Form.Group>
              </Form.Field>
              <Form.Field
                control={Input}
                name="dealerName"
                label="딜러명"
                placeholder="딜러명"
                onChange={eventHandler.onChangeSearchForm}
              />
              <Form.Field
                control={Input}
                name="employeeNumber"
                label="종사원증번호"
                placeholder="종사원증번호"
                onChange={eventHandler.onChangeSearchForm}
              />
              <Form.Field
                control={Input}
                name="companyName"
                label="매매단지"
                placeholder="매매단지"
                onChange={eventHandler.onChangeSearchForm}
              />
            </Form.Group>
          </Form>
        </Segment>
        <Segment attached="bottom" style={{background: `#f9fafb`}}>
          <Container fluid textAlign="right">
            <Button floated="left" color="blue"
                    onClick={() => window.open('http://www.carku.co.kr/situation/member.asp')}><Icon
              name="share square" />전국자동차매매사업조합연합회</Button>
            <Button floated="left" color="yellow"
                    onClick={() => window.open('http://www.kuca.kr/customer/search.html')}><Icon name="share square" />한국자동차매매사업조합연합회</Button>

            <Button.Group>
              <Button color="teal" onClick={retrieveDealerList}><Icon name="search" />Search</Button>
            </Button.Group>
          </Container>
        </Segment>
      </Container>

      <Segment basic style={{overflowX: 'auto', padding: 0}} loading={loading}>
        <Table sortable celled selectable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'dealerId' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'dealerId'})}
              >
                ID
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'loginId' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'loginId'})}
              >
                로그인아이디
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'name' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'name'})}
              >
                이름
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'contactNumber' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'contactNumber'})}
              >
                연락처
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'employeeNumber' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'employeeNumber'})}
              >
                종사원증번호
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'companyName' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'companyName'})}
              >
                소속상사
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'companyContactNumber' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'companyContactNumber'})}
              >
                상사연락처
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'adminConfirmYn' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'adminConfirmYn'})}
              >
                승인여부
              </Table.HeaderCell>

              {/*
              <Table.HeaderCell
                sorted={column === 'grade' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'grade'})}
              >
                등급
              </Table.HeaderCell>
              */}

              <Table.HeaderCell
                sorted={column === 'regTime' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'regTime'})}
              >
                등록일시
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {renderDealerList()}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="15">
                <Header floated="left">Total {data.length}건</Header>
                <Menu floated="right" pagination>
                  {renderPagination()}
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Modal
        closeIcon
        onClose={() => registrationImagePopup.close()}
        open={registrationImagePopup.open}
        size={registrationImagePopup.imageType === IMAGE_TYPE.EMPLOYEE_ID_CARD ? 'small' : 'large'}
        centered>
        <Modal.Content scrolling style={{padding: 0}}>
          {
            registrationImagePopup.imageType === IMAGE_TYPE.EMPLOYEE_ID_CARD ?
              <CarmonEmployeeImageViewer {...registrationImagePopup} />
              :
              <CarmonCompanyRegistrationForm {...registrationImagePopup} />
          }
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CarmonDealerList;