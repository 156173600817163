import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {Button, Form, Grid, Icon, Input, Menu, Modal, Segment, Table} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'lodash';
import {Association, DISTANCE_UNIT, Location} from '../../consts/Consts';
import DaumPostCodeModal from './DaumPostCode';
import {CommonUtil} from '../../utils/CommonUtil';
import {toast} from 'react-semantic-toasts';
import CenterList from './searchCenter/CenterList';

const COUNT_PER_PAGE = 10;
const MAX_PAGE_COUNT = 10;

const APIS = {
  retrieveCenterChannel: async () => {
    return await axios.get('/apis/common/channel');
  },

  retrieveCenter: async () => {
    return await axios.get('/apis/common/center');
  },

  retrieveCommonCode: async (codeGroup) => {
    return await axios.get(`/apis/common/code_list/${codeGroup}`);
  }
}


const CarmonSearchCenter = ({
                              open = false,
                              close,
                              withResTime,
                              reservation = null,
                              callback = null
                            }) => {
  const maxPage = useRef(1);
  const [page, setPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);
  
  const [pageData, setPageData] = useState([])

  const [searchForm, setSearchForm] = useState({
    locationState: '',
    locationRegion: '',
    centerChannel: '',
  });

  const [state, setState] = useState({
    association: _.cloneDeep(Association),
    centerChannelList: [],
    centerList: [],
    locationStateList: [],
    locationRegionList: [],
    locationRegionListAll: [],
    selectedCenter: null,
  });

  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [addressPoint, setAddressPoint] = useState('');
  const [daumPostModalOptions, setDaumPostModalOptions] = useState({
    address: '',
    addressDetail: '',
    open: false,
    callback: (address) => {
      document.getElementsByName('address')[0].value = address;
      setAddress(address);
    }
  });

  useEffect(() => {
    const callInitBaseData = async () => {
      await initBaseData();
    }
    callInitBaseData();
  }, []);

  useEffect(() => {
    if (reservation) {
      setAddress(reservation?.data?.address);
    }
    return dispatch({type: 'CHANGE_DATA', data: []});
  }, [reservation]);

  useEffect(() => {
    if (_.isEmpty(address)) return;

    const callGetPoint = async () => {
      await getPoint();
    }

    callGetPoint();
  }, [address]);

  useEffect(() => {
    if (page > maxPage.current) setPage(1);
  }, [maxPage.current]);

  useEffect(() => {
    fillDuration(data, addressPoint);
    // fillReservationTimes(data);
  }, [page]);

  const initBaseData = useCallback(async () => {
    const [centerChannelRes, centerRes, locationStateRes, locationRegionRes] = await Promise.all([
      APIS.retrieveCenterChannel(),
      APIS.retrieveCenter(),
      APIS.retrieveCommonCode(Location.STATE),
      APIS.retrieveCommonCode(Location.REGION)
    ]);

    if (centerChannelRes.status === 200) {
      const channelOptions = [{key: 0, value: 0, text: '전체'}];
      centerChannelRes.data.map(o => {
        channelOptions.push({key: o.centerChannelId, value: o.centerChannelId, text: o.name});
      })

      setState(prevState => {
        return {...prevState, centerChannelList: channelOptions};
      });
    }

    if (centerRes.status === 200) {
      setState(prevState => {
        return {...prevState, centerListAll: centerRes.data};
      });

      await fillDuration(data, addressPoint);
      // await fillReservationTimes(centerRes.data);
    }

    if (locationStateRes.status === 200) {
      const locationStateOptions = [{key: 0, value: 0, text: '전체'}];
      locationStateRes.data.map(o => {
        locationStateOptions.push({key: o.commonCodeId, value: o.code, text: o.name});
      })
      setState(prevState => {
        return {...prevState, locationStateList: locationStateOptions};
      });
    }

    if (locationRegionRes.status === 200) {
      setState(prevState => {
        return {...prevState, locationRegionListAll: locationRegionRes.data};
      });
    }
  }, []);

  // input:address -> output:lan, lat
  const getPoint = async () => {
    setLoading(true);
    const response = await axios.get('apis/common/address/point', {params: {address: address}});
    const centerList = _.cloneDeep(state.centerListAll);
    
    if (response.status === 200) {
      const mergedAddressPoint = `${response.data.x},${response.data.y}`;
      setAddressPoint(mergedAddressPoint);

      centerList.map(v => {
        if (v.addressPoint) {
          const centerPoint = v.addressPoint.split(",");
          v.distance = CommonUtil.distance(
            response.data.y,  //위도1
            response.data.x,  //경도1
            _.toNumber(centerPoint[1]), //위도2
            _.toNumber(centerPoint[0]));  //경도2
        } else {
          v.distance = 9999;
        }

        return v;
      });

      const displayCenterList = _.sortBy(centerList, ['distance']);
      
      await fillDuration(displayCenterList, mergedAddressPoint);
      // await fillReservationTimes(displayCenterList);
    }

    setLoading(false);
  }

  // 센터 렌더링 하는 함수
  const renderCenter = () => {
    const render = [];
    const pageStartIdx = (page - 1) * COUNT_PER_PAGE;
    const pageFinishIdx = (page * COUNT_PER_PAGE) - 1;

    if (_.isEmpty(data) || data.length === 0) {
      // render.push(
      //   <Table.Row key='emptyCenterRows'>
      //     <Table.Cell colSpan={10} warning>등록된 정비소가 없습니다.</Table.Cell>
      //   </Table.Row>
      // );
      // return
      return (
        <Table.Row key='emptyCenterRows'>
          <Table.Cell colSpan={10} warning>등록된 정비소가 없습니다.</Table.Cell>
        </Table.Row>
      )
    } 

    const filteredData = getFilteredData();
    const displayPageData = filteredData.slice(pageStartIdx, pageFinishIdx + 1)
    
    // render.push(
    //   <CenterList pageData={displayPageData} state={state} reservation={reservation} withResTime={withResTime} eventHandler={eventHandler} />
    // )
    // return render;

      return <CenterList pageData={displayPageData} state={state} reservation={reservation} withResTime={withResTime} eventHandler={eventHandler} />
  }

  // // 센터 채널 id 이용해서 제휴 회사 이름 불러오기
  // const getChannelName = (centerChannel) => {
  //   let findObject = state.centerChannelList.filter((c) => {
  //     return c.value === centerChannel;
  //   });

  //   return findObject.length === 0 ? "미등록 회원사" : findObject[0]['text'];
  // }

  const changeLocationState = (location) => {
    let filteredRegion = [{key: 0, value: 0, text: '전체'}];

    state.locationRegionListAll.map((o) => {
      if (_.isEmpty(location.value))
        filteredRegion.push({key: o.commonCodeId, value: o.code, text: o.name})
      else if (o.masterCode === location.value) {
        filteredRegion.push({key: o.commonCodeId, value: o.code, text: o.name})
      }
    });

    setState(prevState => {
      return {
        ...prevState,
        locationRegionList: filteredRegion,
        searchForm: {
          ...prevState.searchForm,
          locationRegion: location.value
        }
      }
    });

    eventHandler.onFormChange(null, location);
  }

  const eventHandler = {
    locationStateChange: (event, op) => {
      changeLocationState(op);
    },
    onFormChange: (event, {name, value}) => {
      setSearchForm(prevState => {
        return {
          ...prevState,
          [name]: value
        }
      });

      setPage(1);
    },
    onSelectCenter: (center) => {
      setState(prevState => {
        return {
          ...prevState,
          selectedCenter: center
        }
      });
    },
    onSelectComplete: () => {
      callback && callback(state.selectedCenter, reservation);
      setAddress(null);
      close();
    }
  }

  // 데이터 정렬, 데이터 업데이트
  const dataSortableReducer = (centerData, action) => {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (centerData.column === action.column) {
          return {
            ...centerData,
            data: centerData.data.slice().reverse(),
            direction:
              action.direction || centerData.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }

        return {
          column: action.column,
          data: _.sortBy(centerData.data, [action.column]),
          direction: 'ascending',
        }
      case 'CHANGE_DATA':
        return {
          column: null,
          data: action.data,
          direction: null
        }
      default:
        throw new Error()
    }
  }

  const [centerData, dispatch] = useReducer(dataSortableReducer, {
    column: null,
    data: [],
    direction: null,
  })

  const {column, data, direction} = centerData;

  const fillDuration = async (displayCenterList, originPoint) => {
    if (!_.isEmpty(originPoint)) {
      setLoading(true);
      let filledData = [];

      try {
        const pageStartIdx = (page - 1) * COUNT_PER_PAGE;
        const pageFinishIdx = (page * COUNT_PER_PAGE) - 1;

        filledData = await Promise.all(
          displayCenterList.map(async (o, idx) => {
            if (reservation && reservation.data.reservationDate) {
              if (idx >= pageStartIdx && idx <= pageFinishIdx) {
                if (withResTime) {
                  const yyyymmddhhmm = `${reservation?.data?.reservationDate.replace(/-/g, '')}${Math.floor(reservation?.data?.reservationTime)}${reservation?.data?.reservationTime % 1 > 0 ? `30` : '00'}`;
                  const res = await axios.get(`/apis/common/directions/future?pt1=${originPoint}&pt2=${o.addressPoint}&time=${yyyymmddhhmm}`);
                  o['realDistance'] = `${_.round(res.data['realDistance'] / 1000, 2)} km`;
                  o['duration'] = `${_.round(res.data['duration'] / 60, 0)} 분`;
                }
              }
            }

            return o;
          })
        );
      } catch (error) {
        toast({
          type: "error",
          title: "ERROR",
          time: 10000,
          description: JSON.stringify(error.response.data)
        });
      } finally {
        dispatch({type: 'CHANGE_DATA', data: filledData});
        dispatch({type: 'CHANGE_SORT', column: 'distance', direction: 'ascending'});
        setLoading(false);
      }
    }
  }

  const getFilteredData = () => {
    return data.filter((o) => {
      if (!_.isEmpty(searchForm.locationState) && o.locationState !== searchForm.locationState) return false;
      if (!_.isEmpty(searchForm.locationRegion) && o.locationRegion !== searchForm.locationRegion) return false;
      if (searchForm.centerChannel > 0 && o.centerChannelId !== searchForm.centerChannel) return false;

      return true;
    });
  }

  const renderPagination = () => {
    const render = [];
    let total = 0;

    const filteredData = getFilteredData();

    if (filteredData.length > COUNT_PER_PAGE) {
      render.push(
        <Menu.Item key='prevPageGroup' as='a' icon='chevron left' onClick={() => {
          if (pageGroup > 1) setPageGroup(pageGroup - 1);
        }}/>
      );

      for (let i = ((pageGroup - 1) * MAX_PAGE_COUNT + 1); i <= Math.ceil(filteredData.length / COUNT_PER_PAGE); i++) {

        if (i > pageGroup * MAX_PAGE_COUNT) break;

        render.push(<Menu.Item key={i} as='a' onClick={() => setPage(i)}
                               style={{background: page === i ? `rgb(0, 0, 0, .1)` : `0 0`}}>{i}</Menu.Item>);
        total = i;
      }

      render.push(
        <Menu.Item key='nextPageGroup' as='a' icon='chevron right' onClick={() => {
          if (Math.ceil(filteredData.length / COUNT_PER_PAGE) > (pageGroup * MAX_PAGE_COUNT)) {
            setPageGroup(pageGroup + 1);
          }
        }}/>
      );
    }

    maxPage.current = total + 1;

    return render;
  }

  return (
    <>
      <Modal open={open}
             centered={false}
             size='fullscreen' style={{marginLeft: '3em'}}>
        <Modal.Header>정비소 검색</Modal.Header>
        <Modal.Content>
          <Segment attached='top'>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Select fluid
                                   label='회원사명'
                                   placeholder='회원사명'
                                   name='centerChannel'
                                   options={state.centerChannelList}
                                   onChange={eventHandler.onFormChange}
                      />
                      <Form.Select fluid
                                   label='지역(광역시/도)'
                                   name='locationState'
                                   placeholder='광역시/도'
                                   options={state.locationStateList}
                                   defaultValue={searchForm.locationState}
                                   onChange={eventHandler.locationStateChange}
                      />
                      <Form.Select fluid
                                   label='지역(시/구/군)'
                                   placeholder='시/구/군'
                                   name='locationRegion'
                                   options={state.locationRegionList}
                                   defaultValue={searchForm.locationRegion}
                                   onChange={eventHandler.onFormChange}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment attached style={{padding: '0', overflowY: 'auto', maxHeight: '400px'}} loading={loading}>
            <Table basic selectable style={{borderRadius: 0, borderWidth: 0}}>
              <Table.Header style={{background: `#f9fafb`}}>
                <Table.Row verticalAlign='middle'>
                  <Table.HeaderCell>회원사명</Table.HeaderCell>
                  <Table.HeaderCell>정비소명</Table.HeaderCell>
                  <Table.HeaderCell>연락처</Table.HeaderCell>
                  <Table.HeaderCell>점검이력</Table.HeaderCell>
                  {/*<Table.HeaderCell*/}
                  {/*    sorted={column === 'distance' ? direction : null}*/}
                  {/*    onClick={() => dispatch({type: 'CHANGE_SORT', column: 'distance'})}*/}
                  {/*    style={{display: address ? '' : 'none'}}>*/}
                  {/*  거리(km)*/}
                  {/*</Table.HeaderCell>*/}

                  {
                    withResTime &&
                    <Table.HeaderCell sorted={column === 'realDistance' ? direction : null}
                                      onClick={() => dispatch({type: 'CHANGE_SORT', column: 'realDistance'})}
                                      style={{display: address ? '' : 'none'}}>
                      거리(km)
                    </Table.HeaderCell>
                  }

                  {
                    withResTime &&
                    <Table.HeaderCell>
                      예상소요시간
                    </Table.HeaderCell>
                  }

                  <Table.HeaderCell>주소</Table.HeaderCell>
                  <Table.HeaderCell>수입차정비여부</Table.HeaderCell>
                  {
                    withResTime &&
                    <Table.HeaderCell>예약가능시간
                      ({reservation?.data?.reservationDate} {Math.floor(reservation?.data?.reservationTime)}시 {reservation?.data?.reservationTime % 1 > 0 ? `30분` : ''})
                    </Table.HeaderCell>
                  }
                  <Table.HeaderCell>비고</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {renderCenter()}
              </Table.Body>
            </Table>
          </Segment>

          <div style={{overflow: 'hidden', margin:'10px 0 0 0'}}>
            <Menu floated='right' pagination>
              {renderPagination()}
            </Menu>
          </div>

        </Modal.Content>

        <Modal.Actions>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} style={{textAlign:'left'}}>
                <Input fluid
                       name='address'
                       action={{
                         icon: 'search',
                         color: 'orange',
                         onClick: () => {
                           setDaumPostModalOptions(prevState => {
                             return {
                               ...prevState,
                               open: true
                             }
                           })
                         }
                       }}
                       defaultValue={address}
                       placeholder='주소를 설정하면 정비소 거리가 산정됩니다.'
                       readOnly />
              </Grid.Column>
              <Grid.Column width={8}>
                <Button color='teal' onClick={eventHandler.onSelectComplete}>
                  <Icon name='check'/> 선택
                </Button>
                <Button color='red' onClick={() => {
                  setAddress(null);
                  setSearchForm({
                    locationState: '',
                    locationRegion: '',
                    centerChannel: '',
                  })
                  close();
                }}>
                  <Icon name='remove'/> 닫기
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Actions>
      </Modal>

      <DaumPostCodeModal modalOption={daumPostModalOptions}
                         setModalOption={setDaumPostModalOptions}/>
    </>
  )
}

export default CarmonSearchCenter;