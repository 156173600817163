import React from 'react';
import DaumPostcode from "react-daum-postcode";
import {Modal} from "semantic-ui-react";

const DaumPostCodeModal = ({modalOption, setModalOption}) => {
  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '100%',
    height: '450px',
    padding: '1px'
  };

  const setOpen = (open) => {
    setModalOption(prevState => {
      return {
        ...prevState,
        open: open,
      }
    });
  }

  const onCompletePost = (data) => {
    console.log(data);


    let fullAddr = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    setModalOption(prevState => {
      return {
        ...prevState,
        address: data.zonecode,
        addressDetail: fullAddr,
        open: false,
      }
    });

    modalOption && modalOption.callback && modalOption.callback(fullAddr, data.zonecode);
  };

  return (
    <>
      <Modal
        closeIcon
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={modalOption.open}
        size='tiny'
        centered={false}>
        <Modal.Content style={{padding: '0'}}>
          <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompletePost}/>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default DaumPostCodeModal;
