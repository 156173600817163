import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Grid, Checkbox, Input, Label, Popup, Table, TextArea, Menu, Button, Header, Container} from 'semantic-ui-react';
import {CommonUtil} from '../../../utils/CommonUtil'

import BluePrint from '../../../images/blueprint.png';
import moment from 'moment';
import {BASE_STYLES} from '../../../consts/Consts';
import _ from 'lodash';
import {PerformanceModalOptionContext} from '../ReservationList';
import AccidentHistory from '../partners/AccidentHistory';
import WindowPopup from '../../common/WindowPopup';

const STATE_TYPE = [
  {label: '선택안함', value: '', color: '', isAccident: false},
  {label: '교환(교체)', value: 'X', color: 'red', isAccident: true},
  {label: '판금용접', value: 'W', color:'orange', isAccident: true},
  {label: '부식', value: 'C', color:'yellow', isAccident: true},
  {label: '흠집', value: 'A', color:'olive', isAccident: false},
  {label: '요철', value: 'U', color:'green', isAccident: false},
  {label: '손상', value: 'T', color:'blue', isAccident: false},
]

const DEFAULT_DATA = {
  BAS_000001: '', //성능점검장코드
  BAS_000002: '', //성능지관리번호
  BAS_000003: moment().format('YYYYMMDD'), //성능점검일
  BAS_000004: '', //성능점검장명
  BAS_000005: '', //성능점검자
  BAS_000006: '카몬', //고객명
  BAS_010000: '', //차명
  BAS_010100: '', //세부모델
  BAS_020000: '', //자동차등록번호
  BAS_030000: '', //연식
  BAS_040100: '', //검사유효기간 시작일
  BAS_040200: '', //검사유효기간 종료일
  BAS_050000: '', //최초등록일
  BAS_060000: '', //차대번호
  BAS_070000: '1', //변속기종류
  BAS_070100: '', //변속기종류기타
  BAS_080000: '1', //사용연료
  BAS_090000: '', //원동기형식
  BAS_990000: '', //특기사항

  TOT_110101: '1', //계기상태
  TOT_110201: '', //주행거리상태
  TOT_110202: '', //주행거리항목
  TOT_110301: '1', //차대번호표기
  TOT_110401: '2', //일산화탄소상태
  TOT_110402: '', //일산화탄소항목
  TOT_110411: '2', //탄화수소상태
  TOT_110412: '', //탄화수소항목
  TOT_110421: '', //매연상태
  TOT_110422: '', //매연항목
  TOT_110501: '1', //튜닝상태
  TOT_110511: '', //튜닝
  TOT_110521: '', //튜닝-구조
  TOT_110522: '', //튜닝-장치
  TOT_110601: '1', //특별이력상태
  TOT_110611: '', //특별이력-침수
  TOT_110612: '', //특별이력-화재
  TOT_110701: '1', //용도변경상태
  TOT_110711: '', //용도변경-렌트
  TOT_110713: '', //용도변경-영업용
  TOT_110801: '', //리콜대상
  TOT_110811: '', //리콜이행

  IMS_120001: '', //후드
  IMS_120004: '', //트렁크리드
  IMS_120005: '', //라디에이터서포트
  IMS_120007: '', //루프패널
  IMS_120009: '', //프론트패널
  IMS_120010: '', //크로스멤버
  IMS_120015: '', //대쉬패널
  IMS_120016: '', //플로어패널
  IMS_120017: '', //트렁크플로어
  IMS_120018: '', //리어패널
  IMS_120019: '', //패키지트레이
  IMS_121002: '', //프론트휀더(좌)
  IMS_121006: '', //쿼터패털(좌)
  IMS_121008: '', //사이드실패널(좌)
  IMS_121011: '', //인사이드패널(좌)
  IMS_121103: '', //도어(좌전)
  IMS_121112: '', //사이드멤버(좌전)
  IMS_121113: '', //휠하우스(좌전)
  IMS_121114: '', //필러패널(좌전)
  IMS_121214: '', //필러패널(좌중)
  IMS_121303: '', //도어(좌후)
  IMS_121312: '', //사이드멤버(좌후)
  IMS_121313: '', //휠하우스(좌후)
  IMS_121314: '', //필러패널(좌후)
  IMS_122002: '', //프론트휀더(우)
  IMS_122006: '', //쿼터패털(우)
  IMS_122008: '', //사이드실패널(우)
  IMS_122011: '', //인사이드패널(우)
  IMS_122103: '', //도어(우전)
  IMS_122112: '', //사이드멤버(우전)
  IMS_122113: '', //휠하우스(우전)
  IMS_122114: '', //필러패널(우전)
  IMS_122214: '', //필러패널(우중)
  IMS_122303: '', //도어(우후)
  IMS_122312: '', //사이드멤버(우후)
  IMS_122313: '', //휠하우스(우후)
  IMS_122314: '', //필러패널(우후)

  HST_12A001: '1', //사고이력
  HST_12A002: '1', //단순수리
  HST_140101: '', //외판부위1랭크-1후드
  HST_140102: '', //외판부위1랭크-2프론트휀더
  HST_140103: '', //외판부위1랭크-3도어
  HST_140104: '', //외판부위1랭크-4트렁크리드
  HST_140105: '', //외판부위1랭크-5라디에이터서포트
  HST_140201: '', //외판부위2랭크-6쿼터패널
  HST_140202: '', //외판부위2랭크-7루프패널
  HST_140203: '', //외판부위2랭크-8사이드실패널
  HST_140301: '', //주요골격A랭크-9프론트패널
  HST_140302: '', //주요골격A랭크-10크로스멤버
  HST_140303: '', //주요골격A랭크-11인사이드패널
  HST_140304: '', //주요골격A랭크-17트렁크플로어
  HST_140305: '', //주요골격A랭크-18리어패널
  HST_140401: '', //주요골격B랭크-12사이드멤버
  HST_140402: '', //주요골격B랭크-13휠하우스
  HST_140403: '', //주요골격B랭크-14필러패널
  HST_140404: '', //주요골격B랭크-14필러패널A
  HST_140405: '', //주요골격B랭크-14필러패널B
  HST_140406: '', //주요골격B랭크-14필러패널C
  HST_140407: '', //주요골격B랭크-19패키지트레이
  HST_140501: '', //주요골격C랭크-15대쉬패널
  HST_140502: '', //주요골격C랭크-16플로어패널

  DTS_151101: '1', //자기진단-원동기
  DTS_151201: '1', //자기진단-변속기
  DTS_152101: '1', //원동기-작동상태(공회전)
  DTS_152201: '1', //원동기-오일누유-로커암커버
  DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
  DTS_152203: '1', //원동기-오일누유-오일팬
  DTS_152301: '1', //원동기-오일유량
  DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
  DTS_152402: '1', //원동기-냉각수누수-워터펌프
  DTS_152403: '1', //원동기-냉각수누수-라디에이터
  DTS_152404: '1', //원동기-냉각수누수-냉각수수량
  DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
  DTS_153101: '1', //변속기-자동변속기-오일누유
  DTS_153102: '1', //변속기-자동변속기-오일유량및상태
  DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
  DTS_153201: '', //변속기-수동변속기-오일누유
  DTS_153202: '', //변속기-수동변속기-기어변속장치
  DTS_153203: '', //변속기-수동변속기-오일유량및상태
  DTS_153204: '', //변속기-수동변속기-작동상태(공회전)
  DTS_154101: '', //동력전달-클러치어셈블리
  DTS_154201: '1', //동력전달-등속죠인트
  DTS_154301: '1', //동력전달-추진축및베어링
  DTS_154401: '1', //동력전달-디퍼렌셜기어
  DTS_155101: '1', //조향-동력조향작동오일누유
  DTS_155201: '1', //조향-작동상태-스티어링기어
  DTS_155202: '1', //조향-작동상태-스티어링펌프
  DTS_155203: '1', //조향-작동상태-타이로드엔드및볼죠인트
  DTS_155204: '1', //조향-작동상태-스티어링죠인트
  DTS_155205: '1', //조향-작동상태-파워고압호스
  DTS_156101: '1', //제동-브레이크마스터실린더오일누유
  DTS_156201: '1', //제동-브레이크오일누유
  DTS_156301: '1', //제동-배력장치상태
  DTS_157101: '1', //전기-발전기출력
  DTS_157201: '1', //전기-시동모터
  DTS_157301: '1', //전기-와이퍼모터기능
  DTS_157401: '1', //전기-실내송풍모터
  DTS_157501: '1', //전기-라디에이터팬모터
  DTS_157601: '1', //전기-윈도우모터
  DTS_158101: '1', //연료-연료누출(LP가스포함)
  DTS_159101: '', //고전원전기장치-충전구절연상태
  DTS_159201: '', //고전원전기장치-구동축전지격리상태
  DTS_159301: '', //고전원전기장치-고전원전기배선상태
}

const ACCIDENT_PARTS = {
  IMS_120001: { target: 'HST_140101', rank: '1' }, //후드
  IMS_120004: { target: 'HST_140104', rank: '1' }, //트렁크리드
  IMS_120005: { target: 'HST_140105', rank: '1' }, //라디에이터서포트
  IMS_120007: { target: 'HST_140202', rank: '2' }, //루프패널
  IMS_120009: { target: 'HST_140301', rank: 'A' }, //프론트패널
  IMS_120010: { target: 'HST_140302', rank: 'A' }, //크로스멤버
  IMS_120015: { target: 'HST_140501', rank: 'C' }, //대쉬패널
  IMS_120016: { target: 'HST_140502', rank: 'C' }, //플로어패널
  IMS_120017: { target: 'HST_140304', rank: 'A' }, //트렁크플로어
  IMS_120018: { target: 'HST_140305', rank: 'A' }, //리어패널
  IMS_120019: { target: 'HST_140407', rank: 'B' }, //패키지트레이
  IMS_121002: { target: 'HST_140102', rank: '1' }, //프론트휀더(좌)
  IMS_121006: { target: 'HST_140201', rank: '2' }, //쿼터패털(좌)
  IMS_121008: { target: 'HST_140203', rank: '2' }, //사이드실패널(좌)
  IMS_121011: { target: 'HST_140303', rank: 'A' }, //인사이드패널(좌)
  IMS_121103: { target: 'HST_140103', rank: '1' }, //도어(좌전)
  IMS_121112: { target: 'HST_140401', rank: 'B' }, //사이드멤버(좌전)
  IMS_121113: { target: 'HST_140402', rank: 'B' }, //휠하우스(좌전)
  IMS_121114: { target: 'HST_140404', rank: 'B' }, //필러패널(좌전)
  IMS_121214: { target: 'HST_140405', rank: 'B' }, //필러패널(좌중)
  IMS_121303: { target: 'HST_140103', rank: '1' }, //도어(좌후)
  IMS_121312: { target: 'HST_140401', rank: 'B' }, //사이드멤버(좌후)
  IMS_121313: { target: 'HST_140402', rank: 'B' }, //휠하우스(좌후)
  IMS_121314: { target: 'HST_140406', rank: 'B' }, //필러패널(좌후)
  IMS_122002: { target: 'HST_140102', rank: '1' }, //프론트휀더(우)
  IMS_122006: { target: 'HST_140201', rank: '2' }, //쿼터패털(우)
  IMS_122008: { target: 'HST_140203', rank: '2' }, //사이드실패널(우)
  IMS_122011: { target: 'HST_140303', rank: 'A' }, //인사이드패널(우)
  IMS_122103: { target: 'HST_140103', rank: '1' }, //도어(우전)
  IMS_122112: { target: 'HST_140401', rank: 'B' }, //사이드멤버(우전)
  IMS_122113: { target: 'HST_140402', rank: 'B' }, //휠하우스(우전)
  IMS_122114: { target: 'HST_140404', rank: 'B' }, //필러패널(우전)
  IMS_122214: { target: 'HST_140405', rank: 'B' }, //필러패널(우중)
  IMS_122303: { target: 'HST_140103', rank: '1' }, //도어(우후)
  IMS_122312: { target: 'HST_140401', rank: 'B' }, //사이드멤버(우후)
  IMS_122313: { target: 'HST_140402', rank: 'B' }, //휠하우스(우후)
  IMS_122314: { target: 'HST_140406', rank: 'B' }, //필러패널(우후)
}

const _setAccidentPointBg = (dataRaw, dataKey) => {
  const value = dataRaw?.[dataKey];

  switch (value) {
    case 'X': // 교환
      return 'rgb(204,51,0)';
    case 'W': // 판금
      return 'rgb(255,153,102)';
    case 'C': // 부식
      return 'rgb(255,204,0)';
    case 'A': // 흠집
      return 'rgb(153,204,51)';
    case 'U': // 요철
      return 'rgb(51,153,0)';
    case 'T': // 손상
      return 'rgb(41,109,169)';
    default:
      return 'transparent';
  }
};

const _setWarningTextColor = (dataRaw, dataKey, value) => {
  return dataRaw?.[dataKey] === value
    ? {color: 'rgba(204,51,0,1)', fontWeight: 'bold'}
    : {color: BASE_STYLES.FONT_COLOR, fontWeight: 'normal'};
};

const _setChecked = (dataRaw, dataKey, value) => {
  return dataRaw?.[dataKey] === value;
};

export default function PerformanceCheckReport({performanceCheckReportData}) {
  const GEAR_TYPE = {
    Automatic: {
      code: '1',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    Manual: {
      code: '2',
      defaultValues: {
        DTS_153101: '',
        DTS_153102: '',
        DTS_153103: '',
        DTS_153201: '1',
        DTS_153202: '1',
        DTS_153204: '1',
        DTS_154101: '1'
      },
      disabled: {
        DTS_153101: true, //변속기-자동변속기-오일누유
        DTS_153102: true, //변속기-자동변속기-오일유량및상태
        DTS_153103: true, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false
      }
    },
    SemiAuto: {
      code: '3',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    NoGear: {
      code: '4',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    ETC: {
      code: '5',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    fromCode: (code) => {
      let gearType = null;
      for(let key of Object.keys(GEAR_TYPE)) {
        if (GEAR_TYPE[key]['code'] === code) gearType = GEAR_TYPE[key];
      }
      return gearType;
    },
    current: () => {
      let gearType = null;
      for(let key of Object.keys(GEAR_TYPE)) {
        if (GEAR_TYPE[key]['code'] === currentData['BAS_070000']) gearType = GEAR_TYPE[key];
      }
      return gearType;
    },
  }

  const FUEL_TYPE = {
    Gasoline: {
      code: '1',
      defaultValues: {
        TOT_110401: '2', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '2', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '', //고전원전기장치-충전구절연상태
        DTS_159201: '', //고전원전기장치-구동축전지격리상태
        DTS_159301: '', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: true, //고전원전기장치-충전구절연상태
        DTS_159201: true, //고전원전기장치-구동축전지격리상태
        DTS_159301: true, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    Diesel: {
      code: '2',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '2', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '1', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '', //고전원전기장치-충전구절연상태
        DTS_159201: '', //고전원전기장치-구동축전지격리상태
        DTS_159301: '', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: false, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: true, //고전원전기장치-충전구절연상태
        DTS_159201: true, //고전원전기장치-구동축전지격리상태
        DTS_159301: true, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    LPG: {
      code: '3',
      defaultValues: {
        TOT_110401: '2', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '2', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '', //고전원전기장치-충전구절연상태
        DTS_159201: '', //고전원전기장치-구동축전지격리상태
        DTS_159301: '', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: true, //고전원전기장치-충전구절연상태
        DTS_159201: true, //고전원전기장치-구동축전지격리상태
        DTS_159301: true, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    Hybrid: {
      code: '4',
      defaultValues: {
        TOT_110401: '2', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '2', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    Electric: {
      code: '5',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '', //원동기-작동상태(공회전)
        DTS_152201: '', //원동기-오일누유-로커암커버
        DTS_152202: '', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '', //원동기-오일누유-오일팬
        DTS_152301: '', //원동기-오일유량
        DTS_152401: '', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '', //변속기-자동변속기-오일누유
        DTS_153102: '', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '', //조향-동력조향작동오일누유
        DTS_155202: '', //조향-작동상태-스티어링펌프
        DTS_157101: '', //전기-발전기출력
        DTS_157201: '', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: true, //원동기-작동상태(공회전)
        DTS_152201: true, //원동기-오일누유-로커암커버
        DTS_152202: true, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: true, //원동기-오일누유-오일팬
        DTS_152301: true, //원동기-오일유량
        DTS_152401: true, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: true, //변속기-자동변속기-오일누유
        DTS_153102: true, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: true, //조향-동력조향작동오일누유
        DTS_155202: true, //조향-작동상태-스티어링펌프
        DTS_157101: true, //전기-발전기출력
        DTS_157201: true, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: true, //연료-연료누출(LP가스포함)
      }
    },
    Hydrogen: {
      code: '6',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    ETC: {
      code: '7',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: false, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    fromCode: (code) => {
      let fuelType = null;
      for(let key of Object.keys(FUEL_TYPE)) {
        if (FUEL_TYPE[key]['code'] === code) fuelType = FUEL_TYPE[key];
      }
      return fuelType;
    },
    current: () => {
      let fuelType = null;
      for(let key of Object.keys(FUEL_TYPE)) {
        if (FUEL_TYPE[key]['code'] === currentData['BAS_080000']) fuelType = FUEL_TYPE[key];
      }
      return fuelType;
    }
  }

  const {setPerformanceModalOption} = useContext(PerformanceModalOptionContext);

  const [currentData, setCurrentData] = useState(Object.assign({}, DEFAULT_DATA, performanceCheckReportData));
  //const [currentData, setCurrentData] = useState({});
  const [stateMenuOption, setStateMenuOption] = useState({open: false, x: 0, y: 0, code: null});
  const [accidentHistoryPopupOption, setAccidentHistoryPopupOption] = useState({open: false});
  const [pcrTemporaryPopupOption, setPcrTemporaryPopupOption] = useState({open: false});

  useEffect(() => {
    setCurrentData(Object.assign({}, DEFAULT_DATA, performanceCheckReportData));
  }, []);

  const changeValue = (code, value) => {
    setCurrentData(prevState => {
      let currentState = {
        ...prevState,
        [code]: (prevState?.code === value) ? null : value,
      }

      if (code ==='BAS_070000' && prevState[code] !== value) {
        let gearType = GEAR_TYPE.fromCode(value);

        for(let key in gearType.defaultValues) {
          let enable = !(gearType.disabled[key] || FUEL_TYPE.fromCode(prevState['BAS_080000'])?.disabled[key]);
          currentState[key] = enable ? gearType.defaultValues[key] : '';
        }
      }

      if (code ==='BAS_080000' && prevState[code] !== value) {
        let fuelType = FUEL_TYPE.fromCode(value);

        for(let key in fuelType.defaultValues) {
          let enable = !(GEAR_TYPE.fromCode(prevState['BAS_070000'])?.disabled[key] || fuelType.disabled[key]);
          currentState[key] = enable ? fuelType.defaultValues[key] : '';
        }
      }

      if (ACCIDENT_PARTS && ACCIDENT_PARTS[code]) {
        // 1랭크: 교환,판금 모두 단순수리
        // 2랭크: 교환: 사고 판금: 단순수리
        // A,B,C랭크: 교환,판금 모두 사고
        let hasAccident = false;
        let hasRepair = false;
        let checked = false;

        for (let key of Object.keys(ACCIDENT_PARTS)) {
          let o = ACCIDENT_PARTS[key];

          if (o['rank'] === '1' && ['X', 'W'].includes(currentState[key])) {
            hasRepair = true;
          } else if (o['rank'] === '2' && ['X'].includes(currentState[key])) {
            hasAccident = true;
          } else if (o['rank'] === '2' && ['W'].includes(currentState[key])) {
            hasRepair = true;
          } else if (['A','B','C'].includes(o['rank']) && ['X', 'W'].includes(currentState[key])) {
            hasAccident = true;
          }

          if (o['target'] === ACCIDENT_PARTS[code]['target'] && ['X', 'W', 'C'].includes(currentState[key])) {
            checked = true;
          }
        }

        currentState[ACCIDENT_PARTS[code]['target']] = checked ? '2' : ''
        currentState['HST_12A001'] = hasAccident ? '2' : '1'
        currentState['HST_12A002'] = hasRepair ? '2' : '1'
      }

      return currentState;
    });
  }

  const changeControlEnable = (code) => {
    return GEAR_TYPE.current()?.disabled[code]
      || FUEL_TYPE.current()?.disabled[code];
  }

  const popStateMenu = (e, code) => {
    let bodyRect = document.body.getBoundingClientRect();
    let rect = document.querySelector(".performance-check-record").getBoundingClientRect();
    let x = e.clientX - (bodyRect.right - rect.right) + 40;
    let y = e.clientY - (rect.top) + 30;

    setStateMenuOption(prevState => {
      return {
        ...prevState,
        open: true,
        code: code,
        x: x,
        y: y
      }
    });
  }

  useEffect(() => {
    setPerformanceModalOption(prevState => {
      return {
        ...prevState,
        data: currentData
      }
    });
  }, [currentData])


  const repairHistoryPopupOpen = useCallback(() => {
    setAccidentHistoryPopupOption({open: true});
  });

  const repairHistoryPopupClose = useCallback(() => {
    setAccidentHistoryPopupOption({open: false});
  });

  const pcrTemporaryPopupOpen = useCallback(() => {
    setPcrTemporaryPopupOption({open: true});
  });

  const pcrTemporaryPopupClose = useCallback(() => {
    setPcrTemporaryPopupOption({open: false});
  });

  return (
    <>
      <Table className={'performance-check-record'} compact celled
             style={{fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
        <colgroup>
          <col width="150px" />
          <col />
          <col width="150px" />
          <col  />
        </colgroup>

        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={4} style={{textAlign: 'center'}}>
              <Header>
                <span style={{float:'left'}}>중고자동차 성능ㆍ상태점검기록부</span>
                <span style={{float:'right'}}>예약번호: {currentData?.BAS_000002}</span>
              </Header>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} colSpan={4} style={{
              textAlign: 'center',
              borderBottom: '2px double rgba(34,36,38,.1)',
            }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 기본정보</h5>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>① 차명</Table.Cell>
            <Table.Cell>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <span>{currentData?.BAS_010000}</span>
                <span>(세부모델 : {currentData?.BAS_010100 || '\u00A0\u00A0\u00A0\u00A0'})</span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'}>② 자동차등록번호</Table.Cell>
            <Table.Cell>
              {currentData?.BAS_020000}
              <Button basic
                      floated={'right'}
                      size={'mini'}
                      color={'orange'}
                      icon={'search'}
                      content={'사고이력'}
                      onClick={repairHistoryPopupOpen}/>
              <Button basic
                      floated={'right'}
                      size={'mini'}
                      color={'orange'}
                      icon={'search'}
                      content={'정비소차량점검데이터'}
                      onClick={pcrTemporaryPopupOpen}/>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>③ 연식</Table.Cell>
            <Table.Cell>{currentData?.BAS_030000}</Table.Cell>
            <Table.Cell className={'title'}>④ 검사유효기간</Table.Cell>
            <Table.Cell>
              <Input type={'date'}
                     defaultValue={moment(currentData?.BAS_040100).format("YYYY-MM-DD")}
                     size={'small'}
                     input={{style: {padding:'5px'}}}
                     onChange={(event, {value}) => changeValue('BAS_040100', moment(value).format("YYYYMMDD"))}/>
              &nbsp;~&nbsp;
              <Input type={'date'}
                     defaultValue={moment(currentData?.BAS_040200).format("YYYY-MM-DD")}
                     size={'small'}
                     input={{style: {padding:'5px'}}}
                     onChange={(event, {value}) => changeValue('BAS_040200', moment(value).format("YYYYMMDD"))}/>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑤ 최초등록일</Table.Cell>
            <Table.Cell>
              <Input type={'date'}
                     defaultValue={moment(currentData?.BAS_050000).format("YYYY-MM-DD")}
                     size={'small'}
                     input={{style: {padding:'5px'}}}
                     onChange={(event, {value}) => changeValue('BAS_050000', moment(value).format("YYYYMMDD"))}/>
            </Table.Cell>
            <Table.Cell className={'title'} rowSpan={2}>⑦ 변속기종류</Table.Cell>
            <Table.Cell rowSpan={2}>
              {/*BAS_070000*/}
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                  <span>
                    <Checkbox label={'자동'}
                              checked={_setChecked(currentData, 'BAS_070000', '1')}
                            disabled={changeControlEnable('BAS_070000')}
                              onChange={() => changeValue('BAS_070000', '1')} />
                  </span>
                  <span>
                    <Checkbox label={'수동'}
                              checked={_setChecked(currentData, 'BAS_070000', '2')}
                            disabled={changeControlEnable('BAS_070000')}
                              onChange={() => changeValue('BAS_070000', '2')} />
                  </span>
                  <span>
                    <Checkbox label={'세미오토'}
                              checked={_setChecked(currentData, 'BAS_070000', '3')}
                            disabled={changeControlEnable('BAS_070000')}
                              onChange={() => changeValue('BAS_070000', '3')} />
                  </span>
                </div>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                  <span>
                    <Checkbox label={'무단변속기'}
                              checked={_setChecked(currentData, 'BAS_070000', '4')}
                            disabled={changeControlEnable('BAS_070000')}
                              onChange={() => changeValue('BAS_070000', '4')} />
                  </span>
                  <span>
                    <Checkbox label={`기타`}
                              checked={_setChecked(currentData, 'BAS_070000', '5')}
                            disabled={changeControlEnable('BAS_070000')}
                              onChange={() => changeValue('BAS_070000', '5')} />

                    <Input defaultValue={currentData?.BAS_070100}
                           size={'small'}
                           style={{width:'120px', margin:'0 5px'}}
                           input={{style: {padding:'5px'}}}
                           onChange={(event, {value}) => changeValue('BAS_070100', value)}/>
                  </span>
                </div>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑥ 차대번호</Table.Cell>
            <Table.Cell>
              <Input defaultValue={currentData?.BAS_060000}
                     size={'small'}
                     input={{style: {padding:'5px'}}}
                     onChange={(event, {value}) => changeValue('BAS_060000', value)}/>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑧ 사용연료</Table.Cell>
            <Table.Cell colSpan={3}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <Checkbox label={'가솔린'}
                          checked={_setChecked(currentData, 'BAS_080000', '1')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '1')} />

                <Checkbox label={'디젤'}
                          checked={_setChecked(currentData, 'BAS_080000', '2')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '2')} />

                <Checkbox label={'LPG'}
                          checked={_setChecked(currentData, 'BAS_080000', '3')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '3')} />

                <Checkbox label={'하이브리드'}
                          checked={_setChecked(currentData, 'BAS_080000', '4')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '4')} />

                <Checkbox label={'전기'}
                          checked={_setChecked(currentData, 'BAS_080000', '5')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '5')} />

                <Checkbox label={'수소전기'}
                          checked={_setChecked(currentData, 'BAS_080000', '6')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '6')} />

                <Checkbox label={'기타'}
                          checked={_setChecked(currentData, 'BAS_080000', '7')}
                            disabled={changeControlEnable('BAS_080000')}
                          onChange={() => changeValue('BAS_080000', '7')} />
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑨ 원동기형식</Table.Cell>
            <Table.Cell>
              <Input defaultValue={currentData?.BAS_090000}
                     size={'small'}
                     style={{width:'120px'}}
                     input={{style: {padding:'5px'}}}
                     onChange={(event, {value}) => changeValue('BAS_090000', value)}/>
            </Table.Cell>
            <Table.Cell className={'title'}>⑩ 보증유형</Table.Cell> {/* TODO - 없음 */}
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'자가보증'} disabled /></span>
                <span><Checkbox label={'보험사보증'} disabled /></span>
              </div>
            </Table.Cell>
            {/*<Table.Cell className={'title'}>가격산정 기준가격</Table.Cell>*/}
            {/*<Table.Cell className={'title'} colSpan="2">*/}
            {/*  <div style={{*/}
            {/*    flex: 1,*/}
            {/*    display: 'flex',*/}
            {/*    flexDirection: 'row',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'space-between',*/}
            {/*  }}>*/}
            {/*    <span style={{flex: 1, border: '1px dotted'}}>&nbsp;</span>*/}
            {/*    <span style={{*/}
            {/*      flex: 1,*/}
            {/*      borderTop: '1px dotted',*/}
            {/*      borderBottom: '1px dotted',*/}
            {/*      borderRight: '1px dotted',*/}
            {/*      alignSelf: 'stretch',*/}
            {/*    }}>&nbsp;</span>*/}
            {/*    <span style={{*/}
            {/*      flex: 1,*/}
            {/*      borderTop: '1px dotted',*/}
            {/*      borderBottom: '1px dotted',*/}
            {/*      borderRight: '1px dotted',*/}
            {/*    }}>&nbsp;</span>*/}
            {/*    <span style={{*/}
            {/*      flex: 1,*/}
            {/*      borderTop: '1px dotted',*/}
            {/*      borderBottom: '1px dotted',*/}
            {/*      borderRight: '1px dotted',*/}
            {/*    }}>&nbsp;</span>*/}
            {/*    <span style={{*/}
            {/*      flex: 1,*/}
            {/*      borderTop: '1px dotted',*/}
            {/*      borderBottom: '1px dotted',*/}
            {/*      borderRight: '1px dotted',*/}
            {/*    }}>&nbsp;</span>*/}
            {/*    <span>&nbsp;&nbsp;만원</span>*/}
            {/*  </div>*/}
            {/*</Table.Cell>*/}
          </Table.Row>
        </Table.Body>
      </Table>

      <Table className={'performance-check-record'} compact celled
             style={{fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
        <colgroup>
          <col width="150px" />
          <col />
          <col width="150px" />
          <col />
        </colgroup>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={'title'} colSpan={4} style={{
              textAlign: 'center',
              borderBottom: '2px double rgba(34,36,38,.1)',
            }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 종합상태</h5>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑪ 사용이력</Table.Cell>
            <Table.Cell className={'title'}>상태</Table.Cell>
            <Table.Cell className={'title'} colSpan={2}>항목 / 해당부품</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={2}>주행거리 및 계기상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                            checked={_setChecked(currentData, 'TOT_110101', '1')}
                            disabled={changeControlEnable('TOT_110101')}
                            onChange={() => changeValue('TOT_110101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'TOT_110101', '2')}>
                  <Checkbox label={'불량'}
                            checked={_setChecked(currentData, 'TOT_110101', '2')}
                            disabled={changeControlEnable('TOT_110101')}
                            onChange={() => changeValue('TOT_110101', '2')} />
                </span>
              </div>
            </Table.Cell>
            <Table.Cell rowSpan={2} colSpan={2}>
              현재 주행거리 &nbsp;
              <Input defaultValue={CommonUtil.Unit.format(currentData?.TOT_110202)}
                     size={'mini'}
                     style={{margin:'0 5px', width: '120px', fontSize:'1rem'}}
                     input={{style: {padding:'5px', textAlign: 'right'}}}
                     label={<div className="ui label label" style={{padding:'10px', fontSize:'.8rem'}}>KM</div>}
                     labelPosition={'right'}
                     onChange={(event, {value}) => {
                       event.target.value = CommonUtil.Unit.format(value);
                       changeValue('TOT_110202', CommonUtil.Unit.removeComma(value));
                     }}/>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell cstyle={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'많음'}
                            checked={_setChecked(currentData, 'TOT_110201', '1')}
                            disabled={changeControlEnable('TOT_110201')}
                            onChange={() => changeValue('TOT_110201', '1')} />
                </span>
                <span>
                  <Checkbox label={'보통'}
                          checked={_setChecked(currentData, 'TOT_110201', '2')}
                            disabled={changeControlEnable('TOT_110201')}
                          onChange={() => changeValue('TOT_110201', '2')} />
                </span>
                <span>
                  <Checkbox label={'적음'}
                          checked={_setChecked(currentData, 'TOT_110201', '3')}
                            disabled={changeControlEnable('TOT_110201')}
                          onChange={() => changeValue('TOT_110201', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>차대번호표기</Table.Cell>
            <Table.Cell colSpan={3}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                 <Checkbox label={'양호'}
                           checked={_setChecked(currentData, 'TOT_110301', '1')}
                            disabled={changeControlEnable('TOT_110301')}
                           onChange={() => changeValue('TOT_110301', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'TOT_110301', '2')}>
                  <Checkbox label={'부식'}
                            checked={_setChecked(currentData, 'TOT_110301', '2')}
                            disabled={changeControlEnable('TOT_110301')}
                            onChange={() => changeValue('TOT_110301', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'TOT_110301', '3')}>
                  <Checkbox label={'훼손(오손)'}
                            checked={_setChecked(currentData, 'TOT_110301', '3')}
                            disabled={changeControlEnable('TOT_110301')}
                            onChange={() => changeValue('TOT_110301', '3')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'TOT_110301', '4')}>
                  <Checkbox label={'상이'}
                            checked={_setChecked(currentData, 'TOT_110301', '4')}
                            disabled={changeControlEnable('TOT_110301')}
                            onChange={() => changeValue('TOT_110301', '4')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'TOT_110301', '5')}>
                  <Checkbox label={'변조(변타)'}
                            checked={_setChecked(currentData, 'TOT_110301', '5')}
                            disabled={changeControlEnable('TOT_110301')}
                            onChange={() => changeValue('TOT_110301', '5')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'TOT_110301', '6')}>
                  <Checkbox label={'도말'}
                            checked={_setChecked(currentData, 'TOT_110301', '6')}
                            disabled={changeControlEnable('TOT_110301')}
                            onChange={() => changeValue('TOT_110301', '6')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>배출가스</Table.Cell>
            <Table.Cell colSpan={3}>
              <Grid>
                <Grid.Column width={5}>
                  <Checkbox label={'일산화탄소'}
                            checked={_setChecked(currentData, 'TOT_110401', '2')}
                            disabled={changeControlEnable('TOT_110401')}
                            onChange={() => changeValue('TOT_110401', '2')} />
                  <Input defaultValue={currentData?.TOT_110402}
                         size={'mini'}
                         style={{margin:'0 5px', width: '80px', fontSize:'1rem'}}
                         input={{maxLength: 5, style: {padding:'5px', textAlign: 'right'}}}
                         label={<div className="ui label label" style={{padding:'10px', fontSize:'.8rem'}}>%</div>}
                         labelPosition={'right'}
                         onChange={(event, {value}) => changeValue('TOT_110402', value)}/>
                </Grid.Column>

                <Grid.Column width={5}>
                  <Checkbox label={'탄화수소'}
                            checked={_setChecked(currentData, 'TOT_110411', '2')}
                            disabled={changeControlEnable('TOT_110411')}
                            onChange={() => changeValue('TOT_110411', '2')} />
                  <Input defaultValue={currentData?.TOT_110412}
                         size={'mini'}
                         style={{margin:'0 5px', width: '80px', fontSize:'1rem'}}
                         input={{maxLength: 5, style: {padding:'5px', textAlign: 'right'}}}
                         label={<div className="ui label label" style={{padding:'10px', fontSize:'.7rem'}}>PPM</div>}
                         labelPosition={'right'}
                         onChange={(event, {value}) => changeValue('TOT_110412', value)}/>
                </Grid.Column>

                <Grid.Column width={5}>
                  <Checkbox label={'매연'}
                            checked={_setChecked(currentData, 'TOT_110421', '2')}
                            disabled={changeControlEnable('TOT_110421')}
                            onChange={() => changeValue('TOT_110421', '2')} />
                  <Input defaultValue={currentData?.TOT_110422}
                         size={'mini'}
                         style={{margin:'0 5px', width: '80px', fontSize:'1rem'}}
                         input={{maxLength: 5, style: {padding:'5px', textAlign: 'right'}}}
                         label={<div className="ui label label" style={{padding:'10px', fontSize:'.8rem'}}>%</div>}
                         labelPosition={'right'}
                         onChange={(event, {value}) => changeValue('TOT_110422', value)}/>
                </Grid.Column>
              </Grid>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>튜닝</Table.Cell>
            <Table.Cell>
              <Grid columns={2}>
                <Grid.Column width={8}>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                <span>
                  <Checkbox label={'없음'}
                            checked={_setChecked(currentData, 'TOT_110501', '1')}
                            disabled={changeControlEnable('TOT_110501')}
                            onChange={() => changeValue('TOT_110501', '1')} />
                </span>
                    <span>
                  <Checkbox label={'있음'}
                            checked={_setChecked(currentData, 'TOT_110501', '2')}
                            disabled={changeControlEnable('TOT_110501')}
                            onChange={() => changeValue('TOT_110501', '2')} />
                </span>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                <span>
                  <Checkbox label={'적법'}
                            checked={_setChecked(currentData, 'TOT_110511', '1')}
                            disabled={changeControlEnable('TOT_110511')}
                            onChange={() => changeValue('TOT_110511', '1')} />
                </span>
                    <span>
                  <Checkbox label={'불법'}
                            checked={_setChecked(currentData, 'TOT_110511', '2')}
                            disabled={changeControlEnable('TOT_110511')}
                            onChange={() => changeValue('TOT_110511', '2')} />
                </span>
                  </div>
                </Grid.Column>
              </Grid>

            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'구조'}
                            checked={_setChecked(currentData, 'TOT_110521', '2')}
                            disabled={changeControlEnable('TOT_110521')}
                            onChange={() => changeValue('TOT_110521', '2')} />
                </span>
                <span>
                  <Checkbox label={'장치'}
                            checked={_setChecked(currentData, 'TOT_110522', '2')}
                            disabled={changeControlEnable('TOT_110522')}
                            onChange={() => changeValue('TOT_110522', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>특별이력</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                            checked={_setChecked(currentData, 'TOT_110601', '1')}
                            disabled={changeControlEnable('TOT_110601')}
                            onChange={() => changeValue('TOT_110601', '1')} />
                </span>
                <span>
                  <Checkbox label={'있음'}
                          checked={_setChecked(currentData, 'TOT_110601', '2')}
                            disabled={changeControlEnable('TOT_110601')}
                          onChange={() => changeValue('TOT_110601', '2')} />
                </span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'침수'}
                            checked={_setChecked(currentData, 'TOT_110611', '2')}
                            disabled={changeControlEnable('TOT_110611')}
                            onChange={() => changeValue('TOT_110611', '2')} />
                </span>
                <span>
                  <Checkbox label={'화재'}
                            checked={_setChecked(currentData, 'TOT_110612', '2')}
                            disabled={changeControlEnable('TOT_110612')}
                            onChange={() => changeValue('TOT_110612', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>용도변경</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                            checked={_setChecked(currentData, 'TOT_110701', '1')}
                            disabled={changeControlEnable('TOT_110701')}
                            onChange={() => changeValue('TOT_110701', '1')} />
                </span>
                <span>
                  <Checkbox label={'있음'}
                          checked={_setChecked(currentData, 'TOT_110701', '2')}
                            disabled={changeControlEnable('TOT_110701')}
                          onChange={() => changeValue('TOT_110701', '2')} />
                </span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'렌트'}
                            checked={_setChecked(currentData, 'TOT_110711', '2')}
                            disabled={changeControlEnable('TOT_110711')}
                            onChange={() => changeValue('TOT_110711', '2')} />
                </span>
                <span>
                  <Checkbox label={'영업용'}
                            checked={_setChecked(currentData, 'TOT_110713', '2')}
                            disabled={changeControlEnable('TOT_110713')}
                            onChange={() => changeValue('TOT_110713', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>색상</Table.Cell>
            <Table.Cell className={'title'}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'무채색'} disabled /></span>
                <span><Checkbox label={'유채색'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan={2} className={'title'}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'전체도색'} disabled />
                </span>
                <span>
                  <Checkbox label={'색상변경'} disabled />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>주요옵션</Table.Cell>
            <Table.Cell className={'title'}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'없음'} disabled /></span>
                <span><Checkbox label={'있음'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'} colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'썬루프'} disabled /></span>
                <span><Checkbox label={'네비게이션'} disabled /></span>
                <span><Checkbox label={'기타'} disabled /></span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>리콜대상</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'해당없음'}
                            checked={_setChecked(currentData, 'TOT_110801', '1')}
                            disabled={changeControlEnable('TOT_110801')}
                            onChange={() => changeValue('TOT_110801', '1')} />
                </span>
                <span>
                  <Checkbox label={'해당'}
                            checked={_setChecked(currentData, 'TOT_110801', '2')}
                            disabled={changeControlEnable('TOT_110801')}
                            onChange={() => changeValue('TOT_110801', '2')} />
                </span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'}>리콜이행</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'이행'}
                          checked={_setChecked(currentData, 'TOT_110811', '1')}
                            disabled={changeControlEnable('TOT_110811')}
                          onChange={() => changeValue('TOT_110811', '1')} />
                </span>
                <span>
                  <Checkbox label={'미이행'}
                          checked={_setChecked(currentData, 'TOT_110811', '2')}
                            disabled={changeControlEnable('TOT_110811')}
                          onChange={() => changeValue('TOT_110811', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table className={'performance-check-record'} compact celled
             style={{fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
        <colgroup>
          <col width="150px" />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={'title'} colSpan={5}
                        style={{
                          textAlign: 'center',
                          borderBottom: '2px double rgba(34,36,38,.1)',
                        }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>사고 교환 수리 등 이력</h5>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={5}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                ※ 상태표시 부호&nbsp;:&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgb(204,51,0)'}}>X</Label> (교환),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgb(255,153,102)'}}>W</Label> (판금 또는 용접),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgb(255,204,0)'}}>C</Label> (부식),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgb(153,204,51)'}}>A</Label> (흠집),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgb(51,153,0)'}}>U</Label> (요철),&nbsp;
                <Label circular size={'mini'}
                       style={{color: '#ffffff', backgroundColor: 'rgb(41,109,169)'}}>T</Label> (손상)
              </div>
              ※ 하단 항목은 승용차 기준이며, 기타 자동차는 승용차에 준하여 표시
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={5} style={{height: 280, borderBottom: 0}}>
              <div className="elem" style={{height: 280, marginTop: 29}}>
                <div style={{
                  height:'100%',
                  overflow: 'hidden',
                  textAlign: 'center',
                  background: `url(${BluePrint})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center center',
                }} className={'container'}>
                  <div style={{
                      position: 'relative',
                      margin: '0 auto',
                      width:'727px',
                      height:'100%',
                  }}>
                    {/*<img src={BluePrint} style={{opacity: 0.6, marginTop: 0, }} alt={'차량이미지'} />*/}
                    <Popup
                      size={'tiny'}
                      content="1. 후드"
                      trigger={
                        <Label circular
                               size={'mini'}
                               title="1.후드"
                               style={{
                                position: 'absolute',
                                top: (49 - 13),
                                left: (258 - 8),
                                color: '#ffffff',
                                backgroundColor: _setAccidentPointBg(currentData, 'IMS_120001'),
                                cursor: 'pointer',
                               }}
                               onClick={(event) => popStateMenu(event, 'IMS_120001')}>
                          {currentData?.IMS_120001}
                        </Label>
                      }
                      />
                    <Popup
                      size={'tiny'}
                      content="2.프론트휀더좌"
                      trigger={
                        <Label circular size={'mini'} title="2.프론트휀더좌" style={{
                          position: 'absolute',
                          top: (41 - 13),
                          left: (61 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121002'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121002')}>
                        {currentData?.IMS_121002}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="2.프론트휀더우"
                      trigger={
                        <Label circular size={'mini'} title="2.프론트휀더우" style={{
                          position: 'absolute',
                          top: (45 - 13),
                          left: (654 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122002'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122002')}>
                        {currentData?.IMS_122002}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="3.도어전좌"
                      trigger={
                        <Label circular size={'mini'} title="3.도어전좌" style={{
                          position: 'absolute',
                          top: (125 - 13),
                          left: (60 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121103'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121103')}>
                        {currentData?.IMS_121103}</Label>}
                    />
                    <Popup
                      size={'tiny'}
                      content="3.도어후좌"
                      trigger={
                        <Label circular size={'mini'} title="3.도어후좌" style={{
                          position: 'absolute',
                          top: (177 - 13),
                          left: (60 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121303'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121303')}>
                        {currentData?.IMS_121303}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="3.도어전우"
                      trigger={
                        <Label circular size={'mini'} title="3.도어전우" style={{
                          position: 'absolute',
                          top: (124 - 13),
                          left: (655 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122103'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122103')}>
                        {currentData?.IMS_122103}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="3.도어후우"
                      trigger={
                        <Label circular size={'mini'} title="3.도어후우" style={{
                          position: 'absolute',
                          top: (176 - 13),
                          left: (654 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122303'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122303')}>
                        {currentData?.IMS_122303}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="4.트렁크리드"
                      trigger={
                        <Label circular size={'mini'} title="4.트렁크리드" style={{
                          position: 'absolute',
                          top: (242 - 13),
                          left: (257 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120004'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120004')}>
                        {currentData?.IMS_120004}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="5.라디에이터 서포트"
                      trigger={
                        <Label circular size={'mini'} title="5.라디에이터 서포트" style={{
                          position: 'absolute',
                          top: (15 - 13),
                          left: (467 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120005'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120005')}>
                        {currentData?.IMS_120005}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="6.쿼터패널좌"
                      trigger={
                        <Label circular size={'mini'} title="6.쿼터패널좌" style={{
                          position: 'absolute',
                          top: (250 - 13),
                          left: (62 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121006'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121006')}>
                        {currentData?.IMS_121006}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="6.쿼터패널우"
                      trigger={
                        <Label circular size={'mini'} title="6.쿼터패널우" style={{
                          position: 'absolute',
                          top: (247 - 13),
                          left: (654 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122006'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122006')}>
                        {currentData?.IMS_122006}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="7.루프패널"
                      trigger={
                        <Label circular size={'mini'} title="7.루프패널" style={{
                          position: 'absolute',
                          top: (153 - 13),
                          left: (258 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120007'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120007')}>
                        {currentData?.IMS_120007}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="8.사이드실패널좌"
                      trigger={
                        <Label circular size={'mini'} title="8.사이드실패널좌" style={{
                          position: 'absolute',
                          top: (152 - 13),
                          left: (26 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121008'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121008')}>
                        {currentData?.IMS_121008}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="8.사이드실패널우"
                      trigger={
                        <Label circular size={'mini'} title="8.사이드실패널우" style={{
                          position: 'absolute',
                          top: (152 - 13),
                          left: (694 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122008'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122008')}>
                        {currentData?.IMS_122008}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="9.프론트패널"
                      trigger={
                        <Label circular size={'mini'} title="9.프론트패널" style={{
                          position: 'absolute',
                          top: (33 - 13),
                          left: (468 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120009'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120009')}>
                        {currentData?.IMS_120009}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="10.크로스멤버"
                      trigger={
                        <Label circular size={'mini'} title="10.크로스멤버" style={{
                          position: 'absolute',
                          top: (95 - 13),
                          left: (466 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120010'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120010')}>
                        {currentData?.IMS_120010}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="11.인사이드패널좌"
                      trigger={
                        <Label circular size={'mini'} title="11.인사이드패널좌" style={{
                          position: 'absolute',
                          top: (57 - 13),
                          left: (422 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121011'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121011')}>
                        {currentData?.IMS_121011}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="11.인사이드패널우"
                      trigger={
                        <Label circular size={'mini'} title="11.인사이드패널우" style={{
                          position: 'absolute',
                          top: (57 - 13),
                          left: (510 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122011'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122011')}>
                        {currentData?.IMS_122011}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="12.사이드멤버전좌"
                      trigger={
                        <Label circular size={'mini'} title="12.사이드멤버전좌" style={{
                          position: 'absolute',
                          top: (75 - 13),
                          left: (441 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121112'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121112')}>
                        {currentData?.IMS_121112}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="12.사이드멤버후좌"
                      trigger={
                        <Label circular size={'mini'} title="12.사이드멤버후좌" style={{
                          position: 'absolute',
                          top: (247 - 13),
                          left: (424 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121312'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121312')}>
                        {currentData?.IMS_121312}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="12.사이드멤버전우"
                      trigger={
                        <Label circular size={'mini'} title="12.사이드멤버전우" style={{
                          position: 'absolute',
                          top: (75 - 13),
                          left: (492 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122112'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122112')}>
                        {currentData?.IMS_122112}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="12.사이드멤버후우"
                      trigger={
                        <Label circular size={'mini'} title="12.사이드멤버후우" style={{
                          position: 'absolute',
                          top: (247 - 13),
                          left: (507 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122312'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122312')}>
                        {currentData?.IMS_122312}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="13.휠하우스전좌"
                      trigger={
                        <Label circular size={'mini'} title="13.휠하우스전좌" style={{
                          position: 'absolute',
                          top: (81 - 13),
                          left: (422 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121113'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121113')}>
                        {currentData?.IMS_121113}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="13.휠하우스후좌"
                      trigger={
                        <Label circular size={'mini'} title="13.휠하우스후좌" style={{
                          position: 'absolute',
                          top: (220 - 13),
                          left: (420 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121313'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121313')}>
                        {currentData?.IMS_121313}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="13.휠하우스전우"
                      trigger={
                        <Label circular size={'mini'} title="13.휠하우스전우" style={{
                          position: 'absolute',
                          top: (81 - 13),
                          left: (510 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122113'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122113')}>
                        {currentData?.IMS_122113}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="13.휠하우스후우"
                      trigger={
                        <Label circular size={'mini'} title="13.휠하우스후우" style={{
                          position: 'absolute',
                          top: (220 - 13),
                          left: (510 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122313'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122313')}>
                        {currentData?.IMS_122313}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="14.필러패널A좌"
                      trigger={
                        <Label circular size={'mini'} title="14.필러패널A좌" style={{
                          position: 'absolute',
                          top: (115 - 13),
                          left: (115 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121114'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121114')}>
                        {currentData?.IMS_121114}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="14.필러패널B좌"
                      trigger={
                        <Label circular size={'mini'} title="14.필러패널B좌" style={{
                          position: 'absolute',
                          top: (156 - 13),
                          left: (114 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121214'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121214')}>
                        {currentData?.IMS_121214}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="14.필러패널C좌"
                      trigger={
                        <Label circular size={'mini'} title="14.필러패널C좌" style={{
                          position: 'absolute',
                          top: (200 - 13),
                          left: (120 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_121314'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_121314')}>
                        {currentData?.IMS_121314}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="14.필러패널A우"
                      trigger={
                        <Label circular size={'mini'} title="14.필러패널A우" style={{
                          position: 'absolute',
                          top: (114 - 13),
                          left: (607 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122114'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122114')}>
                        {currentData?.IMS_122114}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="14.필러패널B우"
                      trigger={
                        <Label circular size={'mini'} title="14.필러패널B우" style={{
                          position: 'absolute',
                          top: (157 - 13),
                          left: (603 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122214'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122214')}>
                        {currentData?.IMS_122214}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="14.필러패널C우"
                      trigger={
                        <Label circular size={'mini'} title="14.필러패널C우" style={{
                          position: 'absolute',
                          top: (199 - 13),
                          left: (601 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_122314'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_122314')}>
                        {currentData?.IMS_122314}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="15.대쉬패널"
                      trigger={
                        <Label circular size={'mini'} title="15.대쉬패널" style={{
                          position: 'absolute',
                          top: (113 - 13),
                          left: (466 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120015'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120015')}>
                        {currentData?.IMS_120015}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="16.플로워패널"
                      trigger={
                        <Label circular size={'mini'} title="16.플로워패널" style={{
                          position: 'absolute',
                          top: (140 - 13),
                          left: (466 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120016'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120016')}>
                        {currentData?.IMS_120016}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="17.트렁크플로워"
                      trigger={
                        <Label circular size={'mini'} title="17.트렁크플로워" style={{
                          position: 'absolute',
                          top: (245 - 13),
                          left: (466 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120017'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120017')}>
                        {currentData?.IMS_120017}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="18.리어패널"
                      trigger={
                        <Label circular size={'mini'} title="18.리어패널" style={{
                          position: 'absolute',
                          top: (273 - 13),
                          left: (466 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120018'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120018')}>
                        {currentData?.IMS_120018}</Label>
                      } />
                    <Popup
                      size={'tiny'}
                      content="19.패키지트레이"
                      trigger={
                        <Label circular size={'mini'} title="19.패키지트레이" style={{
                          position: 'absolute',
                          top: (213 - 13),
                          left: (466 - 8),
                          color: '#ffffff',
                          backgroundColor: _setAccidentPointBg(currentData, 'IMS_120019'),
                          cursor: 'pointer',
                        }}
                        onClick={(event) => popStateMenu(event, 'IMS_120019')}>
                        {currentData?.IMS_120019}</Label>
                      } />
                  </div>
                </div>
              </div>
              <div style={{height: 30}}>&nbsp;</div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} colSpan={2}>⑫ 사고이력<span>(유의사항 4 참조)</span></Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                            checked={_setChecked(currentData, 'HST_12A001', '1')}
                            disabled={changeControlEnable('HST_12A001')}
                            onChange={() => changeValue('HST_12A001', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'HST_12A001', '2')}>
                  <Checkbox label={'있음'}
                            checked={_setChecked(currentData, 'HST_12A001', '2')}
                            disabled={changeControlEnable('HST_12A001')}
                            onChange={() => changeValue('HST_12A001', '2')} />
                </span>
              </div>
            </Table.Cell>
            <Table.Cell className={'title'}>단순수리</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                            checked={_setChecked(currentData, 'HST_12A002', '1')}
                            disabled={changeControlEnable('HST_12A002')}
                            onChange={() => changeValue('HST_12A002', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'HST_12A002', '2')}>
                  <Checkbox label={'있음'}
                            checked={_setChecked(currentData, 'HST_12A002', '2')}
                            disabled={changeControlEnable('HST_12A002')}
                            onChange={() => changeValue('HST_12A002', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} colSpan={5}>⑬ 교환,판금 등 이상 부위</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={3}>외판부위</Table.Cell>
            <Table.Cell rowSpan={2}>1랭크</Table.Cell>
            <Table.Cell colSpan={3} style={{borderBottom: 0}}>
              <span style={_setWarningTextColor(currentData, 'HST_140101', '2')}>
                <Checkbox label={'1. 후드'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140101', '2')}
                            disabled={changeControlEnable('HST_140101')}
                          onChange={() => changeValue('HST_140101', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140102', '2')}>
                <Checkbox label={'2. 프론트휀더'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140102', '2')}
                            disabled={changeControlEnable('HST_140102')}
                          onChange={() => changeValue('HST_140102', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140103', '2')}>
                <Checkbox label={'3. 도어'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140103', '2')}
                            disabled={changeControlEnable('HST_140103')}
                          onChange={() => changeValue('HST_140103', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140104', '2')}>
                <Checkbox label={'4. 트렁크 리드'}
                          checked={_setChecked(currentData, 'HST_140104', '2')}
                            disabled={changeControlEnable('HST_140104')}
                          onChange={() => changeValue('HST_140104', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={3} style={{borderTop: 0, borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <span style={_setWarningTextColor(currentData, 'HST_140105', '2')}>
                <Checkbox label={'5. 라디에이터서포트(볼트체결부품)'}
                          checked={_setChecked(currentData, 'HST_140105', '2')}
                            disabled={changeControlEnable('HST_140105')}
                          onChange={() => changeValue('HST_140105', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>2랭크</Table.Cell>
            <Table.Cell colSpan={3}>
              <span style={_setWarningTextColor(currentData, 'HST_140201', '2')}>
                <Checkbox label={'6. 쿼터패널(리어휀더)'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140201', '2')}
                            disabled={changeControlEnable('HST_140201')}
                          onChange={() => changeValue('HST_140201', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140202', '2')}>
                <Checkbox label={'7. 루프패널'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140202', '2')}
                            disabled={changeControlEnable('HST_140202')}
                          onChange={() => changeValue('HST_140202', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140203', '2')}>
                <Checkbox label={'8. 사이드실패널'}
                          checked={_setChecked(currentData, 'HST_140203', '2')}
                            disabled={changeControlEnable('HST_140203')}
                          onChange={() => changeValue('HST_140203', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={5}>주요골격</Table.Cell>
            <Table.Cell rowSpan={2}>A랭크</Table.Cell>
            <Table.Cell colSpan={3} style={{borderBottom: 0}}>
              <span style={_setWarningTextColor(currentData, 'HST_140301', '2')}>
                <Checkbox label={'9. 프론트패널'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140301', '2')}
                            disabled={changeControlEnable('HST_140301')}
                          onChange={() => changeValue('HST_140301', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140302', '2')}>
                <Checkbox label={'10. 크로스멤버'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140302', '2')}
                            disabled={changeControlEnable('HST_140302')}
                          onChange={() => changeValue('HST_140302', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140303', '2')}>
                <Checkbox label={'11. 인사이드패널'}
                          checked={_setChecked(currentData, 'HST_140303', '2')}
                            disabled={changeControlEnable('HST_140303')}
                          onChange={() => changeValue('HST_140303', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={3} style={{borderTop: 0, borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <span style={_setWarningTextColor(currentData, 'HST_140304', '2')}>
                <Checkbox label={'17. 트렁크플로워'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140304', '2')}
                            disabled={changeControlEnable('HST_140304')}
                          onChange={() => changeValue('HST_140304', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140305', '2')}>
                <Checkbox label={'18. 리어패널'}
                          checked={_setChecked(currentData, 'HST_140305', '2')}
                            disabled={changeControlEnable('HST_140305')}
                          onChange={() => changeValue('HST_140305', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>B랭크</Table.Cell>
            <Table.Cell colSpan={3} style={{borderBottom: 0}}>
              <span style={_setWarningTextColor(currentData, 'HST_140401', '2')}>
                <Checkbox label={'12. 사이드멤버'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140401', '2')}
                            disabled={changeControlEnable('HST_140401')}
                          onChange={() => changeValue('HST_140401', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140402', '2')}>
                <Checkbox label={'13. 휠하우스'}
                          checked={_setChecked(currentData, 'HST_140402', '2')}
                            disabled={changeControlEnable('HST_140402')}
                          onChange={() => changeValue('HST_140402', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={3} style={{borderTop: 0, borderLeft: '1px solid rgba(34,36,38,.1)'}}>
              <span style={_setWarningTextColor(currentData, 'HST_140403', '2')}>
                <Checkbox label={'14. 필러패널('}
                          checked={_setChecked(currentData, 'HST_140403', '2')}
                            disabled={changeControlEnable('HST_140403')}
                          onChange={() => changeValue('HST_140403', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140404', '2')}>
                <Checkbox label={'A,'}
                          style={{paddingRight:'10px'}}
                          checked={_setChecked(currentData, 'HST_140404', '2')}
                            disabled={changeControlEnable('HST_140404')}
                          onChange={() => changeValue('HST_140404', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140405', '2')}>
                <Checkbox label={'B,'}
                          style={{paddingRight:'10px'}}
                          checked={_setChecked(currentData, 'HST_140405', '2')}
                            disabled={changeControlEnable('HST_140405')}
                          onChange={() => changeValue('HST_140405', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140406', '2')}>
                <Checkbox label={'C)'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140406', '2')}
                            disabled={changeControlEnable('HST_140406')}
                          onChange={() => changeValue('HST_140406', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140407', '2')}>
                <Checkbox label={'19. 패키지트레이'}
                          checked={_setChecked(currentData, 'HST_140407', '2')}
                            disabled={changeControlEnable('HST_140407')}
                          onChange={() => changeValue('HST_140407', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>C랭크</Table.Cell>
            <Table.Cell colSpan={3}>
              <span style={_setWarningTextColor(currentData, 'HST_140501', '2')}>
                <Checkbox label={'15. 대쉬패널'}
                          style={{paddingRight:'20px'}}
                          checked={_setChecked(currentData, 'HST_140501', '2')}
                            disabled={changeControlEnable('HST_140501')}
                          onChange={() => changeValue('HST_140501', '2')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'HST_140502', '2')}>
                <Checkbox label={'16. 플로워패널'}
                          checked={_setChecked(currentData, 'HST_140502', '2')}
                            disabled={changeControlEnable('HST_140502')}
                          onChange={() => changeValue('HST_140502', '2')} />
              </span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>



      <Table className={'performance-check-record'} compact celled
             style={{fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
        <colgroup>
          <col width="150px" />
          <col width="150px" />
          <col width="200px" />
          <col />
        </colgroup>

        <Table.Body>
          <Table.Row>
            <Table.Cell className={'title'} colSpan={4}
                        style={{
                          textAlign: 'center',
                          borderBottom: '2px double rgba(34,36,38,.1)',
                        }}>
              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 세부상태</h5>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>⑭ 주요장치</Table.Cell>
            <Table.Cell className={'title'} colSpan={2}>항목 / 해당부품</Table.Cell>
            <Table.Cell className={'title'}>상태</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={2} className={'title'}>자기진단</Table.Cell>
            <Table.Cell colSpan={2}>원동기</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_151101', '1')}
                    disabled={changeControlEnable('DTS_151101')}
                    onChange={() => changeValue('DTS_151101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_151101', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_151101', '2')}
                    disabled={changeControlEnable('DTS_151101')}
                    onChange={() => changeValue('DTS_151101', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>변속기</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_151201', '1')}
                    disabled={changeControlEnable('DTS_151201')}
                    onChange={() => changeValue('DTS_151201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_151201', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_151201', '2')}
                    disabled={changeControlEnable('DTS_151201')}
                    onChange={() => changeValue('DTS_151201', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={10}>원동기</Table.Cell>
            <Table.Cell colSpan={2}>작동상태(공회전)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_152101', '1')}
                    disabled={changeControlEnable('DTS_152101')}
                    onChange={() => changeValue('DTS_152101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152101', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_152101', '2')}
                    disabled={changeControlEnable('DTS_152101')}
                    onChange={() => changeValue('DTS_152101', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={3} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일누유</Table.Cell>
            <Table.Cell>실린더 커버(로커암 커버)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_152201', '1')}
                    disabled={changeControlEnable('DTS_152201')}
                    onChange={() => changeValue('DTS_152201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152201', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_152201', '2')}
                    disabled={changeControlEnable('DTS_152201')}
                    onChange={() => changeValue('DTS_152201', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152201', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_152201', '3')}
                    disabled={changeControlEnable('DTS_152201')}
                    onChange={() => changeValue('DTS_152201', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>실린더 헤드 / 가스켓</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_152202', '1')}
                      disabled={changeControlEnable('DTS_152202')}
                    onChange={() => changeValue('DTS_152202', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152202', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_152202', '2')}
                    disabled={changeControlEnable('DTS_152202')}
                    onChange={() => changeValue('DTS_152202', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152202', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_152202', '3')}
                    disabled={changeControlEnable('DTS_152202')}
                    onChange={() => changeValue('DTS_152202', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>실린더 블록 /
              오일팬</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_152203', '1')}
                    disabled={changeControlEnable('DTS_152203')}
                    onChange={() => changeValue('DTS_152203', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152203', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_152203', '2')}
                    disabled={changeControlEnable('DTS_152203')}
                    onChange={() => changeValue('DTS_152203', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152203', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_152203', '3')}
                    disabled={changeControlEnable('DTS_152203')}
                    onChange={() => changeValue('DTS_152203', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일 유량</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'적정'}
                    checked={_setChecked(currentData, 'DTS_152301', '1')}
                    disabled={changeControlEnable('DTS_152301')}
                    onChange={() => changeValue('DTS_152301', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152301', '2')}>
                  <Checkbox label={'부족'}
                    checked={_setChecked(currentData, 'DTS_152301', '2')}
                    disabled={changeControlEnable('DTS_152301')}
                    onChange={() => changeValue('DTS_152301', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={4} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>냉각수누수</Table.Cell>
            <Table.Cell>실린더 헤드 / 가스켓</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_152401', '1')}
                    disabled={changeControlEnable('DTS_152401')}
                    onChange={() => changeValue('DTS_152401', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152401', '2')}>
                  <Checkbox label={'미세누수'}
                    checked={_setChecked(currentData, 'DTS_152401', '2')}
                      disabled={changeControlEnable('DTS_152401')}
                    onChange={() => changeValue('DTS_152401', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152401', '3')}>
                  <Checkbox label={'누수'}
                    checked={_setChecked(currentData, 'DTS_152401', '3')}
                                      disabled={changeControlEnable('DTS_152401')}
                    onChange={() => changeValue('DTS_152401', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>워터펌프</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_152402', '1')}
                    disabled={changeControlEnable('DTS_152402')}
                    onChange={() => changeValue('DTS_152402', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152402', '2')}>
                  <Checkbox label={'미세누수'}
                    checked={_setChecked(currentData, 'DTS_152402', '2')}
                    disabled={changeControlEnable('DTS_152402')}
                    onChange={() => changeValue('DTS_152402', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152402', '3')}>
                  <Checkbox label={'누수'}
                    checked={_setChecked(currentData, 'DTS_152402', '3')}
                    disabled={changeControlEnable('DTS_152402')}
                    onChange={() => changeValue('DTS_152402', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>라디에이터</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_152403', '1')}
                    disabled={changeControlEnable('DTS_152403')}
                    onChange={() => changeValue('DTS_152403', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152403', '2')}>
                  <Checkbox label={'미세누수'}
                    checked={_setChecked(currentData, 'DTS_152403', '2')}
                    disabled={changeControlEnable('DTS_152403')}
                    onChange={() => changeValue('DTS_152403', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152403', '3')}>
                  <Checkbox label={'누수'}
                    checked={_setChecked(currentData, 'DTS_152403', '3')}
                      disabled={changeControlEnable('DTS_152403')}
                    onChange={() => changeValue('DTS_152403', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>냉각수 수량</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'적정'}
                    checked={_setChecked(currentData, 'DTS_152404', '1')}
                    disabled={changeControlEnable('DTS_152404')}
                    onChange={() => changeValue('DTS_152404', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152404', '2')}>
                  <Checkbox label={'부족'}
                    checked={_setChecked(currentData, 'DTS_152404', '2')}
                    disabled={changeControlEnable('DTS_152404')}
                    onChange={() => changeValue('DTS_152404', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>커먼레일</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_152501', '1')}
                    disabled={changeControlEnable('DTS_152501')}
                    onChange={() => changeValue('DTS_152501', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_152501', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_152501', '2')}
                    disabled={changeControlEnable('DTS_152501')}
                    onChange={() => changeValue('DTS_152501', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={7}>변속기</Table.Cell>
            <Table.Cell rowSpan={3}>자동변속기 (A/T)</Table.Cell>
            <Table.Cell>오일누유</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_153101', '1')}
                      disabled={changeControlEnable('DTS_153101')}
                    onChange={() => changeValue('DTS_153101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153101', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_153101', '2')}
                    disabled={changeControlEnable('DTS_153101')}
                    onChange={() => changeValue('DTS_153101', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153101', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_153101', '3')}
                    disabled={changeControlEnable('DTS_153101')}
                    onChange={() => changeValue('DTS_153101', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일유량 및 상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'적정'}
                    checked={_setChecked(currentData, 'DTS_153102', '1')}
                    disabled={changeControlEnable('DTS_153102')}
                    onChange={() => changeValue('DTS_153102', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153102', '2')}>
                  <Checkbox label={'부족'}
                    checked={_setChecked(currentData, 'DTS_153102', '2')}
                    disabled={changeControlEnable('DTS_153102')}
                    onChange={() => changeValue('DTS_153102', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153102', '3')}>
                  <Checkbox label={'과다'}
                    checked={_setChecked(currentData, 'DTS_153102', '3')}
                    disabled={changeControlEnable('DTS_153102')}
                    onChange={() => changeValue('DTS_153102', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>작동상태(공회전)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_153103', '1')}
                    disabled={changeControlEnable('DTS_153103')}
                    onChange={() => changeValue('DTS_153103', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153103', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_153103', '2')}
                    disabled={changeControlEnable('DTS_153103')}
                    onChange={() => changeValue('DTS_153103', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={4} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>수동변속기 (M/T)</Table.Cell>
            <Table.Cell>오일누유</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_153201', '1')}
                    disabled={changeControlEnable('DTS_153201')}
                    onChange={() => changeValue('DTS_153201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153201', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_153201', '2')}
                    disabled={changeControlEnable('DTS_153201')}
                    onChange={() => changeValue('DTS_153201', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153201', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_153201', '3')}
                        disabled={changeControlEnable('DTS_153201')}
                    onChange={() => changeValue('DTS_153201', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>기어변속장치</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_153202', '1')}
                    disabled={changeControlEnable('DTS_153202')}
                    onChange={() => changeValue('DTS_153202', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153202', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_153202', '2')}
                    disabled={changeControlEnable('DTS_153202')}
                    onChange={() => changeValue('DTS_153202', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>오일유량 및 상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'적정'}
                    checked={_setChecked(currentData, 'DTS_153203', '1')}
                    disabled={changeControlEnable('DTS_153203')}
                    onChange={() => changeValue('DTS_153203', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153203', '2')}>
                  <Checkbox label={'부족'}
                    checked={_setChecked(currentData, 'DTS_153203', '2')}
                    disabled={changeControlEnable('DTS_153203')}
                    onChange={() => changeValue('DTS_153203', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153203', '3')}>
                  <Checkbox label={'과다'}
                    checked={_setChecked(currentData, 'DTS_153203', '3')}
                    disabled={changeControlEnable('DTS_153203')}
                    onChange={() => changeValue('DTS_153203', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>작동상태(공회전)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_153204', '1')}
                    disabled={changeControlEnable('DTS_153204')}
                    onChange={() => changeValue('DTS_153204', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_153204', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_153204', '2')}
                    disabled={changeControlEnable('DTS_153204')}
                    onChange={() => changeValue('DTS_153204', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={4}>동력전달</Table.Cell>
            <Table.Cell colSpan={2}>클러치 어셈블리</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_154101', '1')}
                        disabled={changeControlEnable('DTS_154101')}
                    onChange={() => changeValue('DTS_154101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_154101', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_154101', '2')}
                    disabled={changeControlEnable('DTS_154101')}
                    onChange={() => changeValue('DTS_154101', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>등속조인트</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_154201', '1')}
                        disabled={changeControlEnable('DTS_154201')}
                    onChange={() => changeValue('DTS_154201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_154201', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_154201', '2')}
                    disabled={changeControlEnable('DTS_154201')}
                    onChange={() => changeValue('DTS_154201', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>추진축 및 베어링</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_154301','1')}
                    disabled={changeControlEnable('DTS_154301')}
                    onChange={() => changeValue('DTS_154301', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_154301', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_154301', '2')}
                    disabled={changeControlEnable('DTS_154301')}
                    onChange={() => changeValue('DTS_154301', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>디퍼렌셜 기어</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_154401', '1')}
                    disabled={changeControlEnable('DTS_154401')}
                    onChange={() => changeValue('DTS_154401', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_154401', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_154401', '2')}
                      disabled={changeControlEnable('DTS_154401')}
                    onChange={() => changeValue('DTS_154401', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={6}>조향</Table.Cell>
            <Table.Cell colSpan={2}>동력조향 작동 오일 누유</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                    <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_155101', '1')}
                    disabled={changeControlEnable('DTS_155101')}
                    onChange={() => changeValue('DTS_155101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155101', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_155101', '2')}
                    disabled={changeControlEnable('DTS_155101')}
                    onChange={() => changeValue('DTS_155101', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155101', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_155101', '3')}
                    disabled={changeControlEnable('DTS_155101')}
                    onChange={() => changeValue('DTS_155101', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={5} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>작동상태</Table.Cell>
            <Table.Cell>스티어링 펌프</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_155202', '1')}
                    disabled={changeControlEnable('DTS_155202')}
                    onChange={() => changeValue('DTS_155202', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155202', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_155202', '2')}
                    disabled={changeControlEnable('DTS_155202')}
                    onChange={() => changeValue('DTS_155202', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>스티어링
              기어(MDPS포함)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_155201', '1')}
                    disabled={changeControlEnable('DTS_155201')}
                    onChange={() => changeValue('DTS_155201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155201', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_155201', '2')}
                    disabled={changeControlEnable('DTS_155201')}
                    onChange={() => changeValue('DTS_155201', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>스티어링조인트</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_155204', '1')}
                    disabled={changeControlEnable('DTS_155204')}
                    onChange={() => changeValue('DTS_155204', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155204', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_155204', '2')}
                        disabled={changeControlEnable('DTS_155204')}
                    onChange={() => changeValue('DTS_155204', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>파워고압호스</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_155205', '1')}
                    disabled={changeControlEnable('DTS_155205')}
                    onChange={() => changeValue('DTS_155205', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155205', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_155205', '2')}
                    disabled={changeControlEnable('DTS_155205')}
                    onChange={() => changeValue('DTS_155205', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>타이로드엔드 및 볼조인트</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_155203', '1')}
                    disabled={changeControlEnable('DTS_155203')}
                    onChange={() => changeValue('DTS_155203', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_155203', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_155203', '2')}
                    disabled={changeControlEnable('DTS_155203')}
                    onChange={() => changeValue('DTS_155203', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={3}>제동</Table.Cell>
            <Table.Cell colSpan={2}>브레이크 마스터 실린더오일 누유</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_156101', '1')}
                    disabled={changeControlEnable('DTS_156101')}
                    onChange={() => changeValue('DTS_156101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_156101', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_156101', '2')}
                    disabled={changeControlEnable('DTS_156101')}
                    onChange={() => changeValue('DTS_156101', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_156101', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_156101', '3')}
                    disabled={changeControlEnable('DTS_156101')}
                    onChange={() => changeValue('DTS_156101', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>브레이크 오일 누유</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'없음'}
                    checked={_setChecked(currentData, 'DTS_156201', '1')}
                    disabled={changeControlEnable('DTS_156201')}
                    onChange={() => changeValue('DTS_156201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_156201', '2')}>
                  <Checkbox label={'미세누유'}
                    checked={_setChecked(currentData, 'DTS_156201', '2')}
                    disabled={changeControlEnable('DTS_156201')}
                    onChange={() => changeValue('DTS_156201', '2')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_156201', '3')}>
                  <Checkbox label={'누유'}
                    checked={_setChecked(currentData, 'DTS_156201', '3')}
                    disabled={changeControlEnable('DTS_156201')}
                    onChange={() => changeValue('DTS_156201', '3')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>배력장치 상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_156301', '1')}
                    disabled={changeControlEnable('DTS_156301')}
                    onChange={() => changeValue('DTS_156301', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_156301', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_156301', '2')}
                                            disabled={changeControlEnable('DTS_156301')}
                    onChange={() => changeValue('DTS_156301', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={6}>전기</Table.Cell>
            <Table.Cell colSpan={2}>발전기 출력</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_157101', '1')}
                    disabled={changeControlEnable('DTS_157101')}
                    onChange={() => changeValue('DTS_157101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_157101', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_157101', '2')}
                    disabled={changeControlEnable('DTS_157101')}
                    onChange={() => changeValue('DTS_157101', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>시동 모터</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_157201', '1')}
                    disabled={changeControlEnable('DTS_157201')}
                    onChange={() => changeValue('DTS_157201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_157201', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_157201', '2')}
                    disabled={changeControlEnable('DTS_157201')}
                    onChange={() => changeValue('DTS_157201', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>와이퍼 모터 기능</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_157301', '1')}
                    disabled={changeControlEnable('DTS_157301')}
                    onChange={() => changeValue('DTS_157301', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_157301', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_157301', '2')}
                      disabled={changeControlEnable('DTS_157301')}
                    onChange={() => changeValue('DTS_157301', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>실내송풍 모터</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_157401', '1')}
                    disabled={changeControlEnable('DTS_157401')}
                    onChange={() => changeValue('DTS_157401', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_157401', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_157401', '2')}
                    disabled={changeControlEnable('DTS_157401')}
                    onChange={() => changeValue('DTS_157401', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>라디에이터 팬 모터</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_157501', '1')}
                    disabled={changeControlEnable('DTS_157501')}
                    onChange={() => changeValue('DTS_157501', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_157501', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_157501', '2')}
                        disabled={changeControlEnable('DTS_157501')}
                    onChange={() => changeValue('DTS_157501', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>윈도우 모터</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_157601', '1')}
                    disabled={changeControlEnable('DTS_157601')}
                    onChange={() => changeValue('DTS_157601', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_157601', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_157601', '2')}
                    disabled={changeControlEnable('DTS_157601')}
                    onChange={() => changeValue('DTS_157601', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'} rowSpan={3}>고전원 전기장치</Table.Cell>
            <Table.Cell colSpan={2}>충전구 절연 상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                    <Checkbox label={'양호'}
                    checked={_setChecked(currentData,  'DTS_159101', '1')}
                    disabled={changeControlEnable('DTS_159101')}
                    onChange={() => changeValue('DTS_159101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_159101', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_159101', '2')}
                    disabled={changeControlEnable('DTS_159101')}
                    onChange={() => changeValue('DTS_159101', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>구동축전지 격리 상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_159201', '1')}
                    disabled={changeControlEnable('DTS_159201')}
                    onChange={() => changeValue('DTS_159201', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_159201', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_159201', '2')}
                    disabled={changeControlEnable('DTS_159201')}
                    onChange={() => changeValue('DTS_159201', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell colSpan={2} style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>고전원전기배선 상태 (접속단자, 피복, 보호기구)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_159301', '1')}
                    disabled={changeControlEnable('DTS_159301')}
                    onChange={() => changeValue('DTS_159301', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_159301', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_159301', '2')}
                    disabled={changeControlEnable('DTS_159301')}
                    onChange={() => changeValue('DTS_159301', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={'title'}>연료</Table.Cell>
            <Table.Cell colSpan={2}>연료누출(LP가스포함)</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span>
                  <Checkbox label={'양호'}
                    checked={_setChecked(currentData, 'DTS_158101', '1')}
                    disabled={changeControlEnable('DTS_158101')}
                    onChange={() => changeValue('DTS_158101', '1')} />
                </span>
                <span style={_setWarningTextColor(currentData, 'DTS_158101', '2')}>
                  <Checkbox label={'불량'}
                    checked={_setChecked(currentData, 'DTS_158101', '2')}
                    disabled={changeControlEnable('DTS_158101')}
                    onChange={() => changeValue('DTS_158101', '2')} />
                </span>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table className={'performance-check-record'} compact celled
             style={{backgroundColor: 'rgba(105, 105, 105, 0.1)', fontSize: BASE_STYLES.FONT_SIZE.SUB}}>
        <colgroup>
          <col width="150px" />
          <col />
          <col />
          <col />
          <col />
        </colgroup>

        <Table.Body>
          <Table.Row>
            <Table.Cell
              className={'title'} colSpan={5}
              style={{
                textAlign: 'center',
                borderBottom: '2px double rgba(34,36,38,.1)',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <h5 style={{marginBottom: 0, marginRight: 4}}>자동차 기타정보</h5>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>&nbsp;</Table.Cell>
            <Table.Cell colSpan={4}>항목</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell rowSpan={5}>수리필요</Table.Cell>
            <Table.Cell>외장</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell>내장</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>광택</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell>룸 크리닝</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>휠</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 6,
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 4,
                }}>
                  <span>운전석</span>
                  <span><Checkbox label={'전'} disabled />,</span>
                  <span><Checkbox label={'후'} disabled /></span>
                </div>
                <span> / </span>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 4,
                }}>
                  <span>동반석</span>
                  <span><Checkbox label={'전'} disabled />,</span>
                  <span><Checkbox label={'후'} disabled />,</span>
                  <span><Checkbox label={'응급'} disabled /></span>
                </div>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>타이어</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 6,
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 4,
                }}>
                  <span>운전석</span>
                  <span><Checkbox label={'전'} disabled />,</span>
                  <span><Checkbox label={'후'} disabled /></span>
                </div>
                <span> / </span>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 4,
                }}>
                  <span>동반석</span>
                  <span><Checkbox label={'전'} disabled />,</span>
                  <span><Checkbox label={'후'} disabled />,</span>
                  <span><Checkbox label={'응급'} disabled /></span>
                </div>
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell style={{borderLeft: '1px solid rgba(34,36,38,.1)'}}>유리</Table.Cell>
            <Table.Cell colSpan={3}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'양호'} disabled /></span>
                <span><Checkbox label={'불량'} disabled /></span>
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>기본품목</Table.Cell>
            <Table.Cell>보유상태</Table.Cell>
            <Table.Cell>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
              }}>
                <span><Checkbox label={'있음'} disabled /></span>
                <span><Checkbox label={'없음'} disabled /></span>
              </div>
            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 4,
              }}>
                (&nbsp;
                <span><Checkbox label={'사용설명서'} disabled /></span>
                <span><Checkbox label={'안전삼각대'} disabled /></span>
                <span><Checkbox label={'잭'} disabled /></span>
                <span><Checkbox label={'스패너'} disabled /></span>
                &nbsp;)
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row style={{backgroundColor: '#fff'}}>
            <Table.Cell className={'title'} rowSpan={2}>⑮ 특기사항 및 점검자의 의견</Table.Cell>
            <Table.Cell>성능ㆍ상태 점검자</Table.Cell>
            <Table.Cell colSpan={3} style={{verticalAlign: 'top', height: 120}}>
              <div style={{
                fontSize: BASE_STYLES.FONT_SIZE.MIN,
                color: BASE_STYLES.THEME_BG_COLOR,
                marginBottom: 6,
              }}>
                (비금속 또는 탈부착 가능 부품은 점검사항에서 제외되며 중고차 특성 상 부분적인 판금, 도색은 있을 수 있습니다.)
              </div>
              <TextArea rows={5}
                        style={{width:'100%'}}
                        value={currentData?.BAS_990000}
                        onChange={(event, {value}) => changeValue('BAS_990000', value)}/>

            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>



      <div style={{
        display: stateMenuOption.open ? 'block' : 'none',
        position: 'absolute',
        zIndex: 9999,
        left: stateMenuOption.x,
        top: stateMenuOption.y,
      }}>
        <Menu vertical compact inverted size={'tiny'}>
          {
            STATE_TYPE.map((o,idx) => {
              return (
                <Menu.Item
                  key={`menu_item_${idx}`}
                  name={o.label}
                  active={currentData[stateMenuOption.code] === o.value}
                  color={_.isEmpty(o.color) ? null : o.color}
                  onClick={() => {
                    changeValue(stateMenuOption.code, o.value);
                    setStateMenuOption(prevState => {
                      return {...prevState, open: false}
                    })
                  }}
                />
              )
            })
          }
        </Menu>
      </div>

      {
        accidentHistoryPopupOption.open && (
        <WindowPopup close={repairHistoryPopupClose} title={'카몬 - 자동차 사고이력 조회'} width={1200} height={800}>
          <AccidentHistory plateNumber={currentData?.BAS_020000}/>
        </WindowPopup>
      )}

      {
        pcrTemporaryPopupOption.open && (
          <WindowPopup
            close={pcrTemporaryPopupClose}
            title={'카몬 - 정비소 차량 점검 내역'}
            width={700}
            height={800} >
              <Container text>
                <iframe src={`${process.env.EXTERNAL_BASE_URL}/external/performance-check/${currentData?.BAS_000002}?editable=false`}
                        style={{border:0, width: '100%', height: `calc(100vh - 60px)`}} scrolling={'no'} frameBorder={0}/>
              </Container>

          </WindowPopup>
        )}

    </>
  );
}

