import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Checkbox, Dimmer, Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon, Input,
  Label,
  List, Pagination, Ref,
  Segment, Select, Sticky,
  Table,
  TextArea,
} from 'semantic-ui-react';
import _ from 'lodash';
import {CommonUtil} from '../../../utils/CommonUtil';
import moment from 'moment';
import {
  REPAIR_HISTORY_EMAIL_REPORT_TEMPLATE,
} from '../../../consts/chachatja/repairHistroyReportTemplates/EmailTemplate';
import {
  REPAIR_HISTORY_MOBILE_REPORT_TEMPLATE,
} from '../../../consts/chachatja/repairHistroyReportTemplates/MobileTemplate';

const positionMap = {
  LEFT: {code: 'left', label: '좌측'},
  RIGHT: {code: 'right', label: '우측'},
  TOP: {code: 'top', label: '상판'},
  BOTTOM: {code: 'bottom', label: '하부'},
  ETC: {code: 'etc', label: '기타'},
};

const CAR_PARTS = {
  left: {
    fender: {code: '2', label: '휀더', checked: false, isAccident: false, withDesc: false, rank: '1'},
    door1: {code: '3f', label: '도어(앞)', checked: false, isAccident: false, withDesc: false, rank: '1'},
    door2: {code: '3r', label: '도어(뒤)', checked: false, isAccident: false, withDesc: false, rank: '1'},
    quarterPanel: {code: '6', label: '쿼터패널', checked: false, isAccident: false, withDesc: false, rank: '2'},
    sideSill: {code: '8', label: '사이드실 패널', checked: false, isAccident: false, withDesc: false, rank: '2'},
    aPillar: {code: '14a', label: 'A필러', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    bPillar: {code: '14b', label: 'B필러', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    cPillar: {code: '14c', label: 'C필러', checked: false, isAccident: true, withDesc: false, rank: 'B'},
  },
  right: {
    fender: {code: '2', label: '휀더', checked: false, isAccident: false, withDesc: false, rank: '1'},
    door1: {code: '3f', label: '도어(앞)', checked: false, isAccident: false, withDesc: false, rank: '1'},
    door2: {code: '3r', label: '도어(뒤)', checked: false, isAccident: false, withDesc: false, rank: '1'},
    quarterPanel: {code: '6', label: '쿼터패널', checked: false, isAccident: false, withDesc: false, rank: '2'},
    sideSill: {code: '8', label: '사이드실 패널', checked: false, isAccident: false, withDesc: false, rank: '2'},
    aPillar: {code: '14a', label: 'A필러', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    bPillar: {code: '14b', label: 'B필러', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    cPillar: {code: '14c', label: 'C필러', checked: false, isAccident: true, withDesc: false, rank: 'B'},
  },
  top: {
    hood: {code: '1', label: '후드', checked: false, isAccident: false, withDesc: false, rank: '1'},
    roofPanel: {code: '7', label: '루프패널', checked: false, isAccident: false, withDesc: false, rank: '2'},
    trunkLid: {code: '4', label: '트렁크 리드', checked: false, isAccident: false, withDesc: false, rank: '1'},
  },
  bottom: {
    radiatorSupport: {code: '5', label: '라디에이터 서포트', checked: false, isAccident: false, withDesc: false, rank: '1'},
    frontPanel: {code: '9', label: '프론트패널', checked: false, isAccident: true, withDesc: false, rank: 'A'},
    crossMember: {code: '10', label: '크로스맴버', checked: false, isAccident: true, withDesc: false, rank: 'A'},
    insidePanel1: {code: '11l', label: '인사이드패널(L)', checked: false, isAccident: true, withDesc: false, rank: 'A'},
    insidePanel2: {code: '11r', label: '인사이드패널(R)', checked: false, isAccident: true, withDesc: false, rank: 'A'},
    sideMember1: {code: '12fl', label: '사이드맴버(FL)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    sideMember2: {code: '12fr', label: '사이드맴버(FR)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    sideMember3: {code: '12rl', label: '사이드맴버(RL)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    sideMember4: {code: '12rr', label: '사이드맴버(RR)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    wheelHouse1: {code: '13fl', label: '휠하우스(FL)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    wheelHouse2: {code: '13fr', label: '휠하우스(FR)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    wheelHouse3: {code: '13rl', label: '휠하우스(RL)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    wheelHouse4: {code: '13rr', label: '휠하우스(RR)', checked: false, isAccident: true, withDesc: false, rank: 'B'},
    dashPanel: {code: '15', label: '대쉬패널', checked: false, isAccident: true, withDesc: false, rank: 'C'},
    floorPanel: {code: '16', label: '플로어패널', checked: false, isAccident: true, withDesc: false, rank: 'C'},
    trunkFloor: {code: '17', label: '트렁크플로어', checked: false, isAccident: true, withDesc: false, rank: 'A'},
    rearPanel: {code: '18', label: '리어패널', checked: false, isAccident: true, withDesc: false, rank: 'A'},
    packageTray: {code: '19', label: '패키지트레이', checked: false, isAccident: true, withDesc: false, rank: 'B'},
  },
  etc: {code: 'etc', label: '기타', checked: false, isAccident: false, withDesc: false, rank: '0'},
};

const SOURCE = [
  {key: '0', text: '전체', value: '전체'},
  {key: '1', text: '나이스 디앤알', value: '나이스 디앤알'},
  {key: '2', text: '국토부', value: '국토부'},
  {key: '3', text: '카히스토리', value: '카히스토리'},
];

const BASE_IMAGE_URL = `${process.env.CARNAWA_API_BASE_URL}/images/parts`;

const DEFAULT_ACTIVE_PAGE = 1;
const DEFAULT_PAGE_SIZE = 5;

export default function RepairReportRequestList() {
  const [pageable, setPageable] = useState({
    activePage: DEFAULT_ACTIVE_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  //const [repairPoint, setRepairPoint] = useState(_.cloneDeep(CAR_PARTS));
  const [backgrounds, setBackgrounds] = useState({
    left: [`url(${BASE_IMAGE_URL}/left)`],
    top: [`url(${BASE_IMAGE_URL}/top)`],
    bottom: [`url(${BASE_IMAGE_URL}/bottom)`],
    right: [`url(${BASE_IMAGE_URL}/right)`],
  });

  const [reportDocument, setReportDocument] = useState(REPAIR_HISTORY_MOBILE_REPORT_TEMPLATE);

  const [searchType, setSearchType] = useState('2');
  const [filterKeyword, setFilterKeyword] = useState('전체');
  const [searchKeyword, setSearchKeyword] = useState('');

  const contextRef = useRef(null);

  useEffect(() => {
    retrieveRequests();
    drawReport();
  }, []);

  useEffect(() => {
    setPageable(prevState => {
      return {
        ...prevState,
        activePage: DEFAULT_ACTIVE_PAGE
      }
    });

    retrieveRequests();
  }, [searchType]);

  useEffect(() => {
    let checkedParts = {
      left: {normal: [], accident: []},
      right: {normal: [], accident: []},
      top: {normal: [], accident: []},
      bottom: {normal: [], accident: []},
    };

    _.map(selectedItem.repairHistory, (row) => {
      if (row && row['selectedParts']) {
        _.map(row['selectedParts'], (parts, pos) => {
          _.map(parts, (partsData) => {
            if (checkedParts && checkedParts[pos]) {
              checkedParts[pos][partsData.isAccident ? 'accident' : 'normal'].push(`${partsData.code}${partsData.isAccident ? 'r' : 'g'}`);
            }
          });
        });
      }
    });

    setBackgrounds(prevState => {
      return {
        ...prevState,
        left: `url(${BASE_IMAGE_URL}/left/${_.union(checkedParts.left.normal, checkedParts.left.accident).join('-')})`,
        right: `url(${BASE_IMAGE_URL}/right/${_.union(checkedParts.right.normal, checkedParts.right.accident).join('-')})`,
        top: `url(${BASE_IMAGE_URL}/top/${_.union(checkedParts.top.normal, checkedParts.top.accident).join('-')})`,
        bottom: `url(${BASE_IMAGE_URL}/bottom/${_.union(checkedParts.bottom.normal, checkedParts.bottom.accident).join('-')})`,
      };
    });

    if (selectedItem && selectedItem.requestInfo) {
      setReportDocument(selectedItem.requestInfo.contactType === 'EML' ? REPAIR_HISTORY_EMAIL_REPORT_TEMPLATE : REPAIR_HISTORY_MOBILE_REPORT_TEMPLATE);
    }
  }, [selectedItem]);

  useEffect(() => {
    drawReport();
  }, [backgrounds]);

  useEffect(() => {
    retrieveRequests();
  }, [pageable.activePage, pageable.pageSize]);

  const drawReport = () => {
    let report = _.cloneDeep(reportDocument);

    if (!_.isEmpty(selectedItem)) {
      report = report.replaceAll('[##_차량번호_##]', selectedItem.licensePlateNumber);
      report = report.replaceAll('[##_요청일시_##]', moment(selectedItem.requestInfo.regTime).format('YYYY년 MM월 DD일 HH:mm'));
      report = report.replaceAll('[##_모델_##]', `[${selectedItem.makerNm}] ${selectedItem.modelNm}`);
      report = report.replaceAll('[##_연식_##]', `${selectedItem.yearType}년식`);
      report = report.replaceAll('[##_최초등록일_##]', moment(selectedItem.requestInfo.firstRegDate).format('YYYY년 MM월 DD일'));
      report = report.replaceAll('[##_연료_##]', selectedItem.fuel);
      report = report.replaceAll('[##_변속기_##]', selectedItem.gearBox);
      report = report.replaceAll('[##_정원_##]', selectedItem.seatingCapacity);
      report = report.replaceAll('[##_차종_##]', selectedItem.modelType);
      report = report.replaceAll('[##_용도_##]', selectedItem.useType);
      report = report.replaceAll('[##_배기량_##]', `${CommonUtil.Unit.format(selectedItem.engineSize)} cc`);
      report = report.replaceAll('[##_색상_##]', selectedItem.color);
      report = report.replaceAll('[##_연비_##]', selectedItem.standardMileage);

      report = report.replaceAll('[##_상세옵션_##]', renderOptions());

      report = report.replaceAll('[##_유사고차량_##]', renderIsAccidentCar());
      report = report.replaceAll('[##_사고정비이력_##]', renderRepairHistory());
      report = report.replaceAll('[##_예상보상가액_##]', calculateCompensation());

      report = report.replaceAll('[##_비고사항_##]', (selectedItem.description || ''));
    }

    report = report.replaceAll('[##_차량부위좌측_##]', backgrounds.left);
    report = report.replaceAll('[##_차량부위상단_##]', backgrounds.top);
    report = report.replaceAll('[##_차량부위하단_##]', backgrounds.bottom);
    report = report.replaceAll('[##_차량부위우측_##]', backgrounds.right);

    setReportDocument(report);
  };

  const renderOptions = () => {
    let detailTable = ``;

    if (selectedItem.detail.count === 1) {
      detailTable = `<table width="${selectedItem.requestInfo.contactType === 'CEL' ? '640' : '480'}" cellpadding="0" cellspacing="0" border="0" class="wrapper" bgcolor="#FFFFFF">
          <tr>
            <td align="center" valign="top">

              <table width="${selectedItem.requestInfo.contactType === 'CEL' ? '600' : '440'}" cellpadding="0" cellspacing="0" border="0" class="container">
                <tr>
                  <td align="left" valign="top" style="line-height: 1.8">
      `;

      selectedItem?.detail?.data?.carOpt?.split(/,/).map((keyword) => {
        detailTable += `<label style="font-size: 12px; background-color: #02A7F0!important; border-color: #02A7F0!important; color: #ffffff !important;display: inline-block; line-height: 1; vertical-align: baseline; margin: 0 0.14285714em; background-image: none; padding: 0.5833em 0.833em; color: rgba(0,0,0,.6); text-transform: none; font-weight: 700; border-radius: 0.28571429rem; -webkit-transition: background .1s ease; transition: background .1s ease;">${keyword}</label>`;
      });

      detailTable += `
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>`;
    }

    return detailTable;
  };

  const renderIsAccidentCar = () => {
    let isAccident = false;

    _.map(selectedItem.repairHistory, (row) => {
      if (row && row['selectedParts']) {
        _.map(row['selectedParts'], (parts) => {
          _.map(parts, (partsData) => {
            if (partsData.isAccident) isAccident = true;
          });
        });
      }
    });

    return isAccident ? `<div style="font-size:2em;color:#ff0000;font-weight: bold;padding-bottom: 30px">유사고차량</div>` : '';
  };

  const renderRepairHistory = () => {
    const renderer = [];

    _.map(selectedItem.repairHistory, (row) => {
      if (row && row['selectedParts']) {
        _.map(row['selectedParts'], (parts, pos) => {
          const position = positionMap[pos.toUpperCase()];

          if (position.code === 'etc') {
            renderer.push(`
              <tr style="height:30px;">
                <td style="padding: 6px;border: 1px solid #2224261a;color: #000}; background-color: #FAFAFA;">${row.inDay}</td>
                <td style="padding: 6px;border: 1px solid #2224261a;color: #000}; background-color: #FAFAFA;">${row.outDay}</td>
                <td style="padding: 6px;border: 1px solid #2224261a;color: #000}; background-color: #FAFAFA;">${position.label}</td>
                <td style="padding: 6px;border: 1px solid #2224261a;color: #000}; background-color: #FAFAFA;">확인되지 않음</td>                               
              </tr>
            `);

            if (row.withDesc) {
              renderer.push(`            
              <tr style="height:30px;">
                <td colspan="4" style="padding: 6px;border: 1px solid #2224261a;color: #666; background-color: #FAFAFA;text-align: left">
                  ${row.description}
                </td>
              </tr>
            `);
            }
          } else {
            _.map(parts, (partsData) => {
              renderer.push(`
                <tr style="height:30px;">
                  <td style="padding: 6px;border: 1px solid #2224261a;color: ${partsData['isAccident'] ? '#f00' : '#000'}; background-color: #FAFAFA;">${row.inDay}</td>
                  <td style="padding: 6px;border: 1px solid #2224261a;color: ${partsData['isAccident'] ? '#f00' : '#000'}; background-color: #FAFAFA;">${row.outDay}</td>
                  <td style="padding: 6px;border: 1px solid #2224261a;color: ${partsData['isAccident'] ? '#f00' : '#000'}; background-color: #FAFAFA;">${partsData['isAccident'] ? '사고수리' : '단순수리'}</td>
                  <td style="padding: 6px;border: 1px solid #2224261a;color: ${partsData['isAccident'] ? '#f00' : '#000'}; background-color: #FAFAFA;">${position['label']} ${partsData['label']}</td>                               
                </tr>
              `);

              if (partsData.withDesc) {
                renderer.push(`            
                <tr style="height:30px;">
                  <td colspan="4" style="padding: 6px;border: 1px solid #2224261a;color: #666; background-color: #FAFAFA;text-align: left">
                    ${row.description}
                  </td>
                </tr>
              `);
              }
            });
          }
        });
      }
    });

    return renderer.join('');
  };

  const calculateCompensation = () => {
    //차량 구매 가격을 입력시 : 입력값*2% ~ 입력값*5%
    //차량 구매 마격 미 입력시: 주행거리시세*2% ~ 주행거리시세*5%
    const MAXIMUM = selectedItem.cho === '1' ? 100 : 200;

    const purchasePrice = selectedItem.purchasePrice;

    if (purchasePrice != null && purchasePrice > 0) {
      let min = Math.round(purchasePrice * 0.05 / 10000, 0);
      let max = Math.round(purchasePrice * 0.07 / 10000, 0);

      if (min >= MAXIMUM) min = MAXIMUM;
      if (max >= MAXIMUM) max = MAXIMUM;

      const compensationRage = min === max ? `${max}` : `${min} ~ ${max}`;

      return `<div style="font-size: 2.5em;font-weight: 800; color: #1b1c1d;">${compensationRage} <span style="font-size:20px">만원</span></div>`;
    }

    return `<div><b>차량 구매가 대비 5% ~ 7% 내에서 보상 가능<br/>(최대 ${MAXIMUM} 만원)</b></div>`;
  };


  const retrieveRequests = () => {
    setLoading(true);
    axios.get(`/apis/admin/repair/requests?type=${searchType}&keyword=${searchKeyword}&page=${pageable.activePage - 1}&size=${pageable.pageSize}`).then(({data}) => {
      for (let row of data.content) {
        row['description'] = '';

        for (let r of row.repairHistory) {
          r['withDesc'] = false;
        }
      }

      setRequests(data);
    }).finally(() => {
      setSelectedItem({});
      setLoading(false);
    });
  };

  /*
  const addRepairPoint = (pos, parts) => {
    setRepairPoint(prevState => {
      return {
        ...prevState,
        [pos]: {
          ...prevState[pos],
          [parts]: {
            ...prevState[pos][parts],
            checked: true,
          },
        },
      };
    });
  };
  */

  const removeRepairPoint = () => {
    let selectedParts = {left: {}, right: {}, top: {}, bottom: {}, etc: {}};

    _.map(selectedItem.repairHistory, (row) => {
      if (row && row['selectedParts']) {
        _.map(row.selectedParts, (parts, pos) => {
          _.map(parts, (partsData, partsKey) => {
            selectedParts[pos][partsKey] = partsData;//_.union(v, row['selectedParts'][k]);
          });
        });
      }
    });

    // setRepairPoint(prevState => {
    //   return {
    //     ...prevState,
    //     [pos]: {
    //       ...prevState[pos],
    //       [parts]: {
    //         ...prevState[pos][parts],
    //         checked: selectedParts[pos][parts].checked,
    //       },
    //     },
    //   };
    // });
  };

  const renderParts = (row, idx) => {
    const renderer = [];

    _.map(CAR_PARTS, (pos, key) => {
      const position = positionMap[key.toUpperCase()];

      if (position.code === 'etc') {
        renderer.push(
          <Dropdown.Item text={position.label} key={`pos_${key}`} onClick={() => {
            if (!(row && row['selectedParts'])) row['selectedParts'] = {};
            if (!(row.selectedParts[key])) row['selectedParts'][key] = {};
            row.selectedParts[key] = _.cloneDeep(CAR_PARTS[key]);
            row.selectedParts[key].checked = true;
            row.selectedParts[key].withDesc = (row && row['withDesc']) ? row['withDesc'] : false;

            setSelectedItem(prevState => {
              prevState['repairHistory'][idx] = row;
              return {
                ...prevState,
                repairHistory: prevState['repairHistory'],
              };
            });

          }} />,
        );
      } else {
        renderer.push(
          <Dropdown.Item key={`pos_${key}`}>
            <Dropdown text={position.label} pointing="left" className="link item">
              <Dropdown.Menu>
                {
                  _.map(pos, (parts, partsKey) => {
                    return <Dropdown.Item key={`pos_${key}_${partsKey}`} onClick={() => {
                      if (!(row && row['selectedParts'])) row['selectedParts'] = {};
                      if (!(row.selectedParts[key])) row['selectedParts'][key] = {};
                      row.selectedParts[key][partsKey] = _.cloneDeep(CAR_PARTS[key][partsKey]);
                      row.selectedParts[key][partsKey].checked = true;
                      row.selectedParts[key][partsKey].withDesc = (row && row['withDesc']) ? row['withDesc'] : false;

                      setSelectedItem(prevState => {
                        prevState['repairHistory'][idx] = row;
                        return {
                          ...prevState,
                          repairHistory: prevState['repairHistory'],
                        };
                      });

                      //addRepairPoint(key, partsKey);
                    }}>{parts.label}</Dropdown.Item>;
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Item>,
        );
      }
    });

    return renderer;
  };

  const toggleAccident = (row, idx, pos, partsKey) => {
    setSelectedItem(prevState => {
      const repairHistory = _.cloneDeep(prevState['repairHistory']);

      if (repairHistory[idx]['selectedParts'][pos][partsKey]) {
        //A,B,C랭크가 아니면, 단순수리 토글 가능
        if (_.indexOf(['A', 'B', 'C'], repairHistory[idx]['selectedParts'][pos][partsKey]['rank']) < 0)
          repairHistory[idx]['selectedParts'][pos][partsKey]['isAccident'] = !repairHistory[idx]['selectedParts'][pos][partsKey]['isAccident'];
      }

      return {
        ...prevState,
        repairHistory: repairHistory,
      };
    });
  };

  const sendReport = () => {
    let emailTemplate = new XMLSerializer().serializeToString(document.querySelector('#emailTemplateArea > iframe').contentWindow.document);
    emailTemplate = emailTemplate.replaceAll('\n', '');

    const requestBody = {
      requestId: selectedItem.requestInfo.repairHistoryId,
      rawData: JSON.stringify(selectedItem),
      emailTemplate,
    };

    setIsSending(true);
    axios.post(`/apis/admin/repair/report-issue`, requestBody)
      .then(() => {
        setSelectedItem({});
        setReportDocument(REPAIR_HISTORY_MOBILE_REPORT_TEMPLATE);
        retrieveRequests();
      })
      .finally(() => setIsSending(false));
  };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width="7">
            <Pagination
              defaultActivePage={DEFAULT_ACTIVE_PAGE}
              totalPages={requests?.totalPages || 1}
              onPageChange={(e, {activePage}) => setPageable(prevState => ({...prevState, activePage: activePage}))}
            />
          </Grid.Column>
        </Grid.Row>

        <Ref innerRef={contextRef}>
          <Grid.Row>
            <Grid.Column width="3">
              {/*<Header>*/}
              {/*  <Header.Content>요청</Header.Content>*/}
              {/*</Header>*/}
              <div style={{fontSize: '.8rem'}}>
                <Select options={[{key:'0', text:'전체', value: '0'},{key:'1', text:'완료', value: '1'},{key:'2', text:'대기', value: '2'}]}
                        value={searchType}
                        disabled={loading}
                        onChange={(e, o) => {
                          setSearchType(o.value);
                        }} />&nbsp;
                <Input type={'text'}
                       placeholder={'검색어'}
                       style={{width:'100px'}}
                       onChange={(e, o) => {
                         setSearchKeyword(o.value);
                         //setSearchType(o.value);
                       }}
                       onKeyPress={(e) => {
                         if (e.code === 'Enter' || e.which === 13 || e.charCode === 13) {
                           retrieveRequests();
                         }
                       }} />&nbsp;
                <Button icon={'refresh'} color={'blue'} size={'mini'} loading={loading} disabled={loading} onClick={retrieveRequests}/>
              </div>

              <Divider />

              <Segment basic style={{padding:0}}>
                <Dimmer.Dimmable dimmed={loading}>
                  {_.map(requests.content, (requestItem, idx) => (
                    <ItemCard currentItem={requestItem} selectedItem={selectedItem} callback={() => {
                      setSelectedItem(prevState => {
                        setRequests(prevReq => {
                          const content = prevReq.content;
                          content[prevState.idx] = prevState;
                          return {
                            ...prevReq,
                            content: content,
                          };
                        });

                        return {idx: idx, ...requestItem};
                      });
                    }}/>
                  ))}
                  <Dimmer inverted active={loading} style={{zIndex:2}} />
                </Dimmer.Dimmable>
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Header dividing>
                <Header.Content style={{float: 'left'}}>정비 이력</Header.Content>
                <div style={{float: 'right', fontSize: '.8rem'}}>
                  <Select options={SOURCE} value={filterKeyword} onChange={(e, o) => {
                    setFilterKeyword(o.value);
                  }} />
                </div>
                <div style={{clear: 'both'}}></div>
              </Header>
              {
                _.map(selectedItem.repairHistory, (row, idx) => {
                  if (filterKeyword !== '전체' && filterKeyword !== row.source) return;

                  return (
                    <Card key={`repair-history${idx}`} fluid>
                      <Table celled striped>
                        <Table.Body>

                          <Table.Row>
                            <Table.Cell collapsing><b>{row.source}</b></Table.Cell>
                            <Table.Cell>
                              {row.inDay} <Icon name={'chevron right'} /> {row.outDay}
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell collapsing><b>정비내용</b></Table.Cell>
                            <Table.Cell>
                              {row.workName} {row?.count > 1 && <Label style={{float:'right'}} circular color={'purple'}><Icon name={'wrench'} />{row.count}</Label>}
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell collapsing>
                              <Checkbox label={'비고'}
                                        checked={row.withDesc}
                                        onChange={(event, {checked}) => {
                                          row['withDesc'] = checked;
                                          _.map(row.selectedParts, (parts, pos) => {
                                            const position = positionMap[pos.toUpperCase()];
                                            if (position.code === 'etc') {
                                              row['selectedParts'][pos]['withDesc'] = checked;
                                            } else {
                                              _.map(parts, (partsData, partsKey) => {
                                                row['selectedParts'][pos][partsKey]['withDesc'] = checked;
                                              });
                                            }
                                          });

                                          setSelectedItem(prevState => {
                                            let prevData = prevState['repairHistory'];
                                            prevData[idx] = row;
                                            return {
                                              ...prevState,
                                              repairHistory: prevData,
                                            };
                                          });
                                        }} />
                            </Table.Cell>
                            <Table.Cell>
                              <Form>
                                <CustomTextArea value={row.description || ''}
                                                callback={(value) => {
                                                  setSelectedItem(prevState => {
                                                    const repairHistory = prevState.repairHistory;
                                                    repairHistory[idx]['description'] = value;

                                                    return {
                                                      ...prevState,
                                                      repairHistory: repairHistory,
                                                    };
                                                  });
                                                }} />
                              </Form>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell collapsing><b>부위지정</b></Table.Cell>
                            <Table.Cell>
                              <Dropdown placeholder={'부위 지정'} pointing="left">
                                <Dropdown.Menu>
                                  {renderParts(row, idx)}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell colSpan={2} collapsing>
                              <List>
                                {_.map(row.selectedParts, (parts, pos) => {
                                  const position = positionMap[pos.toUpperCase()];

                                  if (position.code === 'etc') {
                                    return <List.Item key={`repairParts_${idx}_${pos}`}>
                                      <Label as="a" color={'grey'}>
                                        <Icon name={'wrench'} />기타
                                        <Icon name="delete" onClick={() => {
                                          setSelectedItem(prevState => {
                                            prevState['repairHistory'][idx]['selectedParts'] = _.omit(row.selectedParts, pos);
                                            return {
                                              ...prevState,
                                              repairHistory: prevState['repairHistory'],
                                            };
                                          });
                                        }} />
                                      </Label>
                                    </List.Item>;
                                  } else {
                                    return _.map(parts, (partsData, partsKey) => {
                                      return <List.Item key={`repairParts_${idx}_${pos}_${partsKey}`}>
                                        <Label as="a"
                                               color={partsData['isAccident'] ? 'red' : 'grey'}
                                               onClick={() => toggleAccident(row, idx, pos, partsKey)}>
                                          <Icon name={partsData['isAccident'] ? 'warning sign' : 'wrench'} />
                                          [{position.label}] {partsData['label']}
                                          <Icon name="delete" onClick={() => {
                                            setSelectedItem(prevState => {
                                              prevState['repairHistory'][idx]['selectedParts'][pos] = _.omit(row.selectedParts[pos], partsKey);
                                              return {
                                                ...prevState,
                                                repairHistory: prevState['repairHistory'],
                                              };
                                            });

                                            removeRepairPoint(pos, partsKey);
                                          }} />
                                        </Label>
                                      </List.Item>;
                                    });
                                  }
                                })}
                              </List>
                            </Table.Cell>
                          </Table.Row>

                        </Table.Body>
                      </Table>
                    </Card>
                  );
                })
              }
            </Grid.Column>
            <Grid.Column width="9">
              <Sticky context={contextRef} offset={70} bottomOffset={70} styleElement={{zIndex: 1}}>
                <Button icon={'send'} fluid content={'발송'}
                        disabled={isSending
                          || _.isEmpty(selectedItem)
                          || selectedItem.requestInfo?.reportSendDate !== null}
                        color={'blue'} onClick={sendReport} />
                <textarea placeholder={'비고사항입력'}
                          rows={3}
                          style={{width: '100%', marginTop: '5px'}}
                          disabled={isSending || _.isEmpty(selectedItem)}
                          onBlur={(event) => {
                            setSelectedItem(prevState => {
                              return {
                                ...prevState,
                                description: event.target.value,
                              };
                            });
                          }} />

                <Segment id="emailTemplateArea" style={{marginTop: 0}}>
                  {selectedItem !== null &&
                    <iframe srcDoc={selectedItem?.requestInfo?.responseContent || reportDocument} style={{border: 'none', width: '100%', height: '100vh'}} />
                  }
                </Segment>
              </Sticky>
            </Grid.Column>
          </Grid.Row>
        </Ref>
      </Grid>
    </>
  );
}

const ItemCard = ({currentItem, selectedItem, callback}) => {
  //key={`requestItem_${idx}`}
  return (
    <Card fluid link onClick={callback}>
      {
        currentItem.requestInfo?.reportSendDate !== null
          ? <Label icon="check" color="teal" corner="right" />
          : <Label icon="wait" color="red" corner="right" />
      }
      <Card.Content>
        <Card.Header>
          <Checkbox disabled={currentItem.requestInfo?.reportSendDate !== null}
                    checked={selectedItem?.requestInfo?.repairHistoryId === currentItem?.requestInfo?.repairHistoryId}
                    label={currentItem.licensePlateNumber} />
        </Card.Header>
        <Card.Meta>[{currentItem.makerNm}] {currentItem.modelNm} ({currentItem.yearType})</Card.Meta>
        <Card.Description>
          <Table compact striped size="small">
            <Table.Header />
            <Table.Body>
              <Table.Row>
                <Table.Cell><b>연료</b></Table.Cell>
                <Table.Cell>{currentItem.fuel}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell><b>배기량</b></Table.Cell>
                <Table.Cell>{CommonUtil.Unit.format(currentItem.engineSize)}cc</Table.Cell>
              </Table.Row>

              {/*<Table.Row>*/}
              {/*  <Table.Cell><b>변속기</b></Table.Cell>*/}
              {/*  <Table.Cell>{currentItem.gearBox}</Table.Cell>*/}
              {/*</Table.Row>*/}

              <Table.Row>
                <Table.Cell><b>색상</b></Table.Cell>
                <Table.Cell>{currentItem.color}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell><b>최초등록일</b></Table.Cell>
                <Table.Cell>{moment(currentItem.requestInfo.firstRegDate).format('YYYY. MM. DD')} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><b>차량구매가</b></Table.Cell>
                <Table.Cell>
                  {
                    currentItem.requestInfo.purchasePrice === 0 ? '입력하지 않음' :
                      new Intl.NumberFormat('ko-KR').format(currentItem.requestInfo.purchasePrice)
                  }
                </Table.Cell>
              </Table.Row>
              {/*<Table.Row>*/}
              {/*  <Table.Cell><b>정비이력</b></Table.Cell>*/}
              {/*  <Table.Cell>{currentItem.repairHistory.length} 건</Table.Cell>*/}
              {/*</Table.Row>*/}
            </Table.Body>
          </Table>
        </Card.Description>
      </Card.Content>
      <Card.Content extra textAlign="right">
        요청정보 <strong>{currentItem.requestInfo.ownerName} | {currentItem.requestInfo.contactText}</strong>
        <br />
        요청일시 <strong>{moment(currentItem.requestInfo.regTime).format('YYYY-MM-DD HH:mm')}</strong> {currentItem.requestInfo?.reportSendDate !== null && (<>
        <br />
        응답일시 <strong>{moment(currentItem.requestInfo?.reportSendDate?.regTime).format('YYYY-MM-DD HH:mm')}</strong></>)}
      </Card.Content>
    </Card>
  )
}

const CustomTextArea = ({value, callback}) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <TextArea value={currentValue}
              onChange={(e, {value}) => setCurrentValue(value)}
              onBlur={() => callback(currentValue)} />
  );
};