import React from "react";
import {Route, Redirect} from "react-router-dom";

const CarmonPrivateRoute = ({component: Component, prevPathName, ...rest}) => {
  const accessToken = sessionStorage.getItem('access-token');

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/login',
            state: {from: prevPathName},
          }}/>
        )
      }
    />
  );
}

export default CarmonPrivateRoute;