import {Modal} from 'semantic-ui-react';
import JSONPretty from 'react-json-pretty';
import React, {useEffect, useState} from 'react';
import THEME_MONIKAI from 'react-json-pretty/dist/monikai';

const JsonContentModal = ({open, close, content}) => {
  useEffect(async () => {
    setContentModal(prevState => ({...prevState, open: open}));
  }, [open]);

  const [contentModal, setContentModal] = useState({open: false});

  const closeModal = () => {
    setContentModal({open: false});
    close && close();
  }

  return (
    <Modal open={contentModal.open}
           onClose={closeModal}
           closeIcon
           centered={false}>
      <Modal.Content style={{padding:0, overflowX: 'auto', background:'#272822'}}>
        <JSONPretty data={content} theme={THEME_MONIKAI} style={{margin:10}} />
      </Modal.Content>
    </Modal>
  );
}

export default JsonContentModal;
