import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Form, FormInput, Icon, Loader, Modal} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-semantic-toasts";
import CarmonTransportManagerPopup from './TransportManager';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

const CarmonAssignTransportManager = ({open, close, auction, callback}) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [transportManagerPopup, setTransportManagerPopup] = useState({
    open: false,
    close: () => setTransportManagerPopup(prevState => {return {...prevState, open: false}}),
    callback: (transportManager) => {
      setFormData(prevState => {
        return {
          ...prevState,
          transportManagerName: transportManager['name'],
          transportManagerContactNumber: transportManager['contactNumber']
        }
      });
    }
  });

  useEffect(() => {
    if (auction) retrieveTransport();
  }, [auction]);

  const retrieveTransport = async () => {
    try {
      const response = await axios.get(`/apis/admin/transport/reservation/${auction.reservationId}`);
      if (response.status === 200) {
        setFormData(response.data);

        if (response.data && response.data.transportAmount) {
          document.getElementById('transportAmount').value = response.data.transportAmount.toLocaleString();
        }

        if (response.data.transportManagerId > 0) {
          retrieveTransportManager(response.data.transportManagerId);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const retrieveTransportManager = async (managerId) => {
    try {
      const response = await axios.get(`/apis/admin/transport-manager/${managerId}`);
      if (response.status === 200) {
        setFormData(prevState => {
          return {
            ...prevState,
            transportManagerName: response.data.name,
            transportManagerContactNumber: response.data.contactNumber,
          }
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const saveTransport = async () => {
    try {
      if (!checkValidation()) return;

      setLoading(true);
      const response = await axios.put(`/apis/admin/transport`, formData);
      if (response.status === 200) {
        setLoading(false);
        toast({
          type: 'success',
          title: '탁송정보 등록',
          time: 2000,
          description: '탁송정보가 반영되었습니다.'
        });

        callback && callback();
        close();
      }
    } catch (error) {
      setLoading(false);
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  };

  const checkValidation = () => {
    let hasError = false;
    let message = '';

    if (_.isEmpty(formData.transportManagerName)) {
      hasError = true;
      message = '탁송기사 이름이 입력되지 않았습니다.';
    } else if (_.isEmpty(formData.transportManagerContactNumber)) {
      hasError = true;
      message = '탁송기사 연락처가 입력되지 않았습니다.';
    }

    if (hasError) {
      toast({
        type: "warning",
        title: "확인",
        time: 2000,
        description: message
      });
    }

    return !hasError;
  }

  const eventHandler = {
    onChangeField: (e) => {
      const name = e.target.name;
      let value = e.target.value;

      if (name === "transportAmount") {
        value = value ? parseInt(value.replace(/[\D\s._-]+/g, ""), 10) : 0;
        e.target.value = (_.toNumber(value) === 0) ? "" : value.toLocaleString();
      }

      setFormData(prevState => {
        return {
          ...prevState,
          [name]: value
        }
      });
    },
    onClickSave: () => {
      saveTransport();
    },
    onClickSearchManager: () => {
      setTransportManagerPopup(prevState => {
        return {
          ...prevState,
          open: true,
        }
      });
    }
  }

  return (
    <>
      <Modal
        onClose={close}
        open={open}
        size='small'
        centered={false}>
        <Dimmer active={loading} inverted>
          <Loader size='large'>Loading</Loader>
        </Dimmer>
        <Modal.Header>
          탁송정보 등록
        </Modal.Header>
        <Modal.Content>
          <Form key={formData.transportId}>
            <Form.Field name='transportId' value={formData.transportId}></Form.Field>
            <Form.Field>
              <FormInput fluid
                         label='탁송기사명'
                         name='transportManagerName'
                         placeholder='탁송기사명'
                         // readOnly={formData.transportManagerId}
                         onChange={eventHandler.onChangeField}
                         defaultValue={formData.transportManagerName}
                         action={{icon: 'search', color: 'orange', onClick: eventHandler.onClickSearchManager}} />
            </Form.Field>

            <Form.Input fluid
                        label='기사연락처'
                        placeholder='연락처'
                        name='transportManagerContactNumber'
                        // readOnly={formData.transportManagerId}
                        onChange={eventHandler.onChangeField}
                        defaultValue={formData.transportManagerContactNumber} />

            <Form.Input fluid
                        label='출발지주소'
                        placeholder='출발지주소'
                        name='sellerAddress'
                        readOnly={formData.transportManagerId}
                        onChange={eventHandler.onChangeField}
                        value={formData.sellerAddress || ''} />

            <Form.Input fluid
                        label='도착지주소'
                        placeholder='도착지주소'
                        name='buyerAddress'
                        readOnly={formData.transportManagerId}
                        onChange={eventHandler.onChangeField}
                        value={formData.buyerAddress || ''} />

            <Form.Input label='탁송비용'
                        control={NumberFormat}
                        thousandSeparator
                        placeholder='탁송비용'
                        id='transportAmount'
                        name='transportAmount'
                        readOnly={formData.transportManagerId}
                        onChange={eventHandler.onChangeField}
                        value={formData.transportAmount || ''} />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button color='teal' onClick={eventHandler.onClickSave} disabled={formData.transportState !== 'WA'}>
            <Icon name='save'/> 저장
          </Button>
          <Button color='red' onClick={close}>
            <Icon name='remove'/> 닫기
          </Button>
        </Modal.Actions>
      </Modal>

      <CarmonTransportManagerPopup {...transportManagerPopup} />
    </>
  );
}

export default CarmonAssignTransportManager;