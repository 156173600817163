import React from 'react'
import {Button, Icon, Modal} from "semantic-ui-react";

const CarmonConfirm = ({
                         type = 'confirm',
                         title = '',
                         content = '',
                         open = false,
                         close,
                         callback,
                         size = 'small',
                         centered = false
                       }) => {
  const onClickOk = () => {
    callback && callback();
    close && close();
  }

  const onClickNo = () => {
    close && close();
  }

  return (
    <Modal
      open={open}
      size={size}
      centered={centered}>

      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {content}
      </Modal.Content>

      <Modal.Actions>
        <Button color='teal' onClick={onClickOk}>
          <Icon name='check'/> 확인
        </Button>
        {
          type === 'confirm' ?
            <Button color='red' onClick={onClickNo}>
              <Icon name='remove'/> 취소
            </Button>:''
        }
      </Modal.Actions>
    </Modal>
  )
}

export default CarmonConfirm;