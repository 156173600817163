import React, { useEffect, useState } from 'react'
import { Table, Icon, Form, TextArea } from 'semantic-ui-react'

const CenterData = ({ centerData, ...props }) => {
  // center data 이용해서 계산할 것
  // 1. 실제 거리 - sort에 영향
  // 2. 소요 시간 - sort에 영향
  // ===> sort 에 영향 주는 데이터는 외부에서 계산하고 계산된 값을 받아서 쓰는 방향으로 !
  // 3. 예약 가능 시간 리스트 -> 공임나라 ? 예약 정보 : '유선 연락 필요'

  const { centerId, centerChannelId, name, address, customKey, contactNumber, resCnt, realDistance, duration, canForeign, note } = centerData
  const { state, reservation, withResTime, eventHandler } = props
  const { selectedCenter } = state
  const [ times, setTimes ] = useState('조회중입니다.')

  const getChannelName = (centerChannel) => {
    let findObject = state.centerChannelList.filter((c) => {
      return c.value === centerChannel;
    });

    return findObject.length === 0 ? "미등록 회원사" : findObject[0]['text'];
  }

  const getReservationTimes = async () => {
    if (!reservation || !reservation.data.reservationDate || !withResTime) return
    
    if (getChannelName(centerChannelId) !== '공임나라' || !customKey) {
      return '유선 연락 필요'
    }

    if (customKey) {
      const url = `${process.env.THIRD_PARTY_API_BASE_URL}/reservation/day?key=${customKey}&date=${reservation?.data?.reservationDate}`;
      const res = await fetch(url)
      const json = await res.json()
      const timeTable = await json['times']

      if (!Array.isArray(timeTable)) {
        return timeTable
      }

      if (timeTable.length > 0) {
        return timeTable.join(', ')
      }

      return '확인 필요(폐점 의심)'
    }
  }


  useEffect(() => {
    const fetchReservationTimes = async () => {
      setTimes('조회중입니다.')
      const timeData = await getReservationTimes()
      setTimes(timeData)
    }
    fetchReservationTimes()
  }, [address])

  return (
    <Table.Row key={centerId}
               positive={selectedCenter && selectedCenter.centerId === centerId}
               onClick={() => eventHandler.onSelectCenter(centerData)}
               onDoubleClick={() => eventHandler.onSelectComplete()}>
      <Table.Cell>{getChannelName(centerChannelId)}</Table.Cell>
      <Table.Cell>
        {
          getChannelName(centerChannelId) === '공임나라' ?
          <a href={`https://www.gongim.com/sub/reservation_calender.php?seq=${customKey}&g1=1`} target={'gongim.com'}>{name}</a>
          :
          name
        }
      </Table.Cell>
      <Table.Cell>{contactNumber}</Table.Cell>
      <Table.Cell>{(resCnt && resCnt > 0) ? resCnt : '-'}</Table.Cell>

      {
        withResTime &&
        <Table.Cell style={{color:'#db2828', display: address ? '' : 'none'}}>
          {realDistance}
        </Table.Cell>
      }
      {
        withResTime &&
        <Table.Cell>
          {duration}
        </Table.Cell>
      }

      <Table.Cell>{address}</Table.Cell>
      <Table.Cell>
        <Icon color={
                getChannelName(centerChannelId) === '공임나라' ? (canForeign === 'Y' ? 'green' : 'red') : 'grey'
              }
              disabled={getChannelName(centerChannelId) !== '공임나라'}
              name={
                getChannelName(centerChannelId) === '공임나라' ? (canForeign === 'Y' ? 'check' : 'times') : 'question'
              } 
        />
      </Table.Cell>

          {
            withResTime &&
            <Table.Cell>
              {times}
            </Table.Cell>
          }
      <Table.Cell>
        <Form>
          <TextArea value={note || ''} rows={2} style={{padding: '2px'}}/>
        </Form>
      </Table.Cell>
    </Table.Row>
  )
}

export default CenterData