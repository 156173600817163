import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Menu} from 'semantic-ui-react';
import jwt_decode from "jwt-decode";

const CarmonSideMenu = ({sideMenuHide}) => {
  const [activeItem, setActiveItem] = useState('');
  const [adminRole, setAdminRole] = useState(null);

  useEffect(() => {
    const accessToken = sessionStorage.getItem('access-token');
    const token = jwt_decode(accessToken);
    setAdminRole(token.adminRole);
  }, []);

  const handleItemClick = (e, props) => {
    setActiveItem(props.index);
  };

  return (
    <>
    {
      adminRole === 'S' &&
        <Menu className={sideMenuHide ? 'carmon-main-side hide-menu' : 'carmon-main-side show-menu'} borderless
              fixed="left"
              inverted vertical>
          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>예약관리</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name="reservation"
                active={activeItem === 'reservation'}
                onClick={handleItemClick}
                as={Link}
                to="/reservation"
              >
                <p style={{whiteSpace: 'nowrap'}}>예약 리스트</p>
              </Menu.Item>

              <Menu.Item
                name="carmon-delivery"
                active={activeItem === 'carmon-delivery'}
                onClick={handleItemClick}
                as={Link}
                to="/carmon-delivery"
              >
                <p style={{whiteSpace: 'nowrap'}}>카몬 딜리버리</p>
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>매물관리</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name="auction"
                active={activeItem === 'auction'}
                onClick={handleItemClick}
                as={Link}
                to="/auction"
              >
                <p style={{whiteSpace: 'nowrap'}}>경매진행현황</p>
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>딜러관리</Menu.Header>

            <Menu.Menu>
              <Menu.Item
                name="dealer"
                active={activeItem === 'dealer'}
                onClick={handleItemClick}
                as={Link}
                to="/dealer"
              >
                <p style={{whiteSpace: 'nowrap'}}>딜러리스트</p>
              </Menu.Item>

              <Menu.Item
                name="trading"
                active={activeItem === 'trading'}
                onClick={handleItemClick}
              >
                <p style={{whiteSpace: 'nowrap'}}>거래내역(준비중)</p>
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>정비소</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="center"
                active={activeItem === 'center'}
                onClick={handleItemClick}
                as={Link}
                to="/center"
              >
                <p style={{whiteSpace: 'nowrap'}}>정비소 리스트</p>
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>기타</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="at-history"
                active={activeItem === 'at-history'}
                onClick={handleItemClick}
                as={Link}
                to="/at-history"
              >
                <p style={{whiteSpace: 'nowrap'}}>알림톡발송내역</p>
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>카나와</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="ccj-requests"
                active={activeItem === 'ccj-requests'}
                onClick={handleItemClick}
                as={Link}
                to="/ccj-requests"
              >
                <p style={{whiteSpace: 'nowrap'}}>중고차 가격비교</p>
              </Menu.Item>

              <Menu.Item
                name="repair-report-requests"
                active={activeItem === 'repair-report-requests'}
                onClick={handleItemClick}
                as={Link}
                to="/repair-report-requests"
              >
                <p style={{whiteSpace: 'nowrap'}}>정비이력조회</p>
              </Menu.Item>

            </Menu.Menu>
          </Menu.Item>

          <Menu.Item>
            <Menu.Header style={{whiteSpace: 'nowrap'}}>파트너스</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="accident-history"
                active={activeItem === 'accident-history'}
                onClick={handleItemClick}
                as={Link}
                to="/accident_history"
              >
                <p style={{whiteSpace: 'nowrap'}}>사고이력조회</p>
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>

        </Menu>
    }

    {
      adminRole === 'P' &&
      <Menu className={sideMenuHide ? 'carmon-main-side hide-menu' : 'carmon-main-side show-menu'} borderless
            fixed="left"
            inverted vertical>
        <Menu.Item>
          <Menu.Header style={{whiteSpace: 'nowrap'}}>파트너스</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name="accident-history"
              active={activeItem === 'accident-history'}
              onClick={handleItemClick}
              as={Link}
              to="/accident_history"
            >
              <p style={{whiteSpace: 'nowrap'}}>사고이력조회</p>
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>

      </Menu>
    }
    </>
  );
};

export default withRouter(CarmonSideMenu);
