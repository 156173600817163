import React from 'react';
import {Container, Menu, Segment} from "semantic-ui-react";

const CarmonAdminFooter = () => {
  return (
    <Container fluid className='carmon-top-navi'>
      <Segment basic
               inverted
               style={{
                 width: '100%',
                 padding: '0',
                 background: '#1b1c1d'
               }}
               as={Menu}
               fixed='bottom'>
        <Menu.Item style={{width: '15rem'}}>
        </Menu.Item>
        <Menu.Item style={{width:'100%'}}>
          Copyright © 카몬 All Rights Reserved.
        </Menu.Item>
      </Segment>
    </Container>
  );
}

export default CarmonAdminFooter
