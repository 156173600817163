import React, {useEffect, useState} from 'react';
import {Button, Form,  Input, Modal, Segment, Table} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';

const CarmonCompanySearch = ({open, close, callback}) => {
  const [company, setCompany] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [companyListAll, setCompanyListAll] = useState([]);

  const retrieveCompany = async () => {
    try {
      const response = await axios.get('/apis/admin/company/list');
      if (response.status === 200) {
        setCompanyListAll(response.data);
        setCompanyList(response.data);
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }
  useEffect(() => {
    retrieveCompany();
  }, []);

  const eventHandler = {
    onChangeSearchForm: (evt, {name, value}) => {
      setCompanyList(companyListAll.filter(v => {
        return (new RegExp(value)).test(v[name]);
      }));
    },
    onClickSearch: () => {
      retrieveCompany();
    },
    onSelectCenter: (currentCompany) => {
      setCompany(currentCompany);
    },
    onSelectComplete: () => {
      callback && callback(company);
      close && close();
    }
  }

  const renderCompanyList = () => {
    const render = [];

    if (companyList.length === 0) {
      render.push(
        <Table.Row key='empty'>
          <Table.Cell colSpan='3' textAlign='center'>검색 결과가 없습니다.</Table.Cell>
        </Table.Row>
      );
    } else {
      companyList.map(row => {
        render.push(
          <Table.Row key={row.companyId}
                     positive={company && company.companyId === row.companyId}
                     onClick={() => eventHandler.onSelectCenter(row)}
                     onDoubleClick={() => eventHandler.onSelectComplete()}>
            <Table.Cell>{row.companyName}</Table.Cell>
            <Table.Cell>{row.representative}</Table.Cell>
            <Table.Cell>{row.businessNumber}</Table.Cell>
          </Table.Row>
        );
      });
    }

    return render;
  }

  return (
    <Modal
      closeIcon
      onClose={close}
      open={open}
      size='small'
      centered={false}>
      <Modal.Header>상호검색</Modal.Header>
      <Modal.Content style={{padding: '0'}}>
        <Segment basic>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field control={Input}
                          name='companyName'
                          label='상호'
                          placeholder='상호'
                          onChange={eventHandler.onChangeSearchForm}
              />
              <Form.Field control={Input}
                          name='businessNumber'
                          label='사업자번호'
                          placeholder='사업자번호'
                          onChange={eventHandler.onChangeSearchForm}
              />
            </Form.Group>
          </Form>

          <div style={{textAlign:'right'}}>
            <Button icon='search' content='검색' color='teal' onClick={eventHandler.onClickSearch} />
          </div>
        </Segment>
      </Modal.Content>
      <Modal.Content style={{padding: '0'}} scrolling>
        <Segment basic>
          <Table striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>상호</Table.HeaderCell>
                <Table.HeaderCell>대표자명</Table.HeaderCell>
                <Table.HeaderCell>사업자번호</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {renderCompanyList()}
            </Table.Body>
          </Table>
        </Segment>
      </Modal.Content>
    </Modal>

  );
}

export default CarmonCompanySearch;