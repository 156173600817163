 import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Icon, Modal, Segment, Table} from "semantic-ui-react";
import axios from 'axios';
import {toast} from 'react-semantic-toasts';

const CarmonTransportManagerPopup = ({open, close, callback}) => {
  const [transportManager, setTransportManager] = useState([]);
  const [transportManagerView, setTransportManagerView] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    retrieveTransportManager();
  }, []);

  const retrieveTransportManager = async () => {
    try {
      const response = await axios.get('/apis/admin/transport-manager');
      if (response.status === 200) {
        setTransportManager(response.data);
        setTransportManagerView(response.data);
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const renderer = {
    transportManager: () => {
      let renderer = [
        <Table.Row key='emptyRow'>
          <Table.Cell colSpan={2}>데이터가 없습니다.</Table.Cell>
        </Table.Row>
      ];

      if (transportManagerView.length > 0) {
        renderer = [];

        transportManagerView.map(v => {
          renderer.push(
            <Table.Row key={v.transportManagerId}
                       positive={selected && selected.transportManagerId === v.transportManagerId}
                       onClick={() => setSelected(v)}
                       onDoubleClick={() => eventHandler.onSelectComplete()}
                       style={{background:selected === v ? '#ddd' : '#fff'}}>
              <Table.Cell>{v.name}</Table.Cell>
              <Table.Cell>{v.contactNumber}</Table.Cell>
            </Table.Row>
          )
        });
      }

      return renderer;
    }
  }

  const eventHandler = {
    onFormChange: () => {
      const filtered = transportManager.filter(v => {
        let keyword = document.querySelector('#keyword').value;
        return v['name'].indexOf(keyword) > -1 || v['contactNumber'].indexOf(keyword) > -1
      });

      setTransportManagerView(filtered);
    },
    onSelectComplete: () => {
      callback && callback(selected);
      close && close();
    }
  }

  return (
    <>
      <Modal open={open}
             size='small'
             centered={false}>
        <Modal.Header>탁송기사 검색</Modal.Header>
        <Modal.Content scrolling>
          <Segment attached='top'>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input fluid
                                  label='검색'
                                  placeholder='이름 또는 연락처'
                                  name='keyword'
                                  id='keyword'
                                  onChange={eventHandler.onFormChange}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment attached style={{padding: '0'}}>
            <Table basic selectable style={{borderRadius: 0, borderWidth: 0}}>
              <Table.Header style={{background: `#f9fafb`}}>
                <Table.Row verticalAlign='middle'>
                  <Table.HeaderCell>이름</Table.HeaderCell>
                  <Table.HeaderCell>연락처</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {renderer.transportManager()}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>

        <Modal.Actions>
          <Button color='red' onClick={() => close && close()}>
            <Icon name='remove'/> 닫기
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default CarmonTransportManagerPopup;

