import React, {useEffect, useReducer, useRef, useState} from 'react';
import axios from "axios";
import {
  Button,
  Container,
  Divider,
  Form,
  FormField,
  Grid,
  Header, Menu,
  Segment,
  Table,
} from 'semantic-ui-react';
import {toast} from 'react-semantic-toasts';
import CarmonRegistryChannel from "../popup/RegistryChannel";
import CarmonConfirm from "../common/Confirm";
import {Location, UseYn, Association, BANK_TYPE} from '../../consts/Consts';
import _ from "lodash";
import CarmonRegistryCenter from "../popup/RegistryCenter";

const COUNT_PER_PAGE = 10;
const MAX_PAGE_COUNT = 10;

const CarmonCenterList = () => {
  const maxPage = useRef(1);

  const [page,setPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);

  const [channelList, setChannelList] = useState([]);
  const [centerList, setCenterList] = useState([]);

  const [state, setState] = useState({
    channelList: [],
    centerList: [],
    isChannelUpdate: false,
    isCenterUpdate: false,
    selectedChannel: {},
    selectedCenter: {},
    channelModalOpen: false,
    centerModalOpen: false,
    optionChannelList: [],
    locationState: [],
    locationRegion: [],
    locationRegionAll: [],
    bankOptions: [],
    association: _.cloneDeep(Association),
    searchForm: {}
  });

  useEffect(() => {
    retrieveChannelList();
    retrieveCenterList();
    initialCommonCode();
  }, []);

  useEffect(() => {
    const channelOptions = [{key: -1, value: ' ', text: '전체'}];

    channelList.map((o, idx) => {
      channelOptions.push({key: idx, value: o.centerChannelId, text: o.name});
    });

    setState(prevState => {
      return {
        ...prevState,
        optionChannelList: channelOptions
      };
    });

  }, [channelList]);

  const confirmReducer = (confirmState, action) => {
    switch (action.type) {
      case 'show_remove_channel':
        return Object.assign({}, {
          title: '회원사 삭제',
          content: `${state.selectedChannel.data.name}[${state.selectedChannel.data.centerChannelId}]를 삭제하시겠습니까?`,
          open: true,
          close: () => confirmDispatch({type: 'hide'}),
          callback: () => {
            requestDeleteChannel();
          }
        });
      case 'show_remove_center':
        return Object.assign({}, {
          title: '정비소 삭제',
          content: `${state.selectedCenter.data.name}[${state.selectedCenter.data.centerId}]를 삭제하시겠습니까?`,
          open: true,
          close: () => confirmDispatch({type: 'hide'}),
          callback: () => {
            requestDeleteCenter();
          }
        });
      case 'hide':
        return Object.assign({}, {open: false});
    }
  }

  const initialCommonCode = async () => {
    const locationStateResponse = await retrieveCommonCode(Location.STATE);
    const _locationState = [{key: 0, value: ' ', text: '전체'}];

    locationStateResponse.data.map(({commonCodeId: key, code: value, name: text}) => {
      _locationState.push({key: key, value: value, text: text});
    });

    const locationRegionResponse = await retrieveCommonCode(Location.REGION);
    setState(prevState => {
      return {
        ...prevState,
        locationState: _locationState,
        locationRegionAll: locationRegionResponse.data
      }
    });

    const bankResponse = await retrieveCommonCode(BANK_TYPE.BANK);
    const stockResponse = await retrieveCommonCode(BANK_TYPE.STOCK);
    setState(prevState => {
      return {
        ...prevState,
        bankOptions: [
          ...[{commonCodeId: null, code: null, name: '없음'}],
          ...bankResponse.data,
          ...stockResponse.data
        ].map(({commonCodeId: key, code: value, name: text}) => {
          return {key: key, value: value, text: text};
        }),
      }
    });
  }

  const retrieveCommonCode = (codeGroup) => {
    return axios.get(`/apis/common/code_list/${codeGroup}`);
  }

  const retrieveChannelList = async () => {
    try {
      const channelResponse = await axios.get('/apis/admin/channel/list');
      if (channelResponse.status === 200) {
        setChannelList(channelResponse.data);
        setState(prevState => {
          return {...prevState, selectedChannel: {idx: -1, data: {}}};
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        type: "error",
        title: "ERROR",
        time: 10000,
        description: JSON.stringify(error.response.data)
      });
    }
  }

  const retrieveCenterList = async () => {
    try {
      const centerResponse = await axios.get('/apis/admin/center/list', {params: state.searchForm});
      if (centerResponse.status === 200) {
        setCenterList(centerResponse.data);
        setState(prevState => {
          return {...prevState, selectedCenter: {idx: -1, data: {}}};
        });
        setPageGroup(1);
        setPage(1);
      }
    } catch (error) {
      console.log(error);
      toast({
        type: "error",
        title: "ERROR",
        time: 10000,
        description: JSON.stringify(error.response.data)
      });
    }
  }

  const requestDeleteChannel = () => {
    axios.delete(`/apis/admin/channel/delete`, {data: {...state.selectedChannel.data}})
      .then(() => {
        toast({
          type: "success",
          title: "회원사",
          time: 2000,
          description: "회원사 정보가 삭제되었습니다."
        });
        retrieveChannelList();
      })
      .catch(error => {
        console.log(error);
        toast({
          type: "error",
          title: "ERROR",
          time: 10000,
          description: JSON.stringify(error.response.data)
        });
      });
  }

  const requestDeleteCenter = () => {
    axios.delete(`/apis/admin/center/delete`, {data: {...state.selectedCenter.data}})
      .then(() => {
        toast({
          type: "success",
          title: "정비소",
          time: 2000,
          description: "정비소 정보가 삭제되었습니다."
        });
        retrieveCenterList();
      })
      .catch(error => {
        console.log(error);
        toast({
          type: "error",
          title: "ERROR",
          time: 10000,
          description: JSON.stringify(error.response.data)
        });
      });
  }

  const renderer = {
    centerChannel: () => {
      const render = [];

      if (channelList.length === 0) {
        render.push(
          <Table.Row key='emptyRows'>
            <Table.Cell colSpan={2} warning>등록된 회원사가 없습니다.</Table.Cell>
          </Table.Row>
        );
      } else {
        channelList.map((o, idx) => {
          render.push(
            <Table.Row key={o.centerChannelId} positive={state.selectedChannel.idx === idx}
                       onClick={() => eventHandler.onSelectChannel(idx, o)}>
              <Table.Cell><a onClick={() => eventHandler.openChannelUpdate(idx, o)}>{o.name}</a></Table.Cell>
              <Table.Cell>{o.contactNumber}</Table.Cell>
            </Table.Row>
          );
        });
      }

      return render;
    },

    center: () => {
      const render = [];
      const pageStartIdx = (page - 1) * COUNT_PER_PAGE;
      const pageFinishIdx = (page * COUNT_PER_PAGE) - 1;

      if (centerList.length === 0) {
        render.push(
          <Table.Row key='emptyCenterRows'>
            <Table.Cell colSpan={9} warning>등록된 정비소가 없습니다.</Table.Cell>
          </Table.Row>
        );
      } else {
        centerList.map((o, idx) => {
          if (idx < pageStartIdx) return;
          if (idx > pageFinishIdx) return false;

          render.push(
            <Table.Row key={o.centerId} positive={state.selectedCenter.idx === idx}
                       onClick={() => eventHandler.onSelectCenter(idx, o)}>
              <Table.Cell>{getChannelName(o.centerChannelId)}</Table.Cell>
              <Table.Cell><a onClick={eventHandler.openCenterUpdate}><strong>{o.name}</strong></a></Table.Cell>
              <Table.Cell>{o.contactNumber}</Table.Cell>
              {/*<Table.Cell>{getAssociation(o.association)}</Table.Cell>*/}
              <Table.Cell>{`${getLocationState(o.locationState)} ${getLocationRegion(o.locationRegion)}`}</Table.Cell>
              <Table.Cell>{o.address}</Table.Cell>
              {/*<Table.Cell>{o.youtubeChannel}</Table.Cell>*/}
              <Table.Cell>{o.useYn === UseYn.YES ? <span style={{color:'#00F'}}>사용</span> : <span style={{color:'#F00'}}>미사용</span>}</Table.Cell>
            </Table.Row>
          );
        });
      }

      return render;
    }
  }

  const getChannelName = (centerChannel) => {
    let findObject = channelList.filter((c) => {
      return c.centerChannelId === centerChannel;
    });

    return findObject.length === 0 ? "미등록 회원사" : findObject[0]['name'];
  }

  const getLocationState = (locationState) => {
    let findObject = state.locationState.filter((c) => {
      return c.value === locationState;
    });

    return findObject.length === 0 ? "" : findObject[0]['text'];
  }

  const getLocationRegion = (locationRegion) => {
    let findObject = state.locationRegionAll.filter((c) => {
      return c.code === locationRegion;
    });

    return findObject.length === 0 ? "" : findObject[0]['name'];
  }

  const renderPagination = () => {
    const render = [];
    let total = 0;

    if (centerList.length > COUNT_PER_PAGE) {
      render.push(
        <Menu.Item key='prevPageGroup' as='a' icon='chevron left' onClick={() => {
          if (pageGroup > 1) setPageGroup(pageGroup - 1);
        }}/>
      );

      for (let i = ((pageGroup - 1) * MAX_PAGE_COUNT + 1); i <= Math.ceil(centerList.length / COUNT_PER_PAGE); i++) {

        if (i > pageGroup * MAX_PAGE_COUNT) break;

        render.push(<Menu.Item key={i} as='a' onClick={() => setPage(i)}
                               style={{background: page === i ? `rgb(0, 0, 0, .1)` : `0 0`}}>{i}</Menu.Item>);
        total = i;
      }

      render.push(
        <Menu.Item key='nextPageGroup' as='a' icon='chevron right' onClick={() => {
          if (Math.ceil(centerList.length / COUNT_PER_PAGE) > (pageGroup * MAX_PAGE_COUNT)) {
            setPageGroup(pageGroup + 1);
          }
        }}/>
      );
    }

    maxPage.current = total + 1;

    return render;
  }

  const eventHandler = {
    onSelectChannel: (row, channel) => {
      setState(prevState => {
        return {
          ...prevState,
          selectedChannel: {
            ...prevState.selectedChannel,
            idx: row,
            data: channel
          }
        };
      });
    },
    onSelectCenter: (row, center) => {
      setState(prevState => {
        return {
          ...prevState,
          selectedCenter: {
            ...prevState.selectedCenter,
            idx: row,
            data: center
          }
        };
      });
    },
    openChannelUpdate: (row, channel) => {
      setState(prevState => {
        return {
          ...prevState,
          isChannelUpdate: true,
          channelModalOpen: true,
          selectedChannel: {
            ...prevState.selectedChannel,
            idx: row,
            data: channel
          }
        };
      });
    },
    openCenterUpdate: () => {
      setState(prevState => {
        return {
          ...prevState,
          isCenterUpdate: true,
          centerModalOpen: true,
        };
      });
    },
    removeChannel: () => {
      if (state.selectedChannel.idx >= 0) confirmDispatch({type: 'show_remove_channel'});

    },
    removeCenter: () => {
      if (state.selectedCenter.idx >= 0) confirmDispatch({type: 'show_remove_center'});
    },
    locationStateChange: (e, op) => {
      let filteredRegion = [{key: 0, value: ' ', text: '전체'}];
      state.locationRegionAll.map((o) => {
        if (o.masterCode === op.value) {
          filteredRegion.push({key: o.commonCodeId, value: o.code, text: o.name})
        }
      });

      setState(prevState => {
        return {
          ...prevState,
          locationRegion: filteredRegion,
          searchForm: {
            ...prevState.searchForm,
            locationState: op.value
          }
        }
      });
    },
    onChangeUseYn: (e, item) => {
      setState(prevState => {
        return {
          ...prevState,
          searchForm: {
            ...prevState.searchForm,
            useYn: item.value
          }
        }
      });
    },
    onFormChange: (e, {name, value}) => {
      setState(prevState => {
        return {
          ...prevState,
          searchForm: {
            ...prevState.searchForm,
            [name]: value
          }
        }
      });
    }
  }

  const [confirmOptions, confirmDispatch] = useReducer(confirmReducer, {});

  return (
    <Container fluid>
      <Grid>
        <Grid.Row columns='equal' stretched>
          <Grid.Column width={4}>
            <Segment attached='top'>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Header>회원사 리스트</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Button.Group basic size='small' floated='right'>
                      <Button icon='plus' onClick={() => {
                        setState(prevState => {
                          return {
                            ...prevState,
                            isChannelUpdate: false,
                            channelModalOpen: true,
                            selectedChannel: {
                              ...prevState.selectedChannel,
                              idx: -1,
                              data: null
                            }
                          }
                        })
                      }}/>
                      <Button icon='trash' onClick={eventHandler.removeChannel}/>
                    </Button.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment attached style={{padding: '0', height: `100%`}}>
              <Table basic selectable style={{borderRadius: 0, borderWidth: 0}}>
                <Table.Header style={{background: `#f9fafb`}}>
                  <Table.Row verticalAlign='middle'>
                    <Table.HeaderCell>회원사</Table.HeaderCell>
                    <Table.HeaderCell>연락처</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {renderer.centerChannel()}
                </Table.Body>
              </Table>
            </Segment>
            <Segment attached='bottom'>footer</Segment>
          </Grid.Column>

          <Grid.Column width={12}>
            <Segment attached='top'>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header>정비소 리스트</Header>
                  </Grid.Column>
                </Grid.Row>
                <Divider style={{margin: 0}}/>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Form>
                      <Form.Group widths='equal'>
                        <Form.Select fluid
                                     label='회원사명'
                                     placeholder='회원사명'
                                     name='centerChannel'
                                     options={state.optionChannelList}
                                     onChange={eventHandler.onFormChange}
                        />
                        <Form.Select fluid
                                     label='지역(광역시/도)'
                                     name='locationState'
                                     placeholder='광역시/도'
                                     options={state.locationState}
                                     onChange={eventHandler.locationStateChange}
                        />
                        <Form.Select fluid
                                     label='지역(시/구/군)'
                                     placeholder='시/구/군'
                                     name='locationRegion'
                                     options={state.locationRegion}
                                     onChange={eventHandler.onFormChange}
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Select fluid
                                     label='성능점검협회'
                                     placeholder='성능점검협회'
                                     name='association'
                                     options={state.association}
                                     onChange={eventHandler.onFormChange}
                        />
                        <FormField>
                          <label>사용여부</label>
                          <Form.Group inline={true} style={{marginTop: `1em`}}>
                            <Form.Radio
                              name='useYn'
                              label='전체'
                              value=''
                              checked={_.isEmpty(state.searchForm.useYn)}
                              onChange={eventHandler.onFormChange}
                            />
                            <Form.Radio
                              name='useYn'
                              label='사용'
                              value={UseYn.YES}
                              checked={state.searchForm.useYn === UseYn.YES}
                              onChange={eventHandler.onFormChange}
                            />
                            <Form.Radio
                              name='useYn'
                              label='미사용'
                              value={UseYn.NO}
                              checked={state.searchForm.useYn === UseYn.NO}
                              onChange={eventHandler.onFormChange}
                            />
                          </Form.Group>
                        </FormField>
                        <FormField></FormField>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Divider style={{margin: 0}}/>
                <Grid.Row>
                  <Grid.Column>
                    <Button.Group basic size='small' floated='right'>
                      <Button icon='search' onClick={retrieveCenterList}/>
                      <Button icon='plus' onClick={() => setState({...state, centerModalOpen: true})}/>
                      <Button icon='trash' onClick={eventHandler.removeCenter}/>
                    </Button.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment attached style={{padding: '0'}}>
              <Table basic selectable style={{borderRadius: 0, borderWidth: 0}}>
                <Table.Header style={{background: `#f9fafb`}}>
                  <Table.Row verticalAlign='middle'>
                    <Table.HeaderCell>회원사</Table.HeaderCell>
                    <Table.HeaderCell>정비소</Table.HeaderCell>
                    <Table.HeaderCell>연락처</Table.HeaderCell>
                    {/*<Table.HeaderCell>성능점검협회명</Table.HeaderCell>*/}
                    <Table.HeaderCell>지역</Table.HeaderCell>
                    <Table.HeaderCell>주소</Table.HeaderCell>
                    {/*<Table.HeaderCell>유튜브채널</Table.HeaderCell>*/}
                    <Table.HeaderCell>사용여부</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {renderer.center()}
                </Table.Body>
              </Table>
            </Segment>
            <Segment attached='bottom'>
              <Header floated='left'>Total {centerList.length}건</Header>
              <Menu floated='right' pagination>
                {renderPagination()}
              </Menu>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <CarmonRegistryChannel modalOpen={state.channelModalOpen}
                             visibleControl={() => setState(prevState => {
                               return {...prevState, channelModalOpen: false};
                             })}
                             currentChannel={state.selectedChannel}
                             isUpdate={state.isChannelUpdate}
                             callback={() => {
                               toast({
                                 type: "success",
                                 title: "회원사 정보",
                                 time: 2000,
                                 description: "회원사 정보가 저장되었습니다."
                               });

                               retrieveChannelList();
                             }}/>
      <CarmonRegistryCenter modalOpen={state.centerModalOpen}
                            visibleControl={() => setState(prevState => {
                              return {...prevState, centerModalOpen: false};
                            })}
                            isUpdate={state.isCenterUpdate}
                            callback={() => {
                              toast({
                                type: "success",
                                title: "정비소",
                                time: 2000,
                                description: "정비소 정보가 저장되었습니다."
                              });
                              retrieveCenterList();
                            }}
                            currentCenter={state.selectedCenter}
                            centerChannel={state.optionChannelList}
                            locationState={state.locationState}
                            association={state.association}
                            locationRegionAll={state.locationRegionAll}
                            bankOptions={state.bankOptions} />
      <CarmonConfirm {...confirmOptions}/>

    </Container>
  );
}

export default CarmonCenterList
