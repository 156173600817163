import React, {useEffect, useReducer, useRef, useState} from 'react';
import axios from 'axios';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Menu,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import _ from 'lodash';
import CarmonSearchCenter from '../popup/SearchCenter';

const COUNT_PER_PAGE = 10;
const MAX_PAGE_COUNT = 10;

const TIME_OPTIONS = [
  {key: 'am9', value: 9, text: '9'},
  {key: 'am9.5', value: 9.5, text: '9:30'},
  {key: 'am10', value: 10, text: '10'},
  {key: 'am1.50', value: 10.5, text: '10:30'},
  {key: 'am11', value: 11, text: '11'},
  {key: 'am1.51', value: 11.5, text: '11:30'},
  {key: 'am12', value: 12, text: '12'},
  {key: 'am1.52', value: 12.5, text: '12:30'},
  {key: 'pm1', value: 13, text: '13'},
  {key: 'pm1.5', value: 13.5, text: '13:30'},
  {key: 'pm2', value: 14, text: '14'},
  {key: 'pm2.5', value: 14.5, text: '14:30'},
  {key: 'pm3', value: 15, text: '15'},
  {key: 'pm3.5', value: 15.5, text: '15:30'},
  {key: 'pm4', value: 16, text: '16'},
  {key: 'pm4.5', value: 16.5, text: '16:30'},
  {key: 'pm5', value: 17, text: '17'},
  {key: 'pm5.5', value: 17.5, text: '17:30'},
  {key: 'pm6', value: 18, text: '18'},
  {key: 'pm6.5', value: 18.5, text: '18:30'},
  {key: 'pm7', value: 19, text: '19'},
  {key: 'pm7.5', value: 19.5, text: '19:30'},
];

const CarmonDelivery = () => {
  const maxPage = useRef(1);
  const [page, setPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);
  const [errorModal, setErrorModal] = useState({open: false, message: ''});

  const [modalProps, setModalProps] = useState({
    open: false,
    close: () => {
      setModalProps(prevState => {
        return {
          ...prevState,
          reservation: null,
          open: false,
        }
      });
    },
    //callback: (center, reservation) => searchCenterCallback(center, reservation),
  });

  useEffect(() => {
    retrieveDelivery();
  }, []);

  const dataSortableReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        }
      case 'CHANGE_DATA':
        return {
          ...state,
          column: null,
          data: action.data,
          direction: null
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(dataSortableReducer, {
    column: null,
    data: [],
    direction: null,
  })

  const {column, data, direction} = state;

  const retrieveDelivery = () => {
    axios.get('/apis/admin/carmon-delivery/list')
      .then(({data}) => {
        dispatch({type: 'CHANGE_DATA', data: data});
      })
      .catch(({response}) => {
        setErrorModal({open:true, message: response.data.message});
      });
  }

  const saveDelivery = (row) => {
    axios.post('/apis/admin/carmon-delivery/save', row)
      .then(() => {
        retrieveDelivery();
      })
      .catch(({response}) => {
        setErrorModal({open:true, message: response.data.message});
      });
  }

  const saveReservation = (carmonDeliveryId) => {
    let carmonDelivery = _.find(data, { 'carmonDeliveryId': carmonDeliveryId });

    if (_.isEmpty(carmonDelivery.centerReservationDate)
      || carmonDelivery.centerReservationTime === null
      || carmonDelivery.centerReservationTime === 0) {
      setErrorModal({open:true, message: '정비소 예약일시가 입력되지 않았습니다.'});
      return;
    }

    if (carmonDelivery.centerId === null
      || carmonDelivery.centerId === 0
      || _.isEmpty(carmonDelivery.centerName)) {
      setErrorModal({open:true, message: '정비소 정보가 입력되지 않았습니다.'});
      return;
    }

    if (_.isEmpty(carmonDelivery.managerContactNumber) || _.isEmpty(carmonDelivery.managerName)) {
      setErrorModal({open:true, message: '탁송기사 정보가 입력되지 않았습니다.'});
      return;
    }

    axios.post('/apis/admin/carmon-delivery/reservation', carmonDelivery)
      .then(() => {
        retrieveDelivery();
      })
      .catch(({response}) => {
        setErrorModal({open:true, message: response.data.message});
      });
  }

  const cancelDelivery = (carmonDeliveryId) => {
    axios.delete(`/apis/admin/carmon-delivery/cancel/${carmonDeliveryId}`)
      .then(() => {
        retrieveDelivery();
      })
      .catch(({response}) => {
        setErrorModal({open:true, message: response.data.message});
      });
  }

  const renderPagination = () => {
    const render = [];
    let total = 0;

    if (data.length > COUNT_PER_PAGE) {
      render.push(
        <Menu.Item key='prevPageGroup' as='a' icon='chevron left' onClick={() => {
          if (pageGroup > 1) setPageGroup(pageGroup - 1);
        }}/>
      );

      for (let i = ((pageGroup - 1) * MAX_PAGE_COUNT + 1); i <= Math.ceil(data.length / COUNT_PER_PAGE); i++) {

        if (i > pageGroup * MAX_PAGE_COUNT) break;

        render.push(<Menu.Item key={i} as='a' onClick={() => setPage(i)}
                               style={{background: page === i ? `rgb(0, 0, 0, .1)` : `0 0`}}>{i}</Menu.Item>);
        total = i;
      }

      render.push(
        <Menu.Item key='nextPageGroup' as='a' icon='chevron right' onClick={() => {
          if (Math.ceil(data.length / COUNT_PER_PAGE) > (pageGroup * MAX_PAGE_COUNT)) {
            setPageGroup(pageGroup + 1);
          }
        }}/>
      );
    }

    maxPage.current = total + 1;

    return render;
  }

  const renderPage = () => {
    const render = [];
    const pageStartIdx = (page - 1) * COUNT_PER_PAGE;
    const pageFinishIdx = (page * COUNT_PER_PAGE) - 1;

    let row = 0;
    data.map((o, idx) => {
      if (idx < pageStartIdx) return;
      if (idx > pageFinishIdx) return false;

      row++;

      render.push(
        <Table.Row key={o.carmonDeliveryId}
          positive={o.status==="A"}
          negative={o.status==="C"}>
          <Table.Cell collapsing>
            {o.carmonDeliveryId}
          </Table.Cell>

          <Table.Cell collapsing>
            <Header as='h5' style={{margin: '0'}}>{o.licensePlateNumber}</Header>
            <Header as='h5' style={{margin: '0 auto'}} color='grey'>
              {o.makerName}<br/>{o.modelName}
            </Header>
            <Header as='h6' style={{marginTop: '0'}} color='grey'>
              {o.seriesName}
            </Header>
          </Table.Cell>

          <Table.Cell collapsing>
            <Header as='h5' style={{margin: '0'}}>{o.ownerName}</Header>
            <Header as='h5' style={{marginTop: '0'}} color='grey'>
              <NumberFormat
                displayType={'text'}
                format="###-####-####"
                value={o.contactNumber || ''} />
            </Header>
          </Table.Cell>

          <Table.Cell>
            <p>{o.resDate} {o.resTime}</p>
            <p>{o.address} {o.addressDetail}</p>
          </Table.Cell>

          <Table.Cell>
            <Input type={'date'}
                   size={'small'}
                   name={'centerReservationDate'}
                   value={o.centerReservationDate || ''}
                   onChange={(e, {name, value}) => {
                     data[idx][name] = value;
                     dispatch({type: 'CHANGE_DATA', data: data});
                   }}
                   min={moment().format("YYYY-MM-DD")}
                   fluid
                   style={{marginBottom:3}}
                   disabled={o.status !== 'W'}
                   action={
                     <Dropdown button basic floating
                               placeholder='시'
                               scrolling
                               upward={row > 5}
                               options={TIME_OPTIONS}
                               name={'centerReservationTime'}
                               value={o.centerReservationTime || ''}
                               style={{}}
                               onChange={(e, {name, value}) => {
                                 data[idx][name] = value;
                                 dispatch({type: 'CHANGE_DATA', data: data});
                               }}
                     />}
                   labelPosition='right'
            />
            <Input type='text'
                   size={'small'}
                   value={o.centerName || ''}
                   fluid
                   action={{
                     icon: 'search',
                     basic: true,
                     disabled: (o.status !== 'W'),
                     onClick: () => {
                       setModalProps(prevState => {
                         return {
                           ...prevState,
                           open: true,
                           reservation: {
                             ...prevState.reservation,
                             idx: idx,
                             data: {
                               ...o,
                               locationState: o.sidoCode,
                               locationRegion: o.sigunguCode
                             }
                           },
                           callback: (center) => {
                             data[idx]['centerId'] = center.centerId;
                             data[idx]['centerName'] = center.name;
                             data[idx]['centerContactNumber'] = center.contactNumber;
                             dispatch({type: 'CHANGE_DATA', data: data});
                           }
                         }
                       });
                     }
                   }}
            />
          </Table.Cell>

          <Table.Cell collapsing>
            <Form>
              <Form.Group>
                <Form.Input
                  size={'small'}
                  placeholder='이름'
                  name='managerName'
                  value={o.managerName || ''}
                  disabled={o.status !== 'W'}
                  onChange={(e, {name, value}) => {
                    data[idx][name] = value;
                    dispatch({type: 'CHANGE_DATA', data: data});
                  }}
                />
                <Form.Input
                  size={'small'}
                  placeholder='연락처'
                  name='managerContactNumber'
                  value={o.managerContactNumber || ''}
                  disabled={o.status !== 'W'}
                  onChange={(e, {name, value}) => {
                    data[idx][name] = value;
                    dispatch({type: 'CHANGE_DATA', data: data});
                  }}
                />
                {/*<Form.Button size={'small'} color={'teal'} icon={'save'} onClick={() => saveDeliveryManager()} />*/}
              </Form.Group>
            </Form>
          </Table.Cell>

          <Table.Cell>{
            o.reservationId > 0 ?
              o.reservationId
              :
              <Button size={'small'}
                      color={'blue'}
                      icon={'chain'}
                      disabled={o.status!=="W"}
                      onClick={() => saveReservation(o.carmonDeliveryId)} />
          }</Table.Cell>

          <Table.Cell collapsing>{moment(o.regTime).format("YYYY-MM-DD HH:mm:ss")}</Table.Cell>

          <Table.Cell collapsing>
            <Button size={'small'}
                    color={'teal'}
                    icon={'save'}
                    disabled={o.status==="C"}
                    onClick={() => saveDelivery(o)} />
            <Button size={'small'}
                    color={'red'}
                    icon={'trash'}
                    disabled={o.status==="C"}
                    onClick={() => cancelDelivery(o.carmonDeliveryId)} />
          </Table.Cell>

        </Table.Row>
      )
    });

    return render;
  }

  return (
    <div>
      <Header>
        카몬딜리버리
      </Header>
      <Segment basic style={{overflowX: 'auto', padding: 0}}>
        <Table striped sortable celled selectable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'reservationNumber' ? direction : null}
                onClick={() => dispatch({type: 'CHANGE_SORT', column: 'reservationNumber'})}
              >
                #
              </Table.HeaderCell>
              <Table.HeaderCell>차량번호</Table.HeaderCell>
              <Table.HeaderCell>예약자정보</Table.HeaderCell>
              <Table.HeaderCell>예약정보</Table.HeaderCell>
              <Table.HeaderCell>정비소</Table.HeaderCell>
              <Table.HeaderCell>탁송기사정보</Table.HeaderCell>
              <Table.HeaderCell>예약ID</Table.HeaderCell>
              <Table.HeaderCell>등록일시</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {renderPage()}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='14'>
                <Header floated='left'>Total {data.length}건</Header>
                <Button size={'tiny'} icon={'refresh'} color={'teal'} content={'새로고침'} onClick={retrieveDelivery} />
                <Menu floated='right' pagination>
                  {renderPagination()}
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>

      <Modal
        closeIcon
        open={errorModal.open}
        centered={false}
        onClose={() => setErrorModal({open: false})}
      >
        <Modal.Header>
          <Header as={'h4'} color={'red'}>
            <Icon name={'warning sign'}/>
            ERROR
          </Header>
        </Modal.Header>
        <Modal.Content>
          {
            errorModal.message
          }
        </Modal.Content>
      </Modal>

      <CarmonSearchCenter {...modalProps} />

    </div>

  );
};

export default CarmonDelivery;