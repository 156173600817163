import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Image, Input, Radio, Segment} from 'semantic-ui-react';
import _ from 'lodash';
import axios from 'axios';
import DaumPostCodeModal from './DaumPostCode';
import CarmonCompanySearch from './CompanySearch';
import {toast} from 'react-semantic-toasts';
import moment from 'moment';

const CarmonCompanyRegistrationForm = ({dealerId, url, buyerCertificateInfoUrl, close}) => {
  const [image, setImage] = useState(null);
  const [buyerCertificateInfo, setBuyerCertificateInfo] = useState(null);
  const [company, setCompany] = useState({dealerId: dealerId});

  const [companySearchModalOptions, setCompanySearchModalOptions] = useState({
    open: false,
    close: () => {
      setCompanySearchModalOptions(prevState => {
        return {
          ...prevState,
          open: false,
        };
      });
    },
    callback: (company) => {
      setCompany(prevState => {
        return {
          ...prevState,
          ...company
        }
      });
    },
  });

  const [daumPostModalOptions, setDaumPostModalOptions] = useState({
    address: '',
    addressDetail: '',
    open: false,
    callback: (address, zoneCode) => {
      setCompany(prevState => {
        return {
          ...prevState,
          postalCode: zoneCode,
          address: address,
        }
      });

      document.getElementsByName('postalCode')[0].value = zoneCode;
      document.getElementsByName('address')[0].value = address;
    },
  });

  useEffect(() => {
    getImage();
    getCompany();
  }, []);

  const getImage = async () => {
    loadImage(url, image => setImage(image));
    loadImage(buyerCertificateInfoUrl, image => setBuyerCertificateInfo(image));
  }

  const loadImage = async (imageUrl, callback) => {
    if (!_.isEmpty(imageUrl)) {
      const imgResponse = await axios.get(imageUrl, {responseType: 'blob'});

      if (imgResponse.status === 200) {
        const imageFile = new File([imgResponse.data], 'imageFile');
        const reader = new FileReader();
        reader.onload = e => {
          const image = String(e.target?.result);
          callback && callback(image);
        };

        reader.readAsDataURL(imageFile);
      }
    }
  }

  const getCompany = async () => {
    try {
      const response = await axios.get(`/apis/admin/company/dealer/${dealerId}`);
      if (response.status === 200) {
        setCompany(prevState => {
          return {
            ...prevState,
            ...response.data
          }
        });
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const saveCompany = async () => {
    try {
      if(!checkValidation()) return;

      const response = await axios.post('/apis/admin/company/save', company);

      if (response.status === 200) {
        setCompany(prevState => {
          return {
            ...prevState,
            ...response.data
          }
        });

        close && close();
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 5000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const checkValidation = () => {
    let message;

    if (!(company && company['companyType']) || (company.companyType !== '1' && company.companyType !== '2'))
      message = "개인/법인 구분이 선택되지 않았습니다.";
    else if (!(company && company['companyName']) || _.isEmpty(company.companyName))
      message = "상호가 입력되지 않았습니다.";
    else if (!(company && company['representative']) || _.isEmpty(company.representative))
      message = "대표자명이 입력되지 않았습니다.";
    else if (!(company && company['buyerRegistrationNumber']) || _.isEmpty(company.buyerRegistrationNumber))
      message = "법인번호가 입력되지 않았습니다.";
    else if (!(company && company['businessNumber']) || _.isEmpty(company.businessNumber))
      message = "사업자번호가 입력되지 않았습니다.";
    else if (!(company && company['address']) || _.isEmpty(company.address))
      message = "주소가 입력되지 않았습니다.";
    else if (!(company && company['contactNumber']) || _.isEmpty(company.contactNumber))
      message = "전화번호가 입력되지 않았습니다.";
    else if (!(company && company['documentDate']) || _.isEmpty(company.documentDate))
      message = "사업자등록증 발급일자가 입력되지 않았습니다.";

    if (!_.isEmpty(message)) {
      toast({
        type: "warning",
        title: "확인",
        time: 5000,
        description: message
      });
      return false;
    }

    return true;
  }

  const eventHandler = {
    onClickSearchCompany: () => {
      setCompanySearchModalOptions(prevState => {
        return {
          ...prevState,
          open: true,
        };
      });
    },
    onClickSearchAddress: () => {
      setDaumPostModalOptions(prevState => {
        return {
          ...prevState,
          open: true,
        };
      });
    },
    onChangeSearchForm: (evt, {name, value}) => {
      setCompany(prevState => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    },
    onClickSave: () => {
      saveCompany();
    },
    onClickClear: () => {
      setCompany({ dealerId: dealerId });
    }
  };

  return (
    <>
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src={image} />
              <Image src={buyerCertificateInfo} />
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <label>
                    개인/법인 구분
                  </label>
                  <Radio
                    label='개인'
                    name='companyType'
                    value='1'
                    checked={company.companyType === '1'}
                    onChange={eventHandler.onChangeSearchForm}
                    style={{marginRight:'15px'}}
                  />
                  <Radio
                    label='법인'
                    name='companyType'
                    value='2'
                    checked={company.companyType === '2'}
                    onChange={eventHandler.onChangeSearchForm}
                  />
                </Form.Field>

                <Form.Field
                  control={Input}
                  name="companyName"
                  label={
                    <label>
                      상호
                    </label>
                  }
                  placeholder="상호"
                  value={company.companyName || ''}
                  action={{icon: 'search', color: 'orange', onClick: eventHandler.onClickSearchCompany}}
                  onChange={eventHandler.onChangeSearchForm}
                />
                <Form.Field
                  control={Input}
                  name="representative"
                  label={
                    <label>
                      대표자명
                    </label>
                  }
                  placeholder="대표자명"
                  value={company.representative || ''}
                  onChange={eventHandler.onChangeSearchForm}
                />

                <Form.Field
                  control={Input}
                  name="buyerRegistrationNumber"
                  label={
                    <label>
                      법인번호 (개인사업자는 주민등록번호)
                    </label>
                  }
                  placeholder="법인번호 또는 주민등록번호"
                  value={company.buyerRegistrationNumber || ''}
                  onChange={eventHandler.onChangeSearchForm}
                />

                <Form.Field
                  control={Input}
                  name="businessNumber"
                  label={
                    <label>
                      사업자번호
                    </label>
                  }
                  placeholder="사업자번호"
                  value={company.businessNumber || ''}
                  onChange={eventHandler.onChangeSearchForm}
                />
                <Form.Field
                  control={Input}
                  name="registrationNumber"
                  label={
                    <label>
                      등록번호
                    </label>
                  }
                  placeholder="등록번호"
                  value={company.registrationNumber || ''}
                  onChange={eventHandler.onChangeSearchForm}
                />

                <Form.Field>
                  <label>주소</label>
                  <Form.Group widths={16}>
                    <Form.Field
                      control={Input}
                      name="postalCode"
                      placeholder="우편번호"
                      width={4}
                      readOnly
                      value={company.postalCode || ''}
                      onChange={eventHandler.onChangeSearchForm}
                    />
                    <Form.Field
                      control={Input}
                      name="address"
                      placeholder="주소"
                      width={12}
                      readOnly
                      value={company.address || ''}
                      action={{icon: 'search', color: 'orange', onClick: eventHandler.onClickSearchAddress}}
                      onChange={eventHandler.onChangeSearchForm}
                    />
                  </Form.Group>
                  <Form.Field
                    control={Input}
                    name="addressDetail"
                    placeholder="상세 주소"
                    value={company.addressDetail || ''}
                    onChange={eventHandler.onChangeSearchForm}
                  />
                </Form.Field>

                <Form.Field
                  control={Input}
                  name="contactNumber"
                  label="전화번호"
                  placeholder="전화번호"
                  value={company.contactNumber || ''}
                  onChange={eventHandler.onChangeSearchForm}
                />
                <Form.Field
                  control={Input}
                  name="faxNumber"
                  label="팩스번호"
                  placeholder="팩스번호"
                  value={company.faxNumber || ''}
                  onChange={eventHandler.onChangeSearchForm}
                />
                <Form.Group widths={16}>
                  <Form.Field
                    control={Input}
                    name="emailAddress"
                    label="이메일"
                    placeholder="이메일"
                    width={8}
                    value={company.emailAddress || ''}
                    onChange={eventHandler.onChangeSearchForm}
                  />
                  <Form.Field width={8}>
                    <label>사업자등록증 발급일자</label>
                    <Input type='date'
                           name='documentDate'
                           value={company.documentDate || ''}
                           max={moment().format("YYYY-MM-DD")}
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                </Form.Group>

                <Button color="orange" icon="eraser" content="초기화" onClick={eventHandler.onClickClear} />
                <Button color="blue" icon="save" content="저장" floated='right' onClick={eventHandler.onClickSave} />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <CarmonCompanySearch {...companySearchModalOptions} />
      <DaumPostCodeModal modalOption={daumPostModalOptions}
                         setModalOption={setDaumPostModalOptions} />
    </>
  );
};

export default CarmonCompanyRegistrationForm;